export interface InterfaceProfileData {
    id: string,
    profilePicturePath: string,
    firstName: string,
    lastName: string,
    email: string,
    workingAreaPolyData: string,
    address: string,
    phoneNumber: string,
    roles: Array<string>,
    joinDateString: string,
    lastWorkingAreaModifiedBy: string,
    lastWorkingAreaModifiedDate: string,
    skillTypeId: string,
    skillTypeString: string,
    linkedEmail: string
}

export interface InterfacePasswordData {
    token: string,
    oldPassword: string,
    newPassword: string,
    passwordConfirm: string
}

export interface InterfaceProfileUpdate {
    token: string,
    id: string,
    profilePicturePath: string,
    firstName: string,
    lastName: string,
    email: string,
    workingAreaPolyData: string,
    address: string,
    phoneNumber: string,
    skillTypeId: string
}

export interface InterfaceAccountChangePassword {
    token: string,
    oldPassword: string,
    newPassword: string,
    passwordConfirm: string
}

export const InitialProfileData: InterfaceProfileData = {
    id: "",
    profilePicturePath: "",
    firstName: "",
    lastName: "",
    email: "",
    workingAreaPolyData: "",
    address: "",
    phoneNumber: "",
    roles: [],
    joinDateString: "",
    lastWorkingAreaModifiedBy: "",
    lastWorkingAreaModifiedDate: "",
    skillTypeId: "",
    skillTypeString: "",
    linkedEmail: ""
}

export const initialPasswordData: InterfacePasswordData = {
    token: "",
    oldPassword: "",
    newPassword: "",
    passwordConfirm: ""
}
