import { useEffect, useRef, useState } from "react";
import { esriToken } from "../../../state";

import esriConfig from "@arcgis/core/config"
import esriMap from "@arcgis/core/Map"
import esriMapView from "@arcgis/core/views/MapView"
import esriGraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import esriZoom from "@arcgis/core/widgets/Zoom"
import esriSearch from "@arcgis/core/widgets/Search"
import esriGraphics from "@arcgis/core/Graphic"
import esriPoint from "@arcgis/core/geometry/Point"
import esriMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"
import esriTextSymbol from "@arcgis/core/symbols/TextSymbol"
import esriPictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { fieldingAssignedActiveGet } from "../fielding.api";
import hot from "../../../assets/images/hot.png"

export default function FieldingMap() {

    const mapRef = useRef<any>()
    const dispatch = useDispatch<any>()

    const dataMapList = useSelector((state: RootState) => state.fielding).dataMapList
    const loading = useSelector((state: RootState) => state.fielding).loading
    const center = dataMapList.length > 0
        ? {
            latitude: parseFloat(dataMapList[0].firstMarkerLocation.split(',')[1]),
            longitude: parseFloat(dataMapList[0].firstMarkerLocation.split(',')[0])
        }
        : null

    useEffect(() => {
        dispatch(fieldingAssignedActiveGet())
    }, [dispatch])

    const [layerMarker, setLayerMarker] = useState<any>()

    useEffect(() => {
        // initialize
        if (!loading) {
            esriConfig.apiKey = esriToken
            let map = new esriMap({
                basemap: "topo-vector" //"satellite"
            })
            let layerMarkerLocal = new esriGraphicsLayer()
            let layerMarkerTempLocal = new esriGraphicsLayer()
            map.add(layerMarkerLocal)
            map.add(layerMarkerTempLocal)
            let view = new esriMapView({
                container: mapRef.current,
                map: map,
                zoom: center !== null ? 15 : 11,
                center: center !== null ? [center.longitude, center.latitude] : [-99.09764528271961, 33.173506394266624],
            })
            // widget
            let viewZoom = new esriZoom({ view: view })
            view.ui.remove(viewZoom)
            let viewSearch = new esriSearch({ view: view, locationEnabled: false })
            view.ui.add(viewSearch, { position: "top-left" })
            // listener
            view.on("layerview-create", () => {
                if (document.getElementById("searchbox-input")) {
                    (document.getElementById("searchbox-input") as HTMLInputElement).placeholder = "Enter adress or GPS"
                }
            })
            setLayerMarker(layerMarkerLocal)
            return () => view?.destroy()
        }
    }, [loading])

    useEffect(() => {
        if (layerMarker) {
            let textMarkers: Array<any> = []
            let pointMarkers: Array<any> = []
            let hotMarkers: Array<any> = []
            pointMarkers = [...pointMarkers, ...dataMapList.map(project => {
                textMarkers.push(new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(project.firstMarkerLocation.split(',')[1]),
                        longitude: parseFloat(project.firstMarkerLocation.split(',')[0])
                    }),
                    symbol: new esriTextSymbol({
                        color: "black",
                        text: project.project,
                        xoffset: 40,
                        yoffset: -25,
                        font: {
                            size: 12,
                            family: "Roboto"
                        }
                    }),
                    attributes: {
                        type: "PROJECT_TEXT",
                        markerId: project.id
                    }
                }))
                return new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(project.firstMarkerLocation.split(',')[1]),
                        longitude: parseFloat(project.firstMarkerLocation.split(',')[0])
                    }),
                    symbol: new esriMarkerSymbol({
                        style: "circle",
                        color: "red"
                    }),
                    attributes: {
                        type: "PROJECT_DOT",
                        markerId: project.id
                    }
                })
            })]
            hotMarkers = dataMapList.filter(project => project.isHotJob).map(project => {
                return new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(project.firstMarkerLocation.split(',')[1]),
                        longitude: parseFloat(project.firstMarkerLocation.split(',')[0])
                    }),
                    symbol: new esriPictureMarkerSymbol({
                        url: hot,
                        height: 20,
                        width: 20,
                        xoffset: 20
                    })
                })
            })
            layerMarker.removeAll()
            if (pointMarkers.length > 0) {
                layerMarker.addMany(pointMarkers)
            }
            if (textMarkers.length > 0) {
                layerMarker.addMany(textMarkers)
            }
            if (hotMarkers.length > 0) {
                layerMarker.addMany(hotMarkers)
            }
        }
    }, [layerMarker, dataMapList])

    return (
        <div className="arcgis-container">
            <div ref={mapRef} style={{ height: "100%", width: "100%" }} />
        </div>
    )
}