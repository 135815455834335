import { Close } from "@mui/icons-material";
import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { projectCustomerCodeCreate, projectCustomerCodeDelete, projectCustomerCodeGet } from "../../project.api";
import { InterfaceProjectCustomerCode } from "../../project.interface";

export default function ProjectCustomerCode(props: {
    readOnly: boolean,
    value: string,
    onChange: (params: string) => void
}) {

    const dispatch = useDispatch<any>()

    const { dataCustomerCodeList } = useSelector((state: RootState) => state.project)

    const [valueCustomerCode, setValueCustomerCode] = useState("")

    useEffect(() => {
        dispatch(projectCustomerCodeGet())
    }, [])

    const handleAdd = () => {
        dispatch(projectCustomerCodeCreate(valueCustomerCode))
            .unwrap()
            .then((response: any) => {
                dispatch(projectCustomerCodeGet())
                    .unwrap()
                    .then(() => {
                        props.onChange(response.data.data.id)
                    })
            })
    }

    const handleDelete = (code: InterfaceProjectCustomerCode) => {
        Swal.fire({
            icon: "question",
            title: `Are you sure want to delete "${code.value}"`,
            showCancelButton: true
        })
            .then(result => {
                if (result.isConfirmed) {
                    dispatch(projectCustomerCodeDelete(code.id))
                        .unwrap()
                        .then((response: any) => {
                            dispatch(projectCustomerCodeGet())
                        })
                }
            })
    }

    return (
        <div className="fielding-type">
            <Autocomplete
                fullWidth
                readOnly={props.readOnly}
                options={dataCustomerCodeList}
                getOptionLabel={(code) => code.value}
                renderOption={(props, option) => (
                    <li {...props}>
                        <span style={{ width: "100%" }}>
                            {option.value}
                        </span>
                        <IconButton onClick={() => handleDelete(option)}>
                            <Close />
                        </IconButton>
                    </li>
                )}
                value={dataCustomerCodeList.filter(code => code.id === props.value)[0] || null}
                onChange={(e, value) => props.onChange(value?.id || "")}
                renderInput={(params) => <TextField {...params} label="Project customer code" onChange={(e) => setValueCustomerCode(e.target.value)} />}
            />
            <Button variant="contained" disabled={valueCustomerCode === "" || dataCustomerCodeList.filter(data => data.value === valueCustomerCode).length > 0} onClick={handleAdd}>
                Save New Project Customer Code
            </Button>
        </div>
    )
}