import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { projectGetDetail } from "../project.api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InitialProjectCreate, InterfaceProjectCreate, InterfaceProjectDetail, InterfaceProjectDetailFielding } from "../project.interface";
import moment from "moment";
import { Button, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import ProjectMapDetailMap from "./ProjectMapDetailMap";
import { InterfaceMarkerPoint } from "../../fielding/fielding.interface";

export default function ProjectMapDetail() {

    const dispatch = useDispatch<any>()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const [values, setValues] = useState<InterfaceProjectCreate>(InitialProjectCreate)
    const [center, setCenter] = useState<InterfaceMarkerPoint | null>(null)

    useEffect(() => {
        dispatch(projectGetDetail((searchParams.get("id")) || ""))
            .unwrap()
            .then((response: any) => {
                let data: InterfaceProjectDetail = response.data.data
                setValues({
                    ...values,
                    projectNumber: data.projectNumber,
                    description: data.description,
                    fpAddress: data.fpAddress,
                    fpCity: data.fpCity,
                    customerCodeId: data.customerCodeId,
                    projectFieldingRequests: (
                        data.projectFieldingRequests.map(fieldingRequest => {
                            return {
                                ...fieldingRequest,
                                fieldingRequestName: fieldingRequest.fieldingRequest,
                                customTypeId: fieldingRequest.customTypeId,
                                dueDateString: moment(fieldingRequest.dueDateString).format("YYYY-MM-DD"),
                                fieldingRequestMarkers: fieldingRequest.fieldingRequestMarkers.map(marker => {
                                    return {
                                        ...marker,
                                        lineData: JSON.parse(marker.lineData),
                                        shapeData: JSON.parse(marker.shapeData)
                                    }
                                })
                            }
                        })
                    )
                })
                let centerLocal = getCenterFieldingRequest(data.projectFieldingRequests[0])
                if (centerLocal) {
                    setCenter(centerLocal)
                }
            })
    }, [])

    const getCenterFieldingRequest = (fieldingRequest: InterfaceProjectDetailFielding): InterfaceMarkerPoint | null => {
        let dotLongLat = fieldingRequest.fieldingRequestMarkers[0].dotLongLat
        let lineData = JSON.parse(fieldingRequest.fieldingRequestMarkers[0].lineData)
        let shapeData = JSON.parse(fieldingRequest.fieldingRequestMarkers[0].shapeData)
        if (dotLongLat !== "") {
            return { latitude: parseFloat(dotLongLat.split(',')[1]), longitude: parseFloat(dotLongLat.split(',')[0]) }
        }
        else if (lineData.length > 0) {
            return { latitude: lineData[0][0].latitude, longitude: lineData[0][0].longitude }
        }
        else if (shapeData.length > 0) {
            return { latitude: shapeData[0][0].latitude, longitude: shapeData[0][0].longitude }
        }
        return null
    }

    if (center === null) {
        return <></>
    }

    return (
        <div className="detail-map">
            <div className="menu-header" style={{ alignItems: "flex-end" }}>
                <div className="back">
                    <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
                        Back
                    </Button>
                    <Typography variant="body1">
                        Project {" "}
                        <b >
                            {values.projectNumber}
                        </b>
                    </Typography>
                </div>
            </div>
            <div className="map">
                <ProjectMapDetailMap
                    fieldingRequestList={values.projectFieldingRequests.map(fielding => {
                        return {
                            id: fielding?.fieldingRequestId || "",
                            name: fielding.fieldingRequestName,
                            markers: fielding.fieldingRequestMarkers,
                            color: fielding.colorCode
                        }
                    })}
                    center={center}
                />
            </div>
        </div>
    )
}