import { createSlice } from "@reduxjs/toolkit";
import { InitialDashboardFieldingStatusData, InitialDashboardFieldingStatusDateData, InterfaceActivityType, InterfaceDashboardActivityLog, InterfaceDashboardFielderActiveData, InterfaceDashboardFieldingDateData, InterfaceDashboardFieldingNotesData, InterfaceDashboardFieldingStatusData, InterfaceDashboardFieldingType } from "./dashboard.interface";
import { getDashboardActivity, getDashboardActivityType, getDashboardFielderActive, getDashboardFielderNotes, getDashboardFieldingDate, getDashboardFieldingStatus, getDashboardFieldingType, getDashboardProject } from "./dashboard.api";
import { InterfaceProjectData } from "../project/project.interface";

export interface DashboardState {
    loading: boolean;
    dataFieldingStatus: InterfaceDashboardFieldingStatusData;
    dataFieldingDate: InterfaceDashboardFieldingDateData;
    dataFieldingType: InterfaceDashboardFieldingType[];
    dataFielderActive: InterfaceDashboardFielderActiveData[]
    dataActivityLogs: InterfaceDashboardActivityLog[]
    activityTypeList: InterfaceActivityType[]
    dataNoteList: InterfaceDashboardFieldingNotesData[]
    projectList: InterfaceProjectData[]
}
const initialState: DashboardState = {
    loading: false,
    dataFieldingStatus: InitialDashboardFieldingStatusData,
    dataFieldingDate: InitialDashboardFieldingStatusDateData,
    dataFieldingType: [],
    dataFielderActive: [],
    dataActivityLogs: [],
    activityTypeList: [],
    dataNoteList: [],
    projectList: []
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getDashboardFieldingStatus.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDashboardFieldingStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.dataFieldingStatus = action.payload.data.data;
        })
        builder.addCase(getDashboardFieldingStatus.rejected, (state) => {
            state.loading = false;
        })

        builder.addCase(getDashboardFieldingDate.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDashboardFieldingDate.fulfilled, (state, action) => {
            state.loading = false;
            state.dataFieldingDate = action.payload.data.data;
        })
        builder.addCase(getDashboardFieldingDate.rejected, (state) => {
            state.loading = false;
        })

        builder.addCase(getDashboardFieldingType.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDashboardFieldingType.fulfilled, (state, action) => {
            state.loading = false;
            state.dataFieldingType = action.payload.data.data;
        })
        builder.addCase(getDashboardFieldingType.rejected, (state) => {
            state.loading = false;
        })

        builder.addCase(getDashboardFielderActive.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDashboardFielderActive.fulfilled, (state, action) => {
            state.loading = false;
            state.dataFielderActive = action.payload.data.data;
        })
        builder.addCase(getDashboardFielderActive.rejected, (state) => {
            state.loading = false;
        })

        builder.addCase(getDashboardActivity.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDashboardActivity.fulfilled, (state, action) => {
            state.loading = false;
            state.dataActivityLogs = action.payload.data.data;
        })
        builder.addCase(getDashboardActivity.rejected, (state) => {
            state.loading = false;
        })

        builder.addCase(getDashboardActivityType.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDashboardActivityType.fulfilled, (state, action) => {
            state.loading = false;
            state.activityTypeList = action.payload.data.data;
        })
        builder.addCase(getDashboardActivityType.rejected, (state) => {
            state.loading = false;
        })

        builder.addCase(getDashboardFielderNotes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDashboardFielderNotes.fulfilled, (state, action) => {
            state.loading = false;
            state.dataNoteList = action.payload.data;
        })
        builder.addCase(getDashboardFielderNotes.rejected, (state) => {
            state.loading = false;
        })

        builder.addCase(getDashboardProject.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDashboardProject.fulfilled, (state, action) => {
            state.loading = false;
            state.projectList = action.payload.data.data;
        })
        builder.addCase(getDashboardProject.rejected, (state) => {
            state.loading = false;
        })

    }
})

export default dashboardSlice.reducer;