import { Close, Notifications, PersonOffOutlined } from "@mui/icons-material"
import { Badge, Box, Button, Card, CircularProgress, IconButton, Modal, Typography } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store"
import { useNavigate } from "react-router-dom"
import notificationSuccess from "../../shared/notificationSuccess"
import {
    projectClearNotification,
    projectGetNotification,
    projectReadNotification
} from "../../pages/account/account.api"
import Swal from "sweetalert2"
import { deleteAttachment } from "../../pages/fielding/fielding.api"
import ButtonOutlined from "../../shared/ButtonOutlined"

export default function HeaderNotificationsProject() {
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()
    // const isMobile = useMediaQuery("(max-width:768px)")
    const [NotificationList, setNotificationList] = useState(false)
    const [IsLoading, setIsLoading] = useState(false)
    const [isMobile, setIsMobile] = useState<boolean>(false)

    const { dataNotificationProjectList } = useSelector((state: RootState) => state.account)

    const checkPopUpData = dataNotificationProjectList.filter(
        (data: any) => !data.isRead && data.title === "No Fielder Available"
    )

    const checkUnReadData = dataNotificationProjectList.filter((data: any) => !data.isRead)

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange)
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange)
        }
    }, [])

    useEffect(() => {
        clearLocalStorage()
    }, [])

    const clearLocalStorage = async () => {
        let lastLocalUser = localStorage.getItem("fielderProData")
        let lastLocalUser2 = localStorage.getItem("fielderProAccount")
        let lastLocalUser3 = localStorage.getItem("fielderProUserData")
        if (lastLocalUser || lastLocalUser2 || lastLocalUser3) {
            await localStorage.removeItem("fielderProData")
            await localStorage.removeItem("fielderProAccount")
            await localStorage.removeItem("fielderProUserData")
            await localStorage.removeItem("fielderProUserAccount")
            setTimeout(() => {
                window.location.href = window.location.href
            }, 1000)
        }
    }
    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 768 ? true : false)
    }

    useEffect(() => {
        dispatch(projectGetNotification())
        const i = setInterval(() => {
            dispatch(projectGetNotification())
        }, 60000)
        return () => clearInterval(i)
    }, [])

    const handleClear = () => {
        // console.log("clear")
        dispatch(projectClearNotification())
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    notificationSuccess("")
                    dispatch(projectGetNotification())
                    setNotificationList(false)
                }
            })
    }

    const handleRead = (nId: string = "", fId: string = "") => {
        dispatch(projectReadNotification(nId))
            .unwrap()
            .then(async (response: any) => {
                if (response.status === 200) {
                    if (fId !== "") navigate("/project/assign?id=" + fId)
                    await dispatch(projectGetNotification())
                }
            })
    }

    const handleShowNotif = async () => {
        if (!NotificationList) {
            setIsLoading(true)
            await handleRead()

            setIsLoading(false)
            setTimeout(() => {
                setNotificationList(true)
            }, 400)
        } else setNotificationList(false)
    }

    const handleConfirmDeleteAttachment = async (id: any) => {
        await dispatch(deleteAttachment(id))
            .unwrap()
            .then(async (response: any) => {
                if (response.status === 200) {
                    await handleRead(id)
                    Swal.fire({
                        // title: "Deleted!",
                        title: "Your file has been deleted.",
                        icon: "success"
                    })
                }
            })
    }

    // const handleRejectDeleteAttachment = async (id: any) => {
    //     await dispatch(deleteAttachment(id))
    //         .unwrap()
    //         .then(async (response: any) => {
    //             if (response.status === 200) {
    //                 Swal.fire({
    //                     title: "Deleted!",
    //                     text: "Your file has been deleted.",
    //                     icon: "success"
    //                 })
    //             }
    //         })
    // }

    return (
        <Fragment>
            <Modal open={checkPopUpData.length > 0}>
                {checkPopUpData.length > 0 ? (
                    <div className="custom-modal">
                        <Box
                            bgcolor={"white"}
                            borderRadius={1}
                            width={"25vw"}
                            minWidth={"150px"}
                            padding={2}
                            position={"relative"}
                        >
                            <IconButton
                                onClick={() => handleRead(checkPopUpData[0].id)}
                                sx={{
                                    position: "absolute",
                                    borderRadius: "50%",
                                    background: "#efefef",
                                    right: "12px",
                                    width: "36px",
                                    minWidth: "unset",
                                    zIndex: 2
                                }}
                            >
                                <Close />
                            </IconButton>
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                padding={2}
                                position={"relative"}
                                textAlign={"center"}
                            >
                                <PersonOffOutlined sx={{ fontSize: "60px", margin: "8px" }} />
                                <Typography variant="h6" color={"#093a93"}>
                                    {checkPopUpData[0].title}
                                </Typography>
                                <Typography>
                                    {checkPopUpData[0].content}{" "}
                                    <span style={{ fontWeight: "bold" }}>{checkPopUpData[0].projectName}</span>
                                </Typography>
                                <Typography>Do you want to assign another fielder ?</Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} gap={1} padding={1}>
                                <ButtonOutlined
                                    fullWidth
                                    onClick={() =>
                                        handleRead(checkPopUpData[0].id, checkPopUpData[0].fieldingRequestId)
                                    }
                                >
                                    Yes
                                </ButtonOutlined>
                                <ButtonOutlined fullWidth onClick={() => handleRead(checkPopUpData[0].id)}>
                                    No
                                </ButtonOutlined>
                            </Box>
                        </Box>
                    </div>
                ) : (
                    <></>
                )}
            </Modal>
            {NotificationList && (
                <Card
                    sx={{
                        width: 350,
                        background: "white",
                        position: "absolute",
                        right: 20,
                        top: 50,
                        zIndex: 9,
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    {IsLoading ? (
                        <CircularProgress color="inherit" />
                    ) : (
                        <>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    padding: "0px 16px",
                                    paddingTop: "10px",
                                    marginBottom: 0
                                }}
                            >
                                <Typography sx={{ fontWeight: "bold" }}>Notification</Typography>
                                {dataNotificationProjectList.length > 0 && (
                                    <Typography sx={{ cursor: "pointer" }} onClick={() => handleClear()}>
                                        Clear All
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    minHeight: "20px",
                                    overflowY: "auto",
                                    alignItems: "start",
                                    justifyContent: "start",
                                    marginTop: 0
                                }}
                            >
                                {dataNotificationProjectList.map((notif: any, i: number) => {
                                    let maxLength = isMobile ? 3 : 5
                                    let deleteRequest = notif.title === "File Delete Request"
                                    let filename = notif.content?.split("/")?.at(-1) || ""

                                    if (i < maxLength)
                                        return (
                                            <div
                                                key={notif.fieldingRequestId + i}
                                                style={{
                                                    margin: "6px 16px",
                                                    padding: 2,
                                                    borderTop: "1px solid lightgray"
                                                }}
                                            >
                                                {deleteRequest ? (
                                                    <Box flexDirection={"column"}>
                                                        <img
                                                            src={notif.content + "?width=50"}
                                                            style={{ margin: 1, marginBottom: 4 }}
                                                        />
                                                        <ButtonOutlined
                                                            onClick={() => {
                                                                Swal.fire({
                                                                    title: "Do you want to delete this attachment?",
                                                                    // text: "Do you want to delete this attachment?",
                                                                    html: `<img src="${notif.content}?width=500" style={{ margin: 1 }} />`,
                                                                    // imageUrl: notif.content,
                                                                    // imageWidth: 800,
                                                                    // imageHeight: 500,
                                                                    width: 800,
                                                                    showCancelButton: true,
                                                                    imageAlt: filename,
                                                                    confirmButtonColor: "#3085d6",
                                                                    cancelButtonColor: "#d33",
                                                                    confirmButtonText: "Yes",
                                                                    cancelButtonText: "No"
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        handleConfirmDeleteAttachment(notif.id)
                                                                    } else if (
                                                                        result.dismiss === Swal.DismissReason.cancel
                                                                    ) {
                                                                        console.log("REJECT")
                                                                        // handleRead(notif.id)
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            Confirm
                                                        </ButtonOutlined>
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{ borderRadius: "50%", background: "lightgray" }}
                                                        padding={1}
                                                    >
                                                        <PersonOffOutlined sx={{ fontSize: "30px" }} />
                                                    </Box>
                                                )}
                                                <Box
                                                    flexDirection={"column"}
                                                    sx={{
                                                        alignItems: "start !important",
                                                        paddingLeft: 1,
                                                        position: "relative",
                                                        paddingBottom:
                                                            notif.title !== "Fielder Request Accepted" &&
                                                            notif.title !== "File Delete Request"
                                                                ? "18px"
                                                                : "0px"
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            justifyContent: "space-between",
                                                            width: "100%",
                                                            margin: 0
                                                        }}
                                                    >
                                                        <Typography sx={{ fontWeight: "bold" }}>
                                                            {notif.title}
                                                        </Typography>
                                                        <Typography>{notif.dateTime}</Typography>
                                                    </Box>
                                                    {deleteRequest ? (
                                                        <Box
                                                            flexDirection={"column"}
                                                            style={{
                                                                margin: 0
                                                            }}
                                                        >
                                                            <Typography>Project : {notif.projectName} </Typography>
                                                            <Typography>
                                                                File name :{" "}
                                                                <span style={{ fontWeight: "bold" }}>{filename}</span>
                                                            </Typography>
                                                        </Box>
                                                    ) : (
                                                        <>
                                                            <Typography>
                                                                {notif.content}{" "}
                                                                <span style={{ fontWeight: "bold" }}>
                                                                    {notif.projectName}
                                                                </span>
                                                            </Typography>
                                                            {notif.title !== "Fielder Request Accepted" ? (
                                                                <ButtonOutlined
                                                                    sx={{
                                                                        position: "absolute",
                                                                        right: 0,
                                                                        bottom: "-12px",
                                                                        // top: "54px",
                                                                        height: "25px"
                                                                    }}
                                                                    onClick={() =>
                                                                        navigate(
                                                                            "/project/assign?id=" +
                                                                                notif.fieldingRequestId
                                                                        )
                                                                    }
                                                                >
                                                                    Assignment
                                                                </ButtonOutlined>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    )}
                                                </Box>
                                            </div>
                                        )
                                })}
                            </Box>
                            <Button
                                fullWidth
                                sx={{ borderRadius: 0, background: "#80B8DD" }}
                                onClick={() => {
                                    if (dataNotificationProjectList.length > 0) navigate("/project-notification")
                                }}
                            >
                                View All
                            </Button>
                        </>
                    )}
                </Card>
            )}
            <IconButton
                color="inherit"
                onClick={() => handleShowNotif()}
                onBlur={() => {
                    setTimeout(() => {
                        setNotificationList(false)
                    }, 200)
                }}
            >
                <Badge badgeContent={checkUnReadData.length} color="error">
                    <Notifications />
                </Badge>
            </IconButton>
        </Fragment>
    )
}
