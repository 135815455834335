import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import moment, { Moment } from "moment"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { setDateSelected } from "../calendar.reducer"

export default function CalendarLittle() {

    const theme = useTheme()
    const dispatch = useDispatch<any>()

    const { dateSelected } = useSelector((state: RootState) => state.calendar)

    const dayNames = ["S", "M", "T", "W", "T", "F", "S"]

    let weekList: Moment[][] = []
    const firstDayOfMonth = dateSelected.clone().startOf('month')
    const firstWeekNumber = firstDayOfMonth.isoWeek();
    for (let i = firstWeekNumber; i <= firstWeekNumber + 5; i++) {
        const weekNumber = i
        const startDate = dateSelected.clone().week(weekNumber).startOf('week')
        let dateList = []
        for (let j = 0; j < 7; j++) {
            dateList.push(startDate.clone().add(j, "day"))
        }
        weekList.push(dateList)
    }

    const handlePrev = () => {
        dispatch(setDateSelected(dateSelected.clone().subtract(1, "month")))
    }

    const handleNext = () => {
        dispatch(setDateSelected(dateSelected.clone().add(1, "month")))
    }

    return (
        <Box>
            <Box display={"flex"} alignItems={"center"}>
                <IconButton onClick={handlePrev} >
                    <KeyboardArrowLeft />
                </IconButton>
                <Box width={"100%"}>
                    <Typography variant="h5" align="center">{dateSelected.format("MMMM YYYY")}</Typography>
                </Box>
                <IconButton onClick={handleNext} >
                    <KeyboardArrowRight />
                </IconButton>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        {dayNames.map((day, i) => (
                            <TableCell key={i} align="center" sx={{ padding: 1, borderBottomWidth: 0 }}>{day}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {weekList.map((dateList, i) => (
                        <TableRow key={i}>
                            {dateList.map((date, j) => {
                                const isNow = dateSelected.isSame(date, "day")
                                return (
                                    <TableCell key={j} sx={{ padding: 1, verticalAlign: "top", opacity: date.month() === dateSelected.month() ? 1 : 0.2, borderBottomWidth: 0, cursor: "pointer" }} onClick={() => dispatch(setDateSelected(date))}>
                                        <Typography
                                            align="center"
                                            borderRadius={1}
                                            color={isNow ? theme.palette.primary.contrastText : undefined}
                                            bgcolor={isNow ? theme.palette.primary.main : undefined}
                                            fontWeight={date.isSame(moment(), "date") ? "bold" : undefined}
                                        >
                                            {date.date()}
                                        </Typography>
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    )
}