import moment from "moment"
import React from "react"

const userInfo: any = () => {
    let fielderProUserAccount = localStorage.getItem("fielderProUserAccount") || JSON.stringify({ user: {} })
    let fielderProUserAccountJson = JSON.parse(fielderProUserAccount)
    return fielderProUserAccountJson
}

export { userInfo }

export function isEmptyString(str: string) {
    return !str || /^\s*$/.test(str)
}

export function useWindowSize() {
    const isSSR = typeof window !== "undefined"
    const [windowSize, setWindowSize] = React.useState({
        width: isSSR ? 1200 : window.innerWidth,
        height: isSSR ? 800 : window.innerHeight
    })

    function changeWindowSize() {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    }

    React.useEffect(() => {
        window.addEventListener("resize", changeWindowSize)

        return () => {
            window.removeEventListener("resize", changeWindowSize)
        }
    }, [])

    return windowSize
}

export const secondsFormat = (secondsInput: number) => {
    let secondsMoment = moment.duration(secondsInput, "seconds")
    let hours = secondsMoment.hours()
    let minutes = secondsMoment.minutes()
    let seconds = secondsMoment.seconds()
    return (
        String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0") + ":" + String(seconds).padStart(2, "0")
    )
}

export function formatBytes(bytes: any, decimals: number = 2) {
    if (!+bytes) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const filterListLocationLog = (travelLogs: any, timeStamp: any) => {
    if (!travelLogs && !travelLogs) return []
    let listLocation = timeStamp?.locationLogs
    let selected = travelLogs || null
    let currentTravelLog = timeStamp?.travelLogs.filter((log: any) => log.miles > 0)
    if (selected)
        listLocation = listLocation
            .filter((loc: any) => {
                let LowerIndex: any = currentTravelLog.findIndex(
                    (log: any) => log.miles === selected.miles && log.dateTime === selected.dateTime
                )

                let locationTime = new Date(loc.dateTime).getTime()
                let selectedTime = new Date(selected?.dateTime).getTime()

                if (locationTime <= selectedTime) {
                    if (LowerIndex > 0) {
                        let lowerLog = currentTravelLog[LowerIndex - 1] || null
                        if (lowerLog) if (locationTime > new Date(lowerLog.dateTime).getTime()) return loc
                    } else return loc
                }
            })
            .filter((loc: any) => loc !== false)

    console.log("listLocation", listLocation)
    return listLocation
}

export const formatName = (name: string) => {
    return name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
}