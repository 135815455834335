import { createAsyncThunk } from "@reduxjs/toolkit"
import { InterfaceExpenseGet } from "./expense.interface"
import axios from "axios"
import { baseUrl } from "../../state"
import handleError from "../../shared/handleError"
import { userInfo } from "../../shared/utils"

export const getExpense = createAsyncThunk("getExpense", async (data: InterfaceExpenseGet) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Billing/GetAllBillingHistory",
            data: {
                ...data,
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const approveExpense = createAsyncThunk("approveExpense", async (id: string) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Billing/SetApproval",
            data: {
                id,
                token: userInfo().token,
                isApproved: true
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const rejectExpense = createAsyncThunk("rejectExpense", async (data: { id: string, reason: string }) => {
    try {
        const response = await axios({
            method: "post",
            url: baseUrl + "/newapi/Billing/SetApproval",
            data: {
                id: data.id,
                approvalMessage: data.reason,
                token: userInfo().token,
                isApproved: false
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})

export const getExpenseDetail = createAsyncThunk("getExpenseDetail", async (id: string) => {
    try {
        const response = await axios({
            method: "get",
            url: baseUrl + "/newapi/Billing/GetDetails",
            params: {
                id,
                token: userInfo().token
            }
        })
        return response
    } catch (error: any) {
        handleError(error)
        return error.response
    }
})