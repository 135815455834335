import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import { Box, Button, Grid, IconButton, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material"
import moment, { Moment } from "moment"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { setDateSelected } from "../calendar.reducer"
import { useEffect, useState } from "react"
import Holidays from "date-holidays"
import { InterfaceCalendarHoliday, optionList } from "../calendar.interface"
import { getFieldingRequestByCalendar } from "../calendar.api"

export default function CalendarMain() {

    const theme = useTheme()
    const dispatch = useDispatch<any>()
    const isVerticalTablet = useMediaQuery("(max-width:1200px)")

    const { dateSelected, optionSelected, fielderIdSelected, fieldingReauestList } = useSelector((state: RootState) => state.calendar)

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    const [mode, setMode] = useState<"MONTH" | "WEEK" | "DAY">("MONTH")
    const [holidayList, setHolidayList] = useState<InterfaceCalendarHoliday[]>([])

    useEffect(() => {
        const hd = new Holidays("US")
        setHolidayList(hd.getHolidays(dateSelected.year()))
    }, [])

    useEffect(() => {
        dispatch(getFieldingRequestByCalendar({ year: dateSelected.year(), month: dateSelected.month() + 1, fielderId: fielderIdSelected }))
    }, [dateSelected.month(), fielderIdSelected])

    let weekList: Moment[][] = []
    const firstDayOfMonth = dateSelected.clone().startOf('month')
    const firstWeekNumber = firstDayOfMonth.isoWeek();
    for (let i = firstWeekNumber; i <= firstWeekNumber + 5; i++) {
        const weekNumber = i
        const startDate = dateSelected.clone().week(weekNumber).startOf('week')
        let dateList = []
        for (let j = 0; j < 7; j++) {
            dateList.push(startDate.clone().add(j, "day"))
        }
        weekList.push(dateList)
    }

    const handlePrev = () => {
        let newDate: Moment | undefined = undefined
        if (mode === "MONTH") {
            newDate = dateSelected.clone().subtract(1, "month")
        }
        else if (mode === "WEEK") {
            newDate = dateSelected.clone().subtract(1, "week")
        }
        else if (mode === "DAY") {
            newDate = dateSelected.clone().subtract(1, "day")
        }
        if (newDate) {
            dispatch(setDateSelected(newDate))
        }
    }

    const handleNext = () => {
        let newDate: Moment | undefined = undefined
        if (mode === "MONTH") {
            newDate = dateSelected.clone().add(1, "month")
        }
        else if (mode === "WEEK") {
            newDate = dateSelected.clone().add(1, "week")
        }
        else if (mode === "DAY") {
            newDate = dateSelected.clone().add(1, "day")
        }
        if (newDate) {
            dispatch(setDateSelected(newDate))
        }
    }

    const renderItem = (label: string, color: string | undefined) => {
        return (
            <Box key={label} bgcolor={color} paddingX={1} paddingY={"4px"} borderRadius={1} marginBottom={1}>
                <Typography color={"white"}>{label}</Typography>
            </Box>
        )
    }

    const tableCellProps: SxProps = { padding: 1, border: "1px solid rgba(224, 224, 224, 1)", verticalAlign: "top", width: `${100 / 7}%` }
    const thisWeek = weekList.find(dateList => dateList.some(date => dateSelected.isSame(date, "day")))
    const isShowHoliday = optionSelected.includes("holiday")
    const isShowFieldingAssign = optionSelected.includes("fielding_assign")

    return (
        <Box height={"100%"} paddingBottom={3}>
            <Box marginRight={1} marginBottom={1}>
                <Grid container spacing={1}>
                    <Grid item xs={6} md={4} display={"flex"} flexWrap={"nowrap"}>
                        <IconButton onClick={handlePrev} >
                            <KeyboardArrowLeft />
                        </IconButton>
                        <IconButton onClick={handleNext} >
                            <KeyboardArrowRight />
                        </IconButton>
                        <Button variant={dateSelected.isSame(moment(), "date") ? "contained" : "outlined"} onClick={() => dispatch(setDateSelected(moment()))}>
                            Today
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography variant="h5" align={isVerticalTablet ? "right" : "center"}>{dateSelected.format("MMMM YYYY")}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
                            <Button variant="contained" color={mode === "DAY" ? "primary" : "inherit"} onClick={() => setMode("DAY")}>Day</Button>
                            <Button variant="contained" color={mode === "WEEK" ? "primary" : "inherit"} onClick={() => setMode("WEEK")}>Week</Button>
                            <Button variant="contained" color={mode === "MONTH" ? "primary" : "inherit"} onClick={() => setMode("MONTH")}>Month</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <TableContainer sx={{ height: "100%", paddingRight: 1 }}>
                <Table stickyHeader sx={{ height: "100%" }}>
                    <TableHead>
                        <TableRow>
                            {(mode === "WEEK" || mode === "DAY") && (<TableCell></TableCell>)}
                            {(mode === "MONTH" || mode === "WEEK") && dayNames.map((day, i) => {
                                return (
                                    <TableCell key={i} align="center" sx={{ padding: 1, fontWeight: thisWeek && thisWeek[i].isSame(moment(), "date") ? "bold" : undefined }}>
                                        {day}
                                        {mode === "WEEK" && ` (${thisWeek && thisWeek[i].date()})`}
                                    </TableCell>
                                )
                            })}
                            {mode === "DAY" && (
                                <TableCell align="center" sx={{ padding: 1, fontWeight: dateSelected.isSame(moment(), "date") ? "bold" : undefined }}>
                                    {dayNames[dateSelected.weekday()]} ({dateSelected.date()})
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(mode === "WEEK") && (
                            <TableRow>
                                <TableCell sx={{ ...tableCellProps, width: "auto" }}></TableCell>
                                {thisWeek && thisWeek.map((date, j) => {
                                    const thisHolidayList = holidayList.filter(holiday => moment(holiday.date).isSame(date, "day"))
                                    const thisFieldingRequestList = fieldingReauestList.filter(fieldingRequest => date.isBetween(moment(fieldingRequest.firstAssignDate), moment(fieldingRequest.dueDate), "date"))
                                    return (
                                        <TableCell key={j} sx={{ ...tableCellProps, opacity: date.month() === dateSelected.month() ? 1 : 0.2 }}>
                                            {isShowHoliday && thisHolidayList.map((holiday, i) => renderItem(holiday.name, optionList.find(option => option.code === "holiday")?.color))}
                                            {isShowFieldingAssign && thisFieldingRequestList.map((fieldingRequest, i) => renderItem(`${fieldingRequest.project} - ${fieldingRequest.fieldingRequest}`, optionList.find(option => option.code === "fielding_assign")?.color))}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )}
                        {(mode === "DAY") && (
                            <TableRow>
                                <TableCell colSpan={2} sx={{ ...tableCellProps, width: "auto" }}>
                                    {isShowHoliday && holidayList.filter(holiday => moment(holiday.date).isSame(dateSelected, "day")).map((holiday, i) => renderItem(holiday.name, optionList.find(option => option.code === "holiday")?.color))}
                                    {isShowFieldingAssign && fieldingReauestList.filter(fieldingRequest => dateSelected.isBetween(moment(fieldingRequest.firstAssignDate), moment(fieldingRequest.dueDate), "date")).map((fieldingRequest, i) => renderItem(`${fieldingRequest.project} - ${fieldingRequest.fieldingRequest}`, optionList.find(option => option.code === "fielding_assign")?.color))}
                                </TableCell>
                            </TableRow>
                        )}
                        {mode === "MONTH" && (
                            weekList.map((dateList, i) => (
                                <TableRow key={i}>
                                    {dateList.map((date, j) => {
                                        const isNow = dateSelected.isSame(date, "day")
                                        const thisHolidayList = holidayList.filter(holiday => moment(holiday.date).isSame(date, "day"))
                                        const thisFieldingRequestList = fieldingReauestList.filter(fieldingRequest => date.isBetween(moment(fieldingRequest.firstAssignDate), moment(fieldingRequest.dueDate), "date"))
                                        return (
                                            <TableCell
                                                key={j}
                                                sx={{
                                                    ...tableCellProps,
                                                    borderWidth: date.isSame(moment(), "date") ? 3 : undefined,
                                                    borderColor: date.isSame(moment(), "date") ? "rgba(190, 190, 190, 1)" : undefined,
                                                    bgcolor: isNow ? `${theme.palette.primary.main}0F` : undefined,
                                                    opacity: date.month() === dateSelected.month() ? 1 : 0.2
                                                }}
                                            >
                                                <Typography variant={date.isSame(moment(), "date") ? "h4" : "h6"} fontWeight={date.isSame(moment(), "date") ? "bold" : undefined} align="right" borderRadius={1}>{date.date()}</Typography>
                                                {isShowHoliday && thisHolidayList.map((holiday, i) => renderItem(holiday.name, optionList.find(option => option.code === "holiday")?.color))}
                                                {isShowFieldingAssign && thisFieldingRequestList.map((fieldingRequest, i) => renderItem(`${fieldingRequest.project} - ${fieldingRequest.fieldingRequest}`, optionList.find(option => option.code === "fielding_assign")?.color))}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            ))
                        )}
                        {mode === "WEEK" && (
                            Array.from({ length: 24 }, (_, index) => index).map((hour) => {
                                return (
                                    <TableRow key={hour}>
                                        <TableCell align="center" width={50} sx={{ padding: 1, border: "1px solid rgba(224, 224, 224, 1)" }}>
                                            <Typography align="right" borderRadius={1}>{hour < 10 ? `0${hour}` : hour}</Typography>
                                        </TableCell>
                                        {thisWeek && thisWeek.map((date, j) => {
                                            const isNow = dateSelected.isSame(date, "day") && moment().isSame(moment({ hour: hour }), "hour")
                                            return (
                                                <TableCell key={j} sx={{ ...tableCellProps, bgcolor: isNow ? `${theme.palette.primary.main}0F` : undefined, opacity: date.month() === dateSelected.month() ? 1 : 0.2 }}>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })
                        )}
                        {mode === "DAY" && (
                            Array.from({ length: 24 }, (_, index) => index).map((hour) => {
                                const isNow = moment().isSame(moment({ hour: hour }), "hour")
                                return (
                                    <TableRow key={hour}>
                                        <TableCell align="center" width={50} sx={{ padding: 1, border: "1px solid rgba(224, 224, 224, 1)" }}>
                                            <Typography align="right" borderRadius={1}>{hour < 10 ? `0${hour}` : hour}</Typography>
                                        </TableCell>
                                        <TableCell sx={{ ...tableCellProps, width: "auto", bgcolor: isNow ? `${theme.palette.primary.main}0F` : undefined }}>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}