import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import "./assets/styles/styles.scss"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Layout from './containers/components/Layout';

import SignIn from './pages/auth/component/SignIn';
import SignUp from './pages/auth/component/SignUp';
import moment from 'moment';
import Verification from './pages/auth/component/Verification';
import roles from './shared/roles';
import FieldingUploadUnlogin from './pages/fielding/component/FieldingUploadUnlogin';
import SignPrivacy from './pages/auth/component/SignPrivacy';
import SignTerms from './pages/auth/component/SignTerms';
import routeList from './routeList';
import MyAccount from './pages/myaccount/component/MyAccount';
import { grey } from '@mui/material/colors';
import Notification from './pages/notification/components/Notification';
import NotificationProject from './pages/notification/components/NotificationProject';
// import Manifest from "/manifest.json"

function App() {

  const navigate = useNavigate()
  React.useEffect(() => {
    let localStorageUser = localStorage.getItem("fielderProUserAccount")
    let localStorageUserJson = JSON.parse(localStorageUser || "{}")
    if (!(["#/verification", "#/upload", "#/terms", "#/privacy"].includes(window.location.hash.split('?')[0]))) {
      if (localStorageUserJson.token) {
        let expiredIn = moment(localStorageUserJson.expiredTime).local()
        if (moment().diff(expiredIn, "seconds") > 0) {
          localStorage.removeItem('fielderProUserAccount')
          navigate("/signin")
        }
      }
      else {
        navigate('/signin')
      }
    }
  }, [])

  const theme = createTheme({
    palette: {
      primary: {
        main: "#093a93",
        contrastText: "#FFFFFF"
      },
      secondary: {
        main: grey[300],
        contrastText: grey[800]
      }
    },
    shape: {
      borderRadius: 20
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: "small",
          fullWidth: true,
          inputProps: {
            form: {
              autoComplete: "off"
            }
          }
        }
      },
      MuiGrid: {
        defaultProps: {
          alignItems: "center"
        }
      }
    }
  })



  const firstPath = () => {
    let results = routeList.filter(route => {
      return roles(route.access)
    })
    if (results.length > 0) {
      return results[0].path
    }
    return "/nopage"
  }

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {routeList.map((item, i) => (
          <Route key={i} path={item.path} element={<Layout component={item.component} />} />
        ))}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/myaccount" element={<Layout component={<MyAccount />} />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/upload" element={<FieldingUploadUnlogin />} />
        <Route path="/terms" element={<SignTerms />} />
        <Route path="/privacy" element={<SignPrivacy />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/project-notification" element={<NotificationProject />} />
        <Route path="/404" element={<>Not Found</>} />
        <Route path="/nopage" element={<Layout component={<></>} />} />
        <Route path="*" element={<Navigate to={firstPath()} />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;