import { createSlice } from "@reduxjs/toolkit";
import { InitialExpenseGet, InterfaceExpense, InterfaceExpenseDetail, InterfaceExpenseGet } from "./expense.interface";
import { approveExpense, getExpense, getExpenseDetail, rejectExpense } from "./expense.api";

export interface ExpenseState {
    loading: boolean;
    parameterGetData: InterfaceExpenseGet;
    dataList: InterfaceExpense[];
    dataTotal: number;
    dataDetail: InterfaceExpenseDetail | null;
}

export const initialState: ExpenseState = {
    loading: false,
    parameterGetData: InitialExpenseGet,
    dataList: [],
    dataTotal: 0,
    dataDetail: null
};

export const expenseSlice = createSlice({
    name: 'expense',
    initialState,
    reducers: {
        setParameterGetData: (state, action) => {
            state.parameterGetData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getExpense.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getExpense.fulfilled, (state, action) => {
            state.loading = false;
            state.dataList = action.payload.data.data.data;
            state.dataTotal = action.payload.data.data.total;
        });
        builder.addCase(getExpense.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(approveExpense.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(approveExpense.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(approveExpense.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(rejectExpense.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(rejectExpense.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(rejectExpense.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(getExpenseDetail.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getExpenseDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.dataDetail = action.payload.data.data;
        });
        builder.addCase(getExpenseDetail.rejected, (state, action) => {
            state.loading = false;
        });
        
    },
});

export const { setParameterGetData } = expenseSlice.actions;

export default expenseSlice.reducer;