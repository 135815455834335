import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export default function CustomLoading() {

    const loadingAuth = useSelector((state: RootState) => state.auth).loading
    const loadingProject = useSelector((state: RootState) => state.project).loading
    const loadingAccount = useSelector((state: RootState) => state.account).loading
    const loadingFielding = useSelector((state: RootState) => state.fielding).loading
    const loadingMyAccount = useSelector((state: RootState) => state.myAccount).loading
    const loadingExoense = useSelector((state: RootState) => state.expense).loading
    const loadingDashboard = useSelector((state: RootState) => state.dashboard).loading
    const loadingCalendar = useSelector((state: RootState) => state.calendar).loading

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: 5000 }}
            open={
                loadingAuth ||
                loadingProject ||
                loadingAccount ||
                loadingFielding ||
                loadingMyAccount ||
                loadingExoense ||
                loadingDashboard ||
                loadingCalendar
            }
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}