export const optionList = [
    {
        code: "fielding_assign",
        name: "Assign Date & Due Date",
        color: "#9b82ff"
    },
    {
        code: "fielding_date",
        name: "Fielding Date (TBC)",
        color: "#46ff36"
    },
    {
        code: "holiday",
        name: "Holidays",
        color: "#d1372c"
    },
    {
        code: "leave",
        name: "Leave (TBC)",
        color: "#ffc636"
    }
]

export interface InterfaceCalendarHoliday {
    date: string,
    name: string,
    type: "public" | "bank" | "school" | "optional" | "observance"
}

export interface InterfaceFieldingRequestByCalendar {
    id: string,
    fieldingRequest: string,
    project: string,
    firstAssignDate: string,
    firstAssignDateString: string,
    dueDate: string,
    dueDateString: string
}