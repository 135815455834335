import { Fragment, useState } from "react";
import disclaimerPdf from "../../../assets/Terms.pdf";
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SignTerms() {

    const [numOfPages, setNumOfPages] = useState(0)

    return (
        <Document
            file={disclaimerPdf}
            onLoadSuccess={(doc) => setNumOfPages(doc.numPages)}
            renderMode='svg'
            className={"pdfcontainer"}
        >
            {Array.from(new Array(numOfPages), (el, index) => (
                <Page
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={window.innerWidth}
                    className={"pdfpage"}
                />
            ))}
        </Document>
    );
}