import { useState } from "react";
import Header from "./Header";
import Sidetab from "./Sidetab";

export default function Layout(props: any) {

    const [expand, setExpand] = useState(false)

    const toggleExpand = () => {
        setExpand(!expand)
    }

    return (
        <>
            <Header toggleExpand={toggleExpand} />
            <div className="main">
                <Sidetab expand={expand} />
                <div className="content">
                    {props.component}
                </div>
            </div>
        </>
    )
}