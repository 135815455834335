import { Box, Card, CardContent, CardHeader, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useEffect, useState } from "react";
import moment from "moment";
import { getDashboardFieldingDate } from "../dashboard.api";
import CustomDatePicker from "../../../shared/customDatePicker/CustomDatePicker";
import CustomModal from "../../../shared/customModal/CustomModal";
import DashboardProject from "./DashboardProject";

export default function DashboardRequestActivityStatus(props: {onSelect: (projectId: string) => void }) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()
    const isMobile = useMediaQuery("(max-width:768px)")

    const { dataFieldingDate } = useSelector((state: RootState) => state.dashboard)

    const [dateStart, setDateStart] = useState(moment().add(-30, 'days').format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'))
    const [statusSelected, setStatusSelected] = useState<undefined | number>()

    useEffect(() => {
        dispatch(getDashboardFieldingDate({ filterStartDate: dateStart, filterEndData: dateEnd }))
    }, [dispatch, dateStart, dateEnd])

    return (
        <Card variant="outlined" sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <CustomModal
                open={statusSelected !== undefined}
                onClose={() => setStatusSelected(undefined)}
                title='Project List'
                size="xl"
                component={
                    <DashboardProject dateStart={dateStart} dateEnd={dateEnd} status={statusSelected} onSelect={props.onSelect}/>
                }
            />
            <CardHeader title="Activity Status by Due Date" className="custom-action" action={<CustomDatePicker dateStart={dateStart} dateEnd={dateEnd} setDateStart={setDateStart} setDateEnd={setDateEnd} />} />
            <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", height: "100%" }}>
                <Box display={"flex"} alignItems={"flex-end"} gap={1} height={"100%"}>
                    <Box
                        width={"100%"}
                        height={"100%"}
                        minHeight={200}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-end"}
                        component={"button"}
                        sx={{ cursor: "pointer", bgcolor: "transparent", border: "none" }}
                        onClick={() => setStatusSelected(0)}
                    >
                        <Box bgcolor={theme.palette.primary.main} width={"100%"} height={`${dataFieldingDate.newPercentage}%`}></Box>
                        <Box>
                            <Typography fontWeight={"light"}>New</Typography>
                            <Typography fontWeight={"bold"}>{dataFieldingDate.new} Fielding Request</Typography>
                            <Typography variant={isMobile ? "h6" : "h4"} color={theme.palette.primary.main}>{dataFieldingDate.newPercentage.toFixed(2)}%</Typography>
                        </Box>
                    </Box>
                    <Box
                        width={"100%"}
                        height={"100%"}
                        minHeight={200}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-end"}
                        component={"button"}
                        sx={{ cursor: "pointer", bgcolor: "transparent", border: "none" }}
                        onClick={() => setStatusSelected(2)}
                    >
                        <Box bgcolor={theme.palette.warning.main} width={"100%"} height={`${dataFieldingDate.onGoingPercentage}%`}></Box>
                        <Box>
                            <Typography fontWeight={"light"}>On Going</Typography>
                            <Typography fontWeight={"bold"}>{dataFieldingDate.onGoing} Fielding Request</Typography>
                            <Typography variant={isMobile ? "h6" : "h4"} color={theme.palette.warning.main}>{dataFieldingDate.onGoingPercentage.toFixed(2)}%</Typography>
                        </Box>
                    </Box>
                    <Box
                        width={"100%"}
                        height={"100%"}
                        minHeight={200}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-end"}
                        component={"button"}
                        sx={{ cursor: "pointer", bgcolor: "transparent", border: "none" }}
                        onClick={() => setStatusSelected(2)}
                    >
                        <Box bgcolor={theme.palette.error.main} width={"100%"} height={`${dataFieldingDate.overduePercentage}%`}></Box>
                        <Box>
                            <Typography fontWeight={"light"}>Overdue</Typography>
                            <Typography fontWeight={"bold"}>{dataFieldingDate.overdue} Fielding Request</Typography>
                            <Typography variant={isMobile ? "h6" : "h4"} color={theme.palette.error.main}>{dataFieldingDate.overduePercentage.toFixed(2)}%</Typography>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}