import { Autocomplete, Box, Button, Card, TextField, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { InitialDownloadTravelLog, InterfaceDownloadTravelLog, InterfaceFieldingDetail } from "../fielding.interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getAccountFielder, getServerTime } from "../../account/account.api";
import { InterfaceAccountFielderData } from "../../account/account.interface";
import { formatName } from "../../../shared/utils";
import moment from "moment";
import { createDownloadLog, createTravelLog } from "../fielding.api";
import Swal from "sweetalert2";

export default function FieldingDetailEnd(props: { details: InterfaceFieldingDetail, isContinueLater: boolean, onCancel: () => void, onConfirm: () => void }) {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const [values, setValues] = useState<InterfaceDownloadTravelLog>(InitialDownloadTravelLog);

    const { dataFielderList, serverTime, loading } = useSelector((state: RootState) => state.account)

    useEffect(() => {
        dispatch(getAccountFielder())
        dispatch(getServerTime())
    }, [dispatch])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        Swal.fire({
            icon: "question",
            title: "Are you sure that all inputs are complete and confirmed by both fielders?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        })
            .then(async response => {
                if (response.isConfirmed) {
                    try {
                        const responseDownloadLog = await dispatch(createDownloadLog(props.details.id)).unwrap()
                        if (responseDownloadLog.status === 200) {
                            const downloadLogId = responseDownloadLog.data.data.id
                            await dispatch(createTravelLog({
                                downloadLogId: downloadLogId,
                                isContinueLater: props.isContinueLater,
                                ...values
                            })).unwrap()
                            props.onConfirm();
                        }
                    } catch (err: any) { }
                }
            })
    }

    const travelLogs = props.details.mobileDownloadLogs.flatMap((log) => log.travelLogs).sort((a, b) => moment(a.dateTime).unix() - moment(b.dateTime).unix())
    const uniqDate = Array.from(new Set(travelLogs.map((log) => moment(log.dateTime).format("MM/DD/YYYY"))))

    if (dataFielderList.length === 0 || loading) {
        return (
            <Box>Loading...</Box>
        )
    }

    return (
        <Box component={"form"} onSubmit={handleSubmit}>
            <Box display={"flex"} flexDirection={"column"} gap={1}>
                {travelLogs.map((log, logIndex) => (
                    <Fragment key={logIndex}>
                        {(logIndex === 0 || moment(travelLogs[logIndex - 1].dateTime).format("MM/DD/YYYY") !== moment(log.dateTime).format("MM/DD/YYYY")) && (
                            <Typography marginTop={1}>
                                <Typography display={"inline"} fontWeight={"bold"}>Day {uniqDate.findIndex(d => d === moment(log.dateTime).format("MM/DD/YYYY")) + 1} </Typography>
                                <Typography display={"inline"} fontWeight={"light"}>{moment(log.dateTime).format("MM/DD/YYYY")}</Typography>
                            </Typography>
                        )}
                        <Card variant="outlined">
                            <Box display={"flex"} gap={1} margin={2}>
                                <TextField label="Initial Location" value={log.initialLocation} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
                                <TextField label="Next Location" value={log.nextLocation} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
                                <TextField label="Miles Traveled" value={log.miles} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} type="number" />
                                <Autocomplete
                                    fullWidth
                                    readOnly={true}
                                    options={dataFielderList}
                                    getOptionLabel={(value: InterfaceAccountFielderData) => formatName(value.fullName)}
                                    value={dataFielderList.find((data) => data.id === log.claimerId)}
                                    renderInput={(params) => <TextField label="Claimer" {...params} />}
                                />
                            </Box>
                        </Card>
                    </Fragment>
                ))}
                {(uniqDate.length === 0 || uniqDate[uniqDate.length - 1] !== moment(serverTime).format("MM/DD/YYYY")) && (
                    <Typography marginTop={1}>
                        <Typography display={"inline"} fontWeight={"bold"}>Day {uniqDate.length + 1} </Typography>
                        <Typography display={"inline"} fontWeight={"light"}>{moment(serverTime).format("MM/DD/YYYY")}</Typography>
                    </Typography>
                )}
                <Card variant="outlined">
                    <Box display={"flex"} gap={1} margin={2}>
                        <TextField required label="Initial Location" value={values.initialLocation} InputLabelProps={{ shrink: true }} onChange={e => setValues({ ...values, initialLocation: e.target.value })} />
                        <TextField required label="Next Location" value={values.nextLocation} InputLabelProps={{ shrink: true }} onChange={e => setValues({ ...values, nextLocation: e.target.value })} />
                        <TextField required label="Miles Traveled" value={values.miles} InputLabelProps={{ shrink: true }} onChange={e => setValues({ ...values, miles: parseFloat(e.target.value) })} type="number" />
                        <Autocomplete
                            fullWidth
                            options={dataFielderList.filter(data => props.details.fielderIds.includes(data.id))}
                            getOptionLabel={(value: InterfaceAccountFielderData) => formatName(value.fullName)}
                            value={dataFielderList.find((data) => data.id === values.claimerId)}
                            onChange={(e, value) => setValues({ ...values, claimerId: value?.id || "" })}
                            renderInput={(params) => <TextField {...params} label="Claimer" required />}
                        />
                    </Box>
                </Card>
            </Box>
            <Card variant="outlined" sx={{ marginTop: 2 }}>
                <Box display={"flex"} justifyContent={"space-between"} padding={2} bgcolor={`${theme.palette.primary.main}4F`}>
                    <Typography fontWeight={"bold"} color={theme.palette.primary.main}>Total Miles Travelled</Typography>
                    <Typography fontWeight={"bold"} color={theme.palette.primary.main}>{travelLogs.reduce((total, log) => total + log.miles, 0)} Miles</Typography>
                </Box>
            </Card >
            <Box display={"flex"} justifyContent={"space-between"} marginTop={2}>
                <Button variant="contained" color="inherit" onClick={props.onCancel}>Cancel</Button>
                <Button variant="contained" color="primary" type="submit">Confirm</Button>
            </Box>
        </Box >
    )
}