import { Box, Card, CardContent, CardHeader, Grid, Typography, useTheme } from "@mui/material";
import CustomDatePicker from "../../../shared/customDatePicker/CustomDatePicker";
import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getDashboardFieldingStatus } from "../dashboard.api";

export default function DashbordTotalFieldingRequest() {

    const dispatch = useDispatch<any>()
    const theme = useTheme()

    const { dataFieldingStatus } = useSelector((state: RootState) => state.dashboard)

    const [dateStart, setDateStart] = useState(moment().add(-30, 'days').format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'))

    useEffect(() => {
        dispatch(getDashboardFieldingStatus({ filterStartDate: dateStart, filterEndData: dateEnd }))
    }, [dispatch, dateStart, dateEnd])

    return (
        <Card variant="outlined" sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <CardHeader title="Total Request" action={<CustomDatePicker dateStart={dateStart} dateEnd={dateEnd} setDateStart={setDateStart} setDateEnd={setDateEnd} />} />
            <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                <Typography variant="h1">{dataFieldingStatus.total}</Typography>
                <Box display={"flex"}>
                    <Box bgcolor={theme.palette.primary.main} height={10} width={`${dataFieldingStatus.activePercentage}%`} ></Box>
                    <Box bgcolor={theme.palette.success.main} height={10} width={`${dataFieldingStatus.completedPercentage}%`} ></Box>
                    <Box bgcolor={theme.palette.warning.main} height={10} width={`${dataFieldingStatus.archivedPercentage}%`} ></Box>
                </Box>
                <Grid container spacing={2} marginTop={2}>
                    <Grid item xs={4}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Box height={8} width={8} borderRadius={4} bgcolor={theme.palette.primary.main}></Box>
                            <Box>
                                <Typography>Active</Typography>
                                <Typography variant="body2" display={"inline"} fontWeight={"light"}>{dataFieldingStatus.active} Request - </Typography>
                                <Typography variant="body2" display={"inline"}>{dataFieldingStatus.activePercentage.toFixed(0)}%</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Box height={8} width={8} borderRadius={4} bgcolor={theme.palette.success.main}></Box>
                            <Box>
                                <Typography>Completed</Typography>
                                <Typography variant="body2" display={"inline"} fontWeight={"light"}>{dataFieldingStatus.completed} Request - </Typography>
                                <Typography variant="body2" display={"inline"}>{dataFieldingStatus.completedPercentage.toFixed(0)}%</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Box height={8} width={8} borderRadius={4} bgcolor={theme.palette.warning.main}></Box>
                            <Box>
                                <Typography>Archived</Typography>
                                <Typography variant="body2" display={"inline"} fontWeight={"light"}>{dataFieldingStatus.archived} Request - </Typography>
                                <Typography variant="body2" display={"inline"}>{dataFieldingStatus.archivedPercentage.toFixed(0)}%</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}