import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { InterfaceFieldingNoteCreate } from "../../../fielding/fielding.interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { InterfaceAccountFielderData } from "../../../account/account.interface";
import { fieldingNoteCreate } from "../../../fielding/fielding.api";
import { formatName, userInfo } from "../../../../shared/utils";

export default function ModalAddNotes(props: {
    value: InterfaceFieldingNoteCreate,
    setValue: (params: InterfaceFieldingNoteCreate) => void,
    onSubmit: () => void,
    readOnly: boolean,
    submitOnSave: boolean,
    fieldingRequestId?: string
}) {

    const dispatch = useDispatch<any>()

    const { dataFielderList } = useSelector((state: RootState) => state.account)

    const handleSubmit = async () => {
        if (props.submitOnSave) {
            await dispatch(fieldingNoteCreate({
                ...props.value,
                fieldingRequestId: props.fieldingRequestId || "",
                token: userInfo().token
            }))
        }
        props.onSubmit()
    }

    return (
        <Box>
            <Box display={"flex"} alignItems={"center"} gap={1} sx={{minWidth:"400px"}}>
                <Box>
                    To :
                </Box>
                <Box display={"flex"} flexGrow={1}>
                    <Autocomplete
                        readOnly={props.readOnly}
                        fullWidth
                        multiple
                        options={dataFielderList}
                        getOptionLabel={(value: InterfaceAccountFielderData) => formatName(value.fullName)}
                        onChange={(e, value) => props.setValue({ ...props.value, fielderIdList: value.map(v => v.id) })}
                        value={dataFielderList.filter(data => props.value.fielderIdList.includes(data.id))}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Box>
            </Box>
            <TextField margin="dense" placeholder="Type your notes here..." multiline minRows={5} value={props.value.content} onChange={(e) => props.setValue({ ...props.value, content: e.target.value })} inputProps={{ readOnly: props.readOnly }} />
            <Box display={"flex"} justifyContent={"flex-end"}>
                <Button variant="contained" onClick={handleSubmit}>
                    Done
                </Button>
            </Box>
        </Box>
    )
}