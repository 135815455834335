import { useEffect, useRef, useState } from "react";
import { esriToken } from "../../../state";
import { InterfaceFieldingMarkerList, InterfaceMarkerPoint } from "../fielding.interface";

import esriConfig from "@arcgis/core/config"
import esriMap from "@arcgis/core/Map"
import esriMapView from "@arcgis/core/views/MapView"
import esriGraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import esriZoom from "@arcgis/core/widgets/Zoom"
import esriSearch from "@arcgis/core/widgets/Search"
import esriGraphics from "@arcgis/core/Graphic"
import esriPoint from "@arcgis/core/geometry/Point"
import esriMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"
import esriPolyline from "@arcgis/core/geometry/Polyline"
import esriSimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol"
import esriPolygon from "@arcgis/core/geometry/Polygon"
import esriSimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol"
import esriTextSymbol from "@arcgis/core/symbols/TextSymbol"

interface interfaceFieldingRequest {
    name: string,
    markers: InterfaceFieldingMarkerList,
    color: string
}

export default function FieldingDetailMap(props: {
    fieldingRequest: interfaceFieldingRequest,
    onClickGraphic: any,
    center: InterfaceMarkerPoint | null
}) {

    const mapRef = useRef<any>()

    const [layerMarker, setLayerMarker] = useState<any>()

    useEffect(() => {
        // initialize
        esriConfig.apiKey = esriToken
        let map = new esriMap({
            basemap: "topo-vector" //"satellite"
        })
        let layerMarkerLocal = new esriGraphicsLayer()
        let layerMarkerTempLocal = new esriGraphicsLayer()
        map.add(layerMarkerLocal)
        map.add(layerMarkerTempLocal)
        let view = new esriMapView({
            container: mapRef.current,
            map: map,
            zoom: props.center !== null ? 15 : 5,
            center: props.center !== null ? [props.center.longitude, props.center.latitude] : [-99.09764528271961, 33.173506394266624]
        })
        setLayerMarker(layerMarkerLocal)
        // widget
        let viewZoom = new esriZoom({ view: view })
        view.ui.remove(viewZoom)
        let viewSearch = new esriSearch({ view: view, locationEnabled: false })
        view.ui.add(viewSearch, { position: "top-left" })
        // listener
        view.on("click", (event) => {
            view.hitTest(event)
                .then((response) => {
                    let firstResult = response.results[0]
                    if (firstResult.type === "graphic") {
                        if (firstResult.graphic.attributes?.type === "FIELDING_DOT" || firstResult.graphic.attributes?.type === "FIELDING_LINE" || firstResult.graphic.attributes?.type === "FIELDING_SHAPE" || firstResult.graphic.attributes?.type === "FIELDING_TEXT") {
                            props.onClickGraphic(firstResult.graphic.attributes?.markerId)
                        }
                    }
                })
        })
        view.on("layerview-create", () => {
            const inputElement = document.querySelector<HTMLInputElement>('.esri-search__input');
            if (inputElement) {
                inputElement.placeholder = 'Search';
            }
        })
        view.on("pointer-move", (event) => {
            view.hitTest(event).then((response) => {
                let firstResult = response.results[0]
                if (firstResult.type === "graphic") {
                    if (
                        firstResult.graphic.attributes?.type === "FIELDING_DOT" ||
                        firstResult.graphic.attributes?.type === "FIELDING_LINE" ||
                        firstResult.graphic.attributes?.type === "FIELDING_SHAPE" ||
                        firstResult.graphic.attributes?.type === "FIELDING_TEXT"
                    ) {
                        view.container.style.cursor = "pointer";
                    }
                    else {
                        view.container.style.cursor = "default";
                    }
                }
            });
        });
        return () => view?.destroy()
    }, [])

    useEffect(() => {
        if (layerMarker) {
            let textMarkers: Array<any> = []
            let pointMarkers: Array<any> = []
            let lineMarkers: Array<any> = []
            let shapeMarkers: Array<any> = []
            pointMarkers = [...pointMarkers, ...props.fieldingRequest.markers.filter(marker => marker.dotLongLat !== '').map(marker => {
                textMarkers.push(new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(marker.dotLongLat.split(',')[1]),
                        longitude: parseFloat(marker.dotLongLat.split(',')[0])
                    }),
                    symbol: new esriTextSymbol({
                        color: "black",
                        text: props.fieldingRequest.name,
                        xoffset: 40,
                        yoffset: -25,
                        font: {
                            size: 12,
                            family: "Roboto"
                        }
                    }),
                    attributes: {
                        type: "FIELDING_TEXT",
                        markerId: marker.id
                    }
                }))
                return new esriGraphics({
                    geometry: new esriPoint({
                        latitude: parseFloat(marker.dotLongLat.split(',')[1]),
                        longitude: parseFloat(marker.dotLongLat.split(',')[0])
                    }),
                    symbol: new esriMarkerSymbol({
                        style: "circle",
                        color: "red"
                    }),
                    attributes: {
                        type: "FIELDING_DOT",
                        markerId: marker.id
                    }
                })
            })]
            props.fieldingRequest.markers.filter(marker => marker.dotLongLat === '').map(marker => {
                marker.lineData.map(lineData => {
                    try {
                        textMarkers.push(new esriGraphics({
                            geometry: new esriPoint({
                                latitude: lineData[0].latitude,
                                longitude: lineData[0].longitude
                            }),
                            symbol: new esriTextSymbol({
                                color: "black",
                                text: props.fieldingRequest.name,
                                xoffset: 40,
                                yoffset: -25,
                                font: {
                                    size: 12,
                                    family: "Roboto"
                                }
                            }),
                            attributes: {
                                type: "FIELDING_TEXT",
                                markerId: marker.id
                            }
                        }))
                        let lineMarker = new esriGraphics({
                            geometry: new esriPolyline({
                                paths: [lineData.map((line) => [line.longitude, line.latitude])]
                            }),
                            symbol: new esriSimpleLineSymbol({
                                color: props.fieldingRequest.color,
                                width: 2
                            }),
                            attributes: {
                                type: "FIELDING_LINE",
                                markerId: marker.id
                            }
                        })
                        lineMarkers = [...lineMarkers, lineMarker]
                    } catch (error) {
                        console.log(error)
                    }
                    return null
                })
                return null
            })
            props.fieldingRequest.markers.filter(marker => marker.dotLongLat === '').map(marker => {
                marker.shapeData.map(shapeData => {
                    try {
                        textMarkers.push(new esriGraphics({
                            geometry: new esriPoint({
                                latitude: shapeData[0].latitude,
                                longitude: shapeData[0].longitude
                            }),
                            symbol: new esriTextSymbol({
                                color: "black",
                                text: props.fieldingRequest.name,
                                xoffset: 40,
                                yoffset: -25,
                                font: {
                                    size: 12,
                                    family: "Roboto"
                                }
                            }),
                            attributes: {
                                type: "FIELDING_TEXT",
                                markerId: marker.id
                            }
                        }))
                        let shapeMarker = new esriGraphics({
                            geometry: new esriPolygon({
                                rings: [shapeData.map((shape) => [shape.longitude, shape.latitude])]
                            }),
                            symbol: new esriSimpleFillSymbol({
                                color: [10, 10, 10, 0.5],
                                outline: {
                                    color: props.fieldingRequest.color,
                                    width: 2
                                }
                            }),
                            attributes: {
                                type: "FIELDING_SHAPE",
                                markerId: marker.id
                            }
                        })
                        shapeMarkers = [...shapeMarkers, shapeMarker]
                    } catch (error) {
                        console.log(error)
                    }
                    return null
                })
                return null
            })
            layerMarker.removeAll()
            if (pointMarkers.length > 0) {
                layerMarker.addMany(pointMarkers)
            }
            if (lineMarkers.length > 0) {
                layerMarker.addMany(lineMarkers)
            }
            if (shapeMarkers.length > 0) {
                layerMarker.addMany(shapeMarkers)
            }
            if (textMarkers.length > 0) {
                layerMarker.addMany(textMarkers)
            }
        }
    }, [layerMarker, props.fieldingRequest])

    return (
        <div className="arcgis-container">
            <div ref={mapRef} style={{ height: "100%", width: "100%" }} />
        </div>
    )
}