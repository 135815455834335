import { AxiosError } from "axios";
import Swal from "sweetalert2";

export default function handleError(error: any) {
    const data
        : {
            status: boolean,
            message: string | null,
            data: null,
            errors: Array<{
                key: string,
                errorMessage: string
            }> | null
        }
        = error.response.data
    let message = ""
    if (data.errors) {
        message = data.errors[0].errorMessage
    }
    else {
        message = data.message || ""
    }
    if (message.toLowerCase().includes("token")) {
        window.location.replace('/#/signin')
    }
    else {
        console.log("handleError",message);
        
        return new Promise(resolve => {
            Swal.fire({
                icon: "error",
                text: message
            })
                .then(result => {
                    resolve(result)
                })
        })
    }
}