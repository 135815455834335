import { ArrowBackIos, ArrowForwardIos, Close, Download } from "@mui/icons-material"
import { Button, CircularProgress, Grid, IconButton, LinearProgress, Modal, Tooltip, Typography } from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"
import { InterfaceFieldingDetailMarkerAttachment } from "../../fielding/fielding.interface"

export default function ProjectFormFieldingMarkerImagePreview(props: {
    data: InterfaceFieldingDetailMarkerAttachment
    isOpen: boolean
    onClose: any
    onLeft: any
    onRight: any
}) {
    const [LoadingImage, setLoadingImage] = useState(true)
    const [downloadImages, setDownloadImages] = useState<any>({
        isDownloading: false,
        progressValue: 70
    })

    const handleDownload = () => {
        setDownloadImages({
            isDownloading: true,
            progressValue: 0
        })
        axios({
            url: props.data.filePath,
            method: "GET",
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                setDownloadImages({
                    isDownloading: true,
                    progressValue: percentCompleted
                })
                console.log(percentCompleted)
            }
        }).then((response) => {
            const downloadLink = document.createElement("a")
            downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
            downloadLink.setAttribute("download", props.data.fileName)
            document.body.appendChild(downloadLink)
            downloadLink.click()
            downloadLink.remove()
            setDownloadImages({
                isDownloading: false,
                progressValue: 0
            })
        })
    }

    const timeOffsetUpload = [
        {
            offset: 0,
            text: "-"
        },
        {
            offset: 240,
            text: "Eastern Daylight Time (EDT)"
        },
        {
            offset: 300,
            text: "Eastern Standard Time (EST)"
        },
        {
            offset: 480,
            text: "Pacific Standard Time (PST)"
        },
        {
            offset: 420,
            text: "Pacific Daylight Time (PDT)"
        },
        {
            offset: 300,
            text: "Central Daylight Time (CDT)"
        },
        {
            offset: 360,
            text: "Central Standard Time (CST)"
        },
        {
            offset: 360,
            text: "Mountain Daylight Time (MDT)"
        },
        {
            offset: 420,
            text: "Mountain Standard Time (MST)"
        },
        {
            offset: 600,
            text: "Hawaii Standard Time (HWT)"
        },
        {
            offset: 540,
            text: "Hawaii-Aleutian Daylight Time (HADT)"
        }
    ]

    return (
        <Modal className="custom_modal" open={props.isOpen}>
            <div className="image-detail">
                <div className="content">
                    <div
                        className="container"
                        style={{ height: "92vh", overflow: "auto", display: "flex", justifyContent: "center" }}
                    >
                        <Grid container alignItems={"center"} justifyContent={"center"}>
                            <Grid item md={6} padding={2} sx={{ position: "relative" }}>
                                {LoadingImage && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <CircularProgress color="inherit" />
                                    </div>
                                )}
                                <img
                                    src={props.data?.filePath}
                                    alt="image"
                                    style={{ width: "100%", height: "auto" }}
                                    loading="lazy"
                                    onLoad={() => setLoadingImage(false)}
                                />
                            </Grid>
                            <Grid item md={6} padding={2}>
                                <Typography variant="h5">Image Info :</Typography>
                                <table className="table" style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td>Timezone Job Location</td>
                                            <td>
                                                {timeOffsetUpload.filter(
                                                    (data) => data.offset === props.data.selectedUploadTimeOffset
                                                ).length > 0
                                                    ? timeOffsetUpload.filter(
                                                        (data) => data.offset === props.data.selectedUploadTimeOffset
                                                    )[0].text
                                                    : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Filename</td>
                                            <td>{props.data.fileName}</td>
                                        </tr>
                                        <tr>
                                            <td>Image Date Taken Local</td>
                                            <td>{props.data.detectedImageTakenDate}</td>
                                        </tr>
                                        <tr>
                                            <td>Image Date Taken Server Time</td>
                                            <td>{props.data.serverDetectedImageTakenDate}</td>
                                        </tr>
                                        <tr>
                                            <td>Upload Time ( Server Time (UTC-7) )</td>
                                            <td>{props.data.uploadDateString}</td>
                                        </tr>
                                        <tr>
                                            <td>Uploaded by </td>
                                            <td>{props.data?.uploadedBy}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="close">
                        <Tooltip title="Close">
                            <IconButton onClick={props.onClose} color="inherit">
                                <Close />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="download">
                        <Button startIcon={<Download />} color="inherit" onClick={() => !downloadImages.isDownloading && handleDownload()}>
                            Download Image
                        </Button>
                        {downloadImages.isDownloading && (<LinearProgress variant="determinate" value={downloadImages.progressValue} />)}
                    </div>
                    {props.onLeft !== null && (
                        <div className="left">
                            <IconButton color="inherit" onClick={props.onLeft}>
                                <ArrowBackIos />
                            </IconButton>
                        </div>
                    )}
                    {props.onRight !== null && (
                        <div className="right">
                            <IconButton color="inherit" onClick={props.onRight}>
                                <ArrowForwardIos />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}
