import esriConfig from "@arcgis/core/config"
import esriMap from "@arcgis/core/Map"
import esriMapView from "@arcgis/core/views/MapView"
import esriGraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import esriZoom from "@arcgis/core/widgets/Zoom"
import esriSearch from "@arcgis/core/widgets/Search"
import esriGraphics from "@arcgis/core/Graphic"
import esriPoint from "@arcgis/core/geometry/Point"
import esriMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"
import esriPolygon from "@arcgis/core/geometry/Polygon"
import esriPolyline from "@arcgis/core/geometry/Polyline"
import esriSimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol"
import esriSimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol"
import esriTextSymbol from "@arcgis/core/symbols/TextSymbol"

import { useEffect, useRef, useState } from "react";
import { esriToken } from "../../../state";
import { InterfaceFieldingDetail } from "../../fielding/fielding.interface";
import { InterfaceAccountFielderDetailDataList } from "../../account/account.interface"
import { formatName } from "../../../shared/utils"

export default function ProjectAssignMap(props: {
    fieldingRequest: InterfaceFieldingDetail,
    fielders: InterfaceAccountFielderDetailDataList,
    fielderSelectedIds: Array<string>
}) {

    const mapRef = useRef<any>()

    const [layerMarker, setLayerMarker] = useState<any>()

    useEffect(() => {
        // initialize
        esriConfig.apiKey = esriToken
        let map = new esriMap({
            basemap: "topo-vector" //"satellite"
        })
        let layerMarkerLocal = new esriGraphicsLayer()
        let layerMarkerTempLocal = new esriGraphicsLayer()
        map.add(layerMarkerLocal)
        map.add(layerMarkerTempLocal)
        let view = new esriMapView({
            container: mapRef.current,
            map: map,
            zoom: 15,
            center: props.fieldingRequest.firstMarkerLocation.split(',').map(d => parseFloat(d))
        })
        // widget
        let viewZoom = new esriZoom({ view: view })
        view.ui.remove(viewZoom)
        let viewSearch = new esriSearch({ view: view, locationEnabled: false })
        view.ui.add(viewSearch, { position: "top-left" })
        // listener
        view.on("layerview-create", () => {
            if (document.getElementById("searchbox-input")) {
                (document.getElementById("searchbox-input") as HTMLInputElement).placeholder = "Enter adress or GPS"
            }
        })
        setLayerMarker(layerMarkerLocal)
        return () => view?.destroy()
    }, [])

    useEffect(() => {
        if (layerMarker) {
            let markers: Array<any> = []
            props.fieldingRequest.fieldingRequestMarkers.map(marker => {
                if (marker.dotLongLat !== '') {
                    markers.push(new esriGraphics({
                        geometry: new esriPoint({
                            latitude: parseFloat(marker.dotLongLat.split(',')[1]),
                            longitude: parseFloat(marker.dotLongLat.split(',')[0])
                        }),
                        symbol: new esriMarkerSymbol({
                            style: "circle",
                            color: "red"
                        })
                    }))
                }
                else {
                    if (marker.shapeData.length > 0) {
                        marker.shapeData.map(shapeData => {
                            markers.push(new esriGraphics({
                                geometry: new esriPolygon({
                                    rings: [shapeData.map((shape: any) => [shape.longitude, shape.latitude])]
                                }),
                                symbol: new esriSimpleFillSymbol({
                                    color: [10, 10, 10, 0.2],
                                    outline: {
                                        color: "red",
                                        width: 2
                                    }
                                })
                            }))
                        })
                    }
                    else if (marker.lineData.length > 0) {
                        marker.lineData.map(lineData => {
                            markers.push(new esriGraphics({
                                geometry: new esriPolyline({
                                    paths: [lineData.map((line) => [line.longitude, line.latitude])]
                                }),
                                symbol: new esriSimpleLineSymbol({
                                    color: "red",
                                    width: 2
                                }),
                            }))
                        })
                    }
                }
            })
            props.fielders.map(fielder => {
                if (fielder.workingAreaPolyData !== null && fielder.workingAreaPolyData.includes('[') && props.fielderSelectedIds.includes(fielder.id)) {
                    let shapeData = JSON.parse(fielder.workingAreaPolyData)
                    if (shapeData.length > 0) {
                        markers.push(new esriGraphics({
                            geometry: new esriPoint({
                                latitude: shapeData[0].latitude,
                                longitude: shapeData[0].longitude
                            }),
                            symbol: new esriTextSymbol({
                                color: fielder.color,
                                text: formatName(fielder.fullName),
                                xoffset: 40,
                                yoffset: -25,
                                font: {
                                    size: 12,
                                    family: "Roboto"
                                },
                                backgroundColor: "white"
                            })
                        }))
                    }
                    markers.push(new esriGraphics({
                        geometry: new esriPolygon({
                            rings: [shapeData.map((shape: any) => [shape.longitude, shape.latitude])]
                        }),
                        symbol: new esriSimpleFillSymbol({
                            color: [10, 10, 10, 0.2],
                            outline: {
                                color: fielder.color,
                                width: 2
                            }
                        })
                    }))
                    if (fielder.addressLocation) {
                        markers.push(new esriGraphics({
                            geometry: new esriPoint({
                                latitude: fielder.addressLocation?.latitude || 0,
                                longitude: fielder.addressLocation?.longitude || 0
                            }),
                            symbol: new esriMarkerSymbol({
                                style: "triangle",
                                color: fielder.color,
                                size: 15
                            })
                        }))
                    }
                    fielder.assignedFieldingRequestMarkerLocations.map(location => {
                        if (Boolean(location)) {
                            markers.push(new esriGraphics({
                                geometry: new esriPoint({
                                    latitude: parseFloat(location.split(',')[1]),
                                    longitude: parseFloat(location.split(',')[0])
                                }),
                                symbol: new esriMarkerSymbol({
                                    style: "circle",
                                    color: fielder.color
                                })
                            }))
                        }
                    })
                }
            })
            layerMarker.removeAll()
            if (markers.length > 0) {
                layerMarker.addMany(markers)
            }
        }
    }, [layerMarker, props.fieldingRequest, props.fielderSelectedIds])

    return (
        <div className="arcgis-container">
            <div ref={mapRef} style={{ height: "100%", width: "100%" }} />
        </div>
    )
}