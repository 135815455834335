import { Cancel, Check, Close, Image } from "@mui/icons-material"
import { Box, Button, CircularProgress, IconButton, LinearProgress } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import CustomModal from "../../../shared/customModal/CustomModal"
import axios from "axios"
import { formatBytes, userInfo } from "../../../shared/utils"
import { baseUrl } from "../../../state"
import notificationError from "../../../shared/notificationError"
import notificationSuccess from "../../../shared/notificationSuccess"
import { Dropzone } from "@dropzone-ui/react"
import ButtonOutlined from "../../../shared/ButtonOutlined"

export default function ProjectFormFieldingUpload(props: { fieldingRequestId: string; onUpdate: any }) {
    const [modalUpload, setModalUpload] = useState(false)
    const [filesQueue, setFilesQueue] = useState<any>([])
    const [isUploading, setIsUploading] = useState(false)
    const [OnCancel, setOnCancel] = useState(false)
    const [ControllerUpload, setControllerUpload] = useState<any>()

    useEffect(() => {
        if (!isUploading && filesQueue.length > 0) {
            setIsUploading(true)
            uploading()
        }
        if (filesQueue.length === 0) setIsUploading(false)
    }, [filesQueue, isUploading])

    const onChangeImage = async (filesLocal: any) => {
        if (filesLocal.length > 0) {
            setFilesQueue([
                ...filesQueue,
                ...Array.from(filesLocal).map((file: any) => {
                    return {
                        name: file.name,
                        uploadProgress: 0,
                        file: file.file
                    }
                })
            ])
        }
    }

    const uploading = async (filesPassing = null) => {
        let canceled = false

        let filesLocal: any = filesPassing ? filesPassing : filesQueue
        let filesToUpload: any = filesLocal.filter((file: any) => file.uploadProgress < 100 && !file.abort)

        if (filesToUpload.length > 0) {
            let aFailedFile = false
            for (const i in filesToUpload) {
                try {
                    let data = new FormData()
                    data.append("fieldingRequestId", props.fieldingRequestId)
                    data.append("token", userInfo().token)
                    data.append("file", filesToUpload[i].file)

                    if (filesToUpload?.abort) break
                    const controller = new window.AbortController()
                    // return
                    await axios.post(`${baseUrl}/newapi/FieldingRequest/UploadAttachments`, data, {
                        signal: controller.signal,
                        onUploadProgress: (progressEvent) => {
                            setControllerUpload(controller)
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
                            filesLocal = filesLocal.map((f: any) => {
                                if (f.name === filesToUpload[i].name && percentCompleted > f.uploadProgress) {
                                    return {
                                        ...f,
                                        uploadProgress: percentCompleted,
                                        loadedtBytes: progressEvent?.loaded ? progressEvent?.loaded : 0,
                                        totalBytes: progressEvent?.total ? progressEvent?.total : 0
                                    }
                                }
                                return f
                            })
                            setFilesQueue(filesLocal)
                        }
                    })
                } catch (error: any) {
                    if (axios.isCancel(error)) {
                        console.log("Request Canceled ====================")
                        canceled = true
                        setTimeout(() => {
                            setIsUploading(false)
                        }, 300)

                        break
                    } else {
                        console.log(error)
                        setFilesQueue((prev: any) =>
                            prev.map((f: any) => (f.name === filesToUpload[i].name ? { ...f, failed: true } : f))
                        )
                        aFailedFile = true
                        return notificationError(
                            error.response
                                ? error.response
                                : error?.message === "Network Error"
                                ? "Request entity too large"
                                : error?.message
                        )
                    }
                }
            }
            if (!canceled)
                notificationSuccess("Uploaded complete").then(() => {
                    if (!aFailedFile) {
                        props.onUpdate()
                        setModalUpload(false)
                        setFilesQueue([])
                    }
                    setIsUploading(false)
                })
        } else
            notificationSuccess("Canceled upload").then(() => {
                props.onUpdate()
                setModalUpload(false)
            })
    }

    const handleCancelFile = async (file: any, retry: any = false) => {
        setOnCancel(true)
        let newQueue = await filesQueue
            .map((fq: any) => {
                ControllerUpload.abort()
                if (retry) return { ...fq, retry: fq.name === file.name }
                if (fq.name !== file.name && !retry) return { ...fq, abort: fq.name === file.name }
            })
            .filter((fl: any) => fl)

        setFilesQueue(newQueue)
        setTimeout(() => {
            setOnCancel(false)
        }, 1500)
    }

    return (
        <Fragment>
            <CustomModal
                open={modalUpload}
                onClose={() => setModalUpload(false)}
                title="Upload File"
                size="sm"
                component={
                    <Box>
                        <Dropzone onChange={onChangeImage} header={false} disabled={isUploading} />
                        {filesQueue.map((file: any, i: number) => (
                            <Box key={i + file.name}>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    {file.name}
                                    <div>
                                        {file.totalBytes && file.totalBytes > 0 ? (
                                            <>
                                                {!OnCancel && file.uploadProgress < 90 && !file.failed && (
                                                    <CircularProgress
                                                        color="inherit"
                                                        style={{ width: "10px", height: "10px", marginRight: 4 }}
                                                    />
                                                )}
                                                {`${formatBytes(file.loadedtBytes, 1)} / ${formatBytes(
                                                    file.totalBytes,
                                                    1
                                                )}`}
                                            </>
                                        ) : (
                                            <>
                                                <span style={{ color: "lightgray", paddingRight: 5 }}>waiting</span>
                                                {formatBytes(file.file.size)}
                                            </>
                                        )}
                                        <IconButton>
                                            {file.uploadProgress < 100 ? (
                                                file.uploadProgress > 90 ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        style={{ width: "15px", height: "15px" }}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}
                                                    >
                                                        {OnCancel && (
                                                            <CircularProgress
                                                                color="inherit"
                                                                style={{
                                                                    width: "10px",
                                                                    height: "10px",
                                                                    position: "absolute"
                                                                }}
                                                            />
                                                        )}
                                                        <Cancel
                                                            style={{ color: OnCancel ? "lightgray" : "" }}
                                                            onClick={() => (!OnCancel ? handleCancelFile(file) : "")}
                                                        />
                                                    </div>
                                                )
                                            ) : file.failed || file.abort ? (
                                                OnCancel ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        style={{
                                                            width: "10px",
                                                            height: "10px"
                                                            // position: "absolute"
                                                        }}
                                                    />
                                                ) : (
                                                    <Close
                                                        color="error"
                                                        onClick={() => (!OnCancel ? handleCancelFile(file, true) : "")}
                                                    />
                                                )
                                            ) : (
                                                <Check color="success" />
                                            )}
                                        </IconButton>
                                    </div>
                                </Box>
                                <LinearProgress variant="determinate" value={file.uploadProgress} />
                            </Box>
                        ))}
                    </Box>
                }
            />
            <ButtonOutlined startIcon={<Image />} onClick={() => setModalUpload(true)}>
                Upload Attachment
            </ButtonOutlined>
        </Fragment>
    )
}
