import { createSlice } from "@reduxjs/toolkit"
import moment, { Moment } from "moment"
import { InterfaceFieldingRequestByCalendar } from "./calendar.interface"
import { getFieldingRequestByCalendar } from "./calendar.api"

export interface CalendarState {
    loading: boolean,
    fieldingReauestList: InterfaceFieldingRequestByCalendar[],
    dateSelected: Moment,
    optionSelected: string[],
    fielderIdSelected?: string
}
const initialState: CalendarState = {
    loading: false,
    fieldingReauestList: [],
    dateSelected: moment(),
    optionSelected: ["fielding_assign", "holiday"]
}

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setDateSelected: (state, action) => {
            state.dateSelected = action.payload
        },
        toggleOptionSelected: (state, action) => {
            if (state.optionSelected.includes(action.payload)) {
                state.optionSelected = state.optionSelected.filter(option => option !== action.payload)
            }
            else {
                state.optionSelected.push(action.payload)
            }
        },
        setFielderIdSelected: (state, action) => {
            state.fielderIdSelected = action.payload
        }
    },
    extraReducers: (builder) => {

        builder.addCase(getFieldingRequestByCalendar.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getFieldingRequestByCalendar.fulfilled, (state, action) => {
            state.loading = false;
            state.fieldingReauestList = action.payload.data.data || [];
        })
        builder.addCase(getFieldingRequestByCalendar.rejected, (state) => {
            state.loading = false;
        })

    }
})

export const { setDateSelected, toggleOptionSelected, setFielderIdSelected } = calendarSlice.actions

export default calendarSlice.reducer