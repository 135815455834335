import { Cancel, Check } from "@mui/icons-material";
import { Autocomplete, Box, Card, CardContent, IconButton, LinearProgress, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { accountValidateToken } from "../../account/account.api";
import notificationError from "../../../shared/notificationError";
import bg_auth from "../../../assets/images/bg_auth.png";
import moment from "moment-timezone";
import axios from "axios";
import { baseUrl } from "../../../state";
import { FieldingMarkerDefaultTimeOffsetGetFieldingId } from "../fielding.api";
import uploadLogo from "../../../assets/images/uploadLogo.jpeg";
import notificationSuccess from "../../../shared/notificationSuccess";
import { Dropzone } from "@dropzone-ui/react";

export default function FieldingUploadUnlogin() {

    const dispatch: any = useDispatch()
    const [searchParams] = useSearchParams()

    const [validateData, setValidateData] = useState<{
        userToken: string,
        userId: string,
        fieldingRequestId: string,
        fieldingCategory: number
    }>({
        userToken: "",
        userId: "",
        fieldingRequestId: "",
        fieldingCategory: 0
    })
    const [timeOffsetSelected, setTimeOffsetSelected] = useState<number>(0)
    const [filesQueue, setFilesQueue] = useState<any>([])
    const [isUploading, setIsUploading] = useState(false)

    useEffect(() => {
        dispatch(accountValidateToken(searchParams.get("token") || ""))
            .unwrap()
            .then((response: any) => {
                setValidateData(response.data.data)
            })
    }, [])

    useEffect(() => {
        if (validateData.fieldingRequestId) {
            dispatch(FieldingMarkerDefaultTimeOffsetGetFieldingId({ token: validateData.userToken, fieldingRequestId: validateData.fieldingRequestId }))
                .unwrap()
                .then((response: any) => {
                    setTimeOffsetSelected(response.data.data.length > 0 ? response.data.data[0] : new Date().getTimezoneOffset())
                })
        }
    }, [validateData.fieldingRequestId])

    useEffect(() => {
        if (!isUploading && filesQueue.length > 0) {
            setIsUploading(true)
            uploading()
        }
    }, [filesQueue])

    const onChangeImage = async (filesLocal: any) => {
        if (filesLocal.length > 0) {
            setFilesQueue([
                ...filesQueue,
                ...Array.from(filesLocal).map((file: any) => {
                    return {
                        name: file.name,
                        uploadProgress: 0,
                        file: file.file
                    }
                })
            ])
        }
    }

    const uploading = async () => {
        let filesLocal: any = filesQueue
        let filesToUpload: any = filesLocal.filter((file: any) => file.uploadProgress === 0)
        if (filesToUpload.length > 0) {
            for (const i in filesToUpload) {
                try {
                    let data = new FormData();
                    data.append("projectId", "")
                    data.append("fieldingRequestId", validateData?.fieldingRequestId)
                    data.append("token", validateData?.userToken)
                    data.append("timeOffset", String(timeOffsetSelected || localOffset))
                    data.append("file", filesToUpload[i].file)
                    await axios.post(`${baseUrl}/newapi/FieldingRequestMarker/TimerUpload`, data, {
                        onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
                            filesLocal = filesLocal.map((f: any) => {
                                if (f.name === filesToUpload[i].name) {
                                    return {
                                        ...f,
                                        uploadProgress: percentCompleted
                                    }
                                }
                                return f
                            })
                            setFilesQueue(filesLocal)
                        }
                    });
                } catch (error: any) {
                    console.log(error)
                    notificationError(error.response)
                }
            }
        }
        setIsUploading(false)
        notificationSuccess("Uploaded successfully")
    }

    const localOffset = new Date().getTimezoneOffset()

    const timeOffsetOptions = [
        {
            name: "-",
            value: [240, 300, 360, 420, 480, 600].includes(localOffset) ? 0 : localOffset
        },
        {
            name: "EDT/Eastern Time",
            value: moment().tz("America/New_York").isDST() ? 240 : 300
        },
        {
            name: "MDT/Mountain Time",
            value: moment().tz("America/Denver").isDST() ? 360 : 420
        },
        {
            name: "PDT/Pacific Time",
            value: moment().tz("America/Los_Angeles").isDST() ? 420 : 480
        },
        {
            name: "CDT/Central Time",
            value: moment().tz("America/Chicago").isDST() ? 300 : 360
        },
        {
            name: "HST/Hawaii Time",
            value: 600
        }
    ]

    return (
        <Box sx={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img src={bg_auth} alt="bg" className="bg" />
            <Box sx={{ position: "relative", maxHeight: "50vh", width: "30vw", minWidth: "500px", bgcolor: "#063058", borderRadius: 2, paddingTop: 15 }}>
                <Box sx={{ position: "absolute", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <img src={uploadLogo} alt="ul" style={{ borderRadius: "50%" }} />
                </Box>
                <Box sx={{ bgcolor: "white", padding: 4, paddingTop: 15, borderRadius: 2 }}>
                    <Typography variant="h4" align="center">
                        Upload File
                    </Typography>
                    <br />
                    <br />
                    <Autocomplete
                        fullWidth
                        options={timeOffsetOptions}
                        getOptionLabel={(time) => time.name}
                        value={timeOffsetOptions.filter(time => time.value === timeOffsetSelected)[0] || null}
                        onChange={(e, value) => setTimeOffsetSelected(value?.value || 0)}
                        renderInput={(params) => <TextField {...params} label="Time Zone" />}
                    />
                    <br />
                    <Dropzone onChange={onChangeImage} header={false} accept="image/*" disabled={isUploading} />
                    {filesQueue.map((file: any) => (
                        <Card key={file.name} variant="outlined" sx={{ marginTop: 1 }}>
                            <CardContent>
                                <Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        {file.name}
                                        <IconButton>
                                            {file.uploadProgress < 100
                                                ? (
                                                    <Cancel />
                                                )
                                                : (
                                                    <Check color="success" />
                                                )
                                            }
                                        </IconButton>
                                    </Box>
                                    <LinearProgress variant="determinate" value={file.uploadProgress} />
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}