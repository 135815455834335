export default function progressColor(value: number) {
    if(value >= 0 && value <= 20) {
        return "red";
    }
    else if(value >= 21 && value <= 50) {
        return "orange";
    }
    else if(value >= 51 && value <= 80) {
        return "blue";
    }
    else if(value >= 81 && value <= 100) {
        return "green";
    }
}