import { Add, ArrowBack, ArrowBackIos, ArrowForwardIos, Attachment, Close, Delete, Edit, Note, NoteAdd } from "@mui/icons-material"
import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, IconButton, Modal, TextField, Tooltip, Typography } from "@mui/material"
import ProjectFormMap from "./ProjectFormMap"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import notificationSuccess from "../../../shared/notificationSuccess"
import { userInfo } from "../../../shared/utils"
import { useNavigate, useSearchParams } from "react-router-dom"
import randomColor from "randomcolor"
import moment from "moment"
import CustomModal from "../../../shared/customModal/CustomModal"
import axios from "axios"
import { baseUrl, colors } from "../../../state"
import { InitialProjectCreate, InterfaceProjectCreate, InterfaceProjectDetail, InterfaceProjectFileLocalLIst } from "../project.interface"
import { projectCreate, projectEdit, projectFieldingAdd, projectGetDetail, projectGetEnum, projectLinkAppend } from "../project.api"
import { fieldingNoteCreate, fieldingTypeGet } from "../../fielding/fielding.api"
import { InitialFieldingMarker, InitialFieldingNoteCreate, InterfaceFieldingMarker, InterfaceFieldingNoteCreate, InterfaceMarkerLine, InterfaceMarkerPoint } from "../../fielding/fielding.interface"
import ProjectFormFilesFielding from "./ProjectFormFilesFielding"
import { getAccountFielder } from "../../account/account.api"
import ModalAddMarker from "./modals/ModalAddMarker"
import ModalAddNotes from "./modals/ModalAddNotes"
import ProjectCustomerCode from "./shared/ProjectCustomerCode"
import Swal from "sweetalert2"
import ButtonOutlined from "../../../shared/ButtonOutlined"
import useAddress from "../../../shared/useAddress"
import hot from "../../../assets/images/hot.png"

export default function ProjectForm(props: { type: "create" | "detail" | "edit" }) {
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const addressHook = useAddress()

    const [typeLocal, setTypeLocal] = useState<"create" | "detail" | "edit">(props.type ? props.type : "create")
    const [id, setId] = useState<string>("")
    const [values, setValues] = useState<InterfaceProjectCreate>(InitialProjectCreate)
    const [fieldingRequestIndex, setFieldingRequestIndex] = useState<number>(0)
    const [linksLocal, setLinksLocal] = useState<Array<string>>([])
    const [filesLocal, setFilessLocal] = useState<InterfaceProjectFileLocalLIst>([])
    const [filesUpload, setFilesUpload] = useState<{ isUploading: boolean; countUploaded: number; percentage: number }>({ isUploading: false, countUploaded: 0, percentage: 0 })
    const [center, setCenter] = useState<InterfaceMarkerPoint | null>(null)
    const [isAddFielding, setIsAddFielding] = useState(false)
    const [modalFilesFielding, setModalFilesFielding] = useState(false)
    const [filesFieldingLocal, setFilessFieldingLocal] = useState<Array<InterfaceProjectFileLocalLIst>>([[]])
    const [modalAddNotes, setModalAddNotes] = useState(false)
    const [notesSubmitOnModal, setNotesSubmitOnModal] = useState(false)
    const [notes, setNotes] = useState<Array<InterfaceFieldingNoteCreate>>([InitialFieldingNoteCreate])
    const [markerTemp, setMarkerTemp] = useState<InterfaceFieldingMarker | null>(null)

    const paramsId = searchParams.get("id")
    useEffect(() => {
        dispatch(getAccountFielder())
    }, [dispatch])

    useEffect(() => {
        dispatch(projectGetEnum())
        dispatch(fieldingTypeGet())
        setTypeLocal(props.type)
    }, [dispatch, paramsId, props.type])

    useEffect(() => {
        if (typeLocal === "create") {
            setValues({
                ...InitialProjectCreate,
                token: userInfo().token,
                companyId: userInfo().user.companyId,
                projectFieldingRequests: [
                    {
                        colorCode: colors[0],
                        dueDateString: "",
                        fielderIds: [],
                        fieldingRequestMarkers: [],
                        customTypeId: null,
                        fieldingRequestName: "Field Request 1"
                    }
                ]
            })
        }
        else if (typeLocal === "detail") {
            initialDetail()
        }
        else if (typeLocal === "edit") {
        }
        else {
            setValues(InitialProjectCreate)
        }
    }, [typeLocal])

    useEffect(() => {
        if (props.type === "detail" && typeLocal === "detail" && (linksLocal.length > 0 || filesLocal.length > 0)) {
            setTypeLocal("edit")
        }
    }, [linksLocal, filesLocal])

    useEffect(() => {
        if (typeLocal !== "create" && typeLocal) {
            if (values.projectFieldingRequests.length > 0) {
                const center = getCenterFieldingRequest(fieldingRequestIndex)
                if (center) {
                    setCenter({ latitude: center.latitude, longitude: center.longitude })
                    return
                }
            }
        }
    }, [fieldingRequestIndex, values])

    useEffect(() => {
        if (typeLocal === "create")
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    setCenter({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    })
                })
            }
            else {
                console.log("Geolocation is not available in your browser.")
            }
    }, [])

    const initialDetail = () => {
        dispatch(projectGetDetail(paramsId || ""))
            .unwrap()
            .then((response: any) => {
                let data: InterfaceProjectDetail = response.data.data
                setId(data.id)
                setValues({
                    ...values,
                    projectNumber: data.projectNumber,
                    description: data.description,
                    fpAddress: data.fpAddress,
                    fpCity: data.fpCity,
                    customerCodeId: data.customerCodeId,
                    isHotJob: data.isHotJob,
                    projectFieldingRequests: data.projectFieldingRequests.map((fieldingRequest) => {
                        return {
                            ...fieldingRequest,
                            fieldingRequestName: fieldingRequest.fieldingRequest,
                            customTypeId: fieldingRequest.customTypeId,
                            dueDateString: moment(fieldingRequest.dueDateString).format("YYYY-MM-DD"),
                            fieldingRequestMarkers: fieldingRequest.fieldingRequestMarkers.map((marker) => {
                                return {
                                    ...marker,
                                    lineData: JSON.parse(marker.lineData),
                                    shapeData: JSON.parse(marker.shapeData)
                                }
                            })
                        }
                    })
                })
                setNotes(
                    data.projectFieldingRequests.map((fieldingRequest) => {
                        return {
                            ...InitialFieldingNoteCreate,
                            fielderIdList:
                                fieldingRequest.fieldingRequestNotes.length > 0
                                    ? fieldingRequest.fieldingRequestNotes[0].fielderIds
                                    : [],
                            content:
                                fieldingRequest.fieldingRequestNotes.length > 0
                                    ? fieldingRequest.fieldingRequestNotes[0].content
                                    : ""
                        }
                    })
                )
            })
    }

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleAddFielding = () => {
        setValues({
            ...values,
            projectFieldingRequests: [
                ...values.projectFieldingRequests,
                {
                    colorCode:
                        values.projectFieldingRequests.length > colors.length
                            ? randomColor()
                            : colors[values.projectFieldingRequests.length + 1],
                    customTypeId: null,
                    dueDateString: "",
                    fielderIds: [],
                    fieldingRequestMarkers: [],
                    fieldingRequestName: `Field Request ${values.projectFieldingRequests.length + 1}`
                }
            ]
        })
        setFieldingRequestIndex(values.projectFieldingRequests.length)
        setFilessFieldingLocal([...filesFieldingLocal, []])
        setNotes([...notes, InitialFieldingNoteCreate])
    }

    const handleCancelAddFielding = () => {
        setIsAddFielding(false)
        setValues({
            ...values,
            projectFieldingRequests: values.projectFieldingRequests.filter((f) => Boolean(f.fieldingRequestId))
        })
        setFieldingRequestIndex(0)
    }

    const handleSaveFieldingRequest = async () => {
        if (values.fpAddress === "") {
            const address = await getAddressForProject()
            await dispatch(
                projectEdit({
                    token: userInfo().token,
                    id: id,
                    projectNumber: values.projectNumber,
                    description: values.description,
                    fpAddress: address.address === "-" ? "" : address.address,
                    fpCity: address.city === "-" ? "" : address.city,
                    customerCodeId: values.customerCodeId,
                    isHotJob: values.isHotJob
                })
            )
        }
        await dispatch(projectFieldingAdd({
            projectId: id,
            token: userInfo().token,
            colorCode: values.projectFieldingRequests[fieldingRequestIndex].colorCode,
            customTypeId: values.projectFieldingRequests[fieldingRequestIndex].customTypeId,
            dueDateString: values.projectFieldingRequests[fieldingRequestIndex].dueDateString
                ? moment(values.projectFieldingRequests[fieldingRequestIndex].dueDateString).format("MM/DD/YYYY")
                : null,
            fielderIds: values.projectFieldingRequests[fieldingRequestIndex].fielderIds,
            fieldingRequestName: values.projectFieldingRequests[fieldingRequestIndex]?.fieldingRequestName,
            fieldingRequestMarkers: values.projectFieldingRequests[fieldingRequestIndex].fieldingRequestMarkers.map(
                (marker) => {
                    return {
                        ...marker,
                        lineData: JSON.stringify(marker.lineData),
                        shapeData: JSON.stringify(marker.shapeData),
                        timeOffset: moment().utcOffset() * -1
                    }
                }
            )
        })
        )
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    setFieldingRequestIndex(0)
                    setIsAddFielding(false)
                    initialDetail()
                }
            })
    }

    const handleChangeFieldingRequest = () => {
        if (markerTemp !== null) {
            let newValue = {
                ...values,
                projectFieldingRequests: values.projectFieldingRequests.map((fieldingRequestOriginal, i) => {
                    if (fieldingRequestIndex === i) {
                        return {
                            ...fieldingRequestOriginal,
                            fieldingRequestMarkers: [
                                ...fieldingRequestOriginal.fieldingRequestMarkers,
                                markerTemp
                            ]
                        }
                    }
                    return fieldingRequestOriginal
                })
            }
            setValues(newValue)
            setMarkerTemp(null)
        }
    }

    const handleAddMarker = (marker: {
        lineData: Array<InterfaceMarkerLine>
        shapeData: Array<InterfaceMarkerLine>
        dotLongLat: string
    }) => {
        const markerList = values.projectFieldingRequests[fieldingRequestIndex].fieldingRequestMarkers
        setMarkerTemp({
            ...InitialFieldingMarker,
            ...marker,
            id: String(parseInt(markerList[markerList.length - 1]?.id || "0") + 1)
        })
    }

    const handleDeletemarker = (id: string) => {
        setValues({
            ...values,
            projectFieldingRequests: values.projectFieldingRequests.map((fieldingRequestOriginal, i) => {
                if (fieldingRequestIndex === i) {
                    return {
                        ...fieldingRequestOriginal,
                        fieldingRequestMarkers: fieldingRequestOriginal.fieldingRequestMarkers.filter((marker) => marker.id !== id)
                    }
                }
                return fieldingRequestOriginal
            })
        })
    }

    const handleChangeDueDate = (value: string) => {
        setValues({
            ...values,
            projectFieldingRequests: values.projectFieldingRequests.map((fieldingRequestOriginal, i) => {
                if (fieldingRequestIndex === i) {
                    return {
                        ...fieldingRequestOriginal,
                        dueDateString: value
                    }
                }
                return fieldingRequestOriginal
            })
        })
    }

    const getAddressForProject = async () => {
        const coordinateList: Array<InterfaceMarkerPoint> = []
        for (const i in values.projectFieldingRequests) {
            if (Object.prototype.hasOwnProperty.call(values.projectFieldingRequests, i)) {
                const fieldingRequest = values.projectFieldingRequests[i]
                for (const j in fieldingRequest.fieldingRequestMarkers) {
                    if (Object.prototype.hasOwnProperty.call(fieldingRequest.fieldingRequestMarkers, j)) {
                        const marker = fieldingRequest.fieldingRequestMarkers[j]
                        if (marker.dotLongLat !== "") {
                            coordinateList.push({
                                latitude: parseFloat(marker.dotLongLat.split(",")[1]),
                                longitude: parseFloat(marker.dotLongLat.split(",")[0])
                            })
                        }
                        else if (marker.lineData.length > 0) {
                            coordinateList.push({
                                latitude: marker.lineData[0][0].latitude,
                                longitude: marker.lineData[0][0].longitude
                            })
                        }
                        else if (marker.shapeData.length > 0) {
                            coordinateList.push({
                                latitude: marker.shapeData[0][0].latitude,
                                longitude: marker.shapeData[0][0].longitude
                            })
                        }
                    }
                }
            }
        }
        let address: { address: string; city: string } = { address: "-", city: "-" }
        for (const i in coordinateList) {
            if (Object.prototype.hasOwnProperty.call(coordinateList, i)) {
                const coordinate = coordinateList[i]
                address = await addressHook.getAddress(coordinate.latitude, coordinate.longitude)
            }
        }
        return address
    }

    const handleSubmit = async () => {
        if (values.customerCodeId === "") {
            Swal.fire({
                icon: "error",
                title: "Please choose/enter project customer code"
            })
            return
        }
        if (typeLocal === "create") {
            const address = await getAddressForProject()
            let form = {
                ...values,
                fpAddress: address.address === "-" ? "" : address.address,
                fpCity: address.city === "-" ? "" : address.city
            }
            let responseProject: any = {}
            try {
                responseProject = await dispatch(projectCreate(form)).unwrap()
            } catch (error) {
                console.log(error)
                return null
            }
            let uploadingCount = 0
            for (const i in filesLocal) {
                let data = new FormData()
                data.append("projectId", responseProject.data.data.newProject.id)
                data.append("token", userInfo().token)
                data.append("file", filesLocal[i].file)
                try {
                    uploadingCount = uploadingCount + 1
                    await axios.post(`${baseUrl}/newapi/Project/FPUploadSupportingAttachments`, data, {
                        onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
                            setFilesUpload({
                                isUploading: true,
                                countUploaded: uploadingCount,
                                percentage: percentCompleted
                            })
                        }
                    })
                } catch (error: any) {
                    console.log(error)
                }
            }
            let fieldingRequestList = responseProject.data.data.newFieldingRequests
            for (const i in filesFieldingLocal) {
                for (const j in filesFieldingLocal[i]) {
                    let data = new FormData()
                    data.append("fieldingRequestId", fieldingRequestList[i].id)
                    data.append("token", userInfo().token)
                    data.append("file", filesFieldingLocal[i][j].file)
                    try {
                        uploadingCount = uploadingCount + 1
                        await axios.post(`${baseUrl}/newapi/FieldingRequest/UploadAttachments`, data, {
                            onUploadProgress: (progressEvent) => {
                                var percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) / (progressEvent.total || 1)
                                )
                                setFilesUpload({
                                    isUploading: true,
                                    countUploaded: uploadingCount,
                                    percentage: percentCompleted
                                })
                            }
                        })
                    } catch (error: any) {
                        console.log(error)
                        return null
                    }
                }
            }
            for (const i in notes) {
                try {
                    if (notes[0].content !== "") {
                        await dispatch(
                            fieldingNoteCreate({
                                ...notes[i],
                                fieldingRequestId: fieldingRequestList[i].id,
                                token: userInfo().token
                            })
                        )
                    }
                } catch (error) {
                    console.log(error)
                    return null
                }
            }
            setFilesUpload({ isUploading: false, countUploaded: 0, percentage: 0 })
            setFilessLocal([])
            await dispatch(projectLinkAppend({ projectId: responseProject.data.data.newProject.id, links: linksLocal })).unwrap()
            setLinksLocal([])
            notificationSuccess("")
                .then(() => {
                    navigate(-1)
                })
        } else if (typeLocal === "edit") {
            dispatch(
                projectEdit({
                    token: userInfo().token,
                    id: id,
                    projectNumber: values.projectNumber,
                    description: values.description,
                    fpAddress: values.fpAddress,
                    fpCity: values.fpCity,
                    customerCodeId: values.customerCodeId,
                    isHotJob: values.isHotJob
                })
            )
                .unwrap()
                .then(async (response: any) => {
                    if (response.status === 200) {
                        await dispatch(projectLinkAppend({ projectId: id, links: linksLocal }))
                            .unwrap()
                            .then(() => {
                                setLinksLocal([])
                            })
                        for (const i in filesLocal) {
                            let data = new FormData()
                            data.append("projectId", id)
                            data.append("token", userInfo().token)
                            data.append("file", filesLocal[i].file)
                            try {
                                await axios.post(`${baseUrl}/newapi/Project/FPUploadSupportingAttachments`, data, {
                                    onUploadProgress: (progressEvent) => {
                                        var percentCompleted = Math.round(
                                            (progressEvent.loaded * 100) / (progressEvent.total || 1)
                                        )
                                        console.log(percentCompleted)
                                        setFilesUpload({
                                            isUploading: true,
                                            countUploaded: parseInt(i) + 1,
                                            percentage: percentCompleted
                                        })
                                    }
                                })
                            } catch (error: any) {
                                console.log(error)
                            }
                        }
                        await setFilesUpload({ isUploading: false, countUploaded: 0, percentage: 0 })
                        await setFilessLocal([])
                        await notificationSuccess("project successfully edited").then(() => {
                            setTypeLocal("detail")
                            initialDetail()
                        })
                    }
                })
        }
    }

    const handleSubmitProject = () => {
        Swal.fire({
            icon: "info",
            title: `Do you want to save project only without field request?\n You can add field request later.`,
            showConfirmButton: true,
            confirmButtonText: "Save project only",
            showCancelButton: true,
            cancelButtonText: "Add fielding request"
        }).then(async (response) => {
            if (response.isConfirmed) {
                let responseProject: any = {}
                try {
                    responseProject = await dispatch(
                        projectCreate({
                            ...values,
                            projectFieldingRequests: []
                        })
                    ).unwrap()
                } catch (error) {
                    console.log(error)
                    return null
                }
                let uploadingCount = 0
                for (const i in filesLocal) {
                    let data = new FormData()
                    data.append("projectId", responseProject.data.data.newProject.id)
                    data.append("token", userInfo().token)
                    data.append("file", filesLocal[i].file)
                    try {
                        uploadingCount = uploadingCount + 1
                        await axios.post(`${baseUrl}/newapi/Project/FPUploadSupportingAttachments`, data, {
                            onUploadProgress: (progressEvent) => {
                                var percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) / (progressEvent.total || 1)
                                )
                                setFilesUpload({
                                    isUploading: true,
                                    countUploaded: uploadingCount,
                                    percentage: percentCompleted
                                })
                            }
                        })
                    } catch (error: any) {
                        console.log(error)
                    }
                }
                setFilesUpload({ isUploading: false, countUploaded: 0, percentage: 0 })
                setFilessLocal([])
                await dispatch(
                    projectLinkAppend({ projectId: responseProject.data.data.newProject.id, links: linksLocal })
                ).unwrap()
                setLinksLocal([])
                notificationSuccess("").then(() => {
                    navigate(-1)
                })
            }
        })
    }

    const fieldProps = {
        onChange: handleChangeValues,
        InputProps: { readOnly: typeLocal === "detail" }
    }

    const getCenterFieldingRequest = (index: number) => {
        if (
            values.projectFieldingRequests.length > 0 &&
            values.projectFieldingRequests[index].fieldingRequestMarkers.length > 0
        ) {
            let dotLongLat = values.projectFieldingRequests[index].fieldingRequestMarkers[0].dotLongLat
            let lineData = values.projectFieldingRequests[index].fieldingRequestMarkers[0].lineData
            let shapeData = values.projectFieldingRequests[index].fieldingRequestMarkers[0].shapeData
            if (dotLongLat !== "") {
                return {
                    latitude: parseFloat(dotLongLat.split(",")[1]),
                    longitude: parseFloat(dotLongLat.split(",")[0])
                }
            }
            else if (lineData.length > 0) {
                return { latitude: lineData[0][0].latitude, longitude: lineData[0][0].longitude }
            }
            else if (shapeData.length > 0) {
                return { latitude: shapeData[0][0].latitude, longitude: shapeData[0][0].longitude }
            }
        }
        return null
    }

    const handleDeleteFieldingRequest = () => {
        setValues({
            ...values,
            projectFieldingRequests: values.projectFieldingRequests.filter(
                (fielding, index) => index !== fieldingRequestIndex
            )
        })
        if (fieldingRequestIndex > 0) {
            setFieldingRequestIndex(fieldingRequestIndex - 1)
        }
        else {
            setFieldingRequestIndex(0)
        }
    }

    const handleUndoMarker = () => {
        setValues({
            ...values,
            projectFieldingRequests: values.projectFieldingRequests.map((fieldingRequestOriginal, i, selfFieldingList) => {
                if (i === fieldingRequestIndex) {
                    const markerLength = fieldingRequestOriginal.fieldingRequestMarkers.length
                    if (markerLength > 0) {
                        const lastMarkerIndex = markerLength - 1
                        const lastMarker = fieldingRequestOriginal.fieldingRequestMarkers[lastMarkerIndex]
                        const isLastMarkerPoint = lastMarker.dotLongLat !== ""
                        const isLastMarkerLine = lastMarker.lineData.length > 0
                        const isLastMarkerShape = lastMarker.shapeData.length > 0
                        const isRemoveMarker = isLastMarkerPoint || (isLastMarkerLine && lastMarker.lineData[0].length === 1) || (isLastMarkerShape && lastMarker.shapeData[0].length === 1)
                        if (isRemoveMarker) {
                            return {
                                ...fieldingRequestOriginal,
                                fieldingRequestMarkers: fieldingRequestOriginal.fieldingRequestMarkers.filter((_, j) => j !== lastMarkerIndex)
                            }
                        }
                        else if (isLastMarkerLine) {
                            return {
                                ...fieldingRequestOriginal,
                                fieldingRequestMarkers: fieldingRequestOriginal.fieldingRequestMarkers.map((marker, j) => {
                                    if (j === lastMarkerIndex) {
                                        return {
                                            ...marker,
                                            lineData: [marker.lineData[0].filter((_, k, lineData) => k !== lineData.length - 1)]
                                        }
                                    }
                                    return marker
                                })
                            }
                        }
                        else if (isLastMarkerShape) {
                            return {
                                ...fieldingRequestOriginal,
                                fieldingRequestMarkers: fieldingRequestOriginal.fieldingRequestMarkers.map((marker, j) => {
                                    if (j === lastMarkerIndex) {
                                        return {
                                            ...marker,
                                            shapeData: [marker.shapeData[0].filter((_, k, shapeData) => k !== shapeData.length - 1)]
                                        }
                                    }
                                    return marker
                                })
                            }
                        }
                    }
                }
                return fieldingRequestOriginal
            })
        })
    }

    if (
        typeLocal === "detail" &&
        isAddFielding === false &&
        values.projectFieldingRequests.length > 0 &&
        center === null
    ) {
        return <></>
    }

    return (
        <div className="project-form">
            <Modal open={filesUpload.isUploading}>
                <Box height={"100vh"} width={"100vw"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Card>
                        <CardContent>
                            Uploading {filesUpload.countUploaded} of{" "}
                            {filesLocal.length + filesFieldingLocal.reduce((total, data) => total + data.length, 0)},{" "}
                            {filesUpload.percentage}%
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
            <CustomModal
                open={modalFilesFielding}
                onClose={() => setModalFilesFielding(false)}
                title="Field Request Attachment"
                size="sm"
                component={
                    <ProjectFormFilesFielding
                        index={fieldingRequestIndex}
                        files={filesFieldingLocal}
                        setFiles={setFilessFieldingLocal}
                    />
                }
            />
            <CustomModal
                open={modalAddNotes}
                onClose={() => {
                    setModalAddNotes(false)
                    notesSubmitOnModal &&
                        setNotes(
                            notes.map((note, i) => {
                                if (i === fieldingRequestIndex) {
                                    return InitialFieldingNoteCreate
                                }
                                else {
                                    return note
                                }
                            })
                        )
                }}
                title="Add Notes"
                size="lg"
                component={
                    <ModalAddNotes
                        value={notes[fieldingRequestIndex]}
                        setValue={(value) =>
                            setNotes(
                                notes.map((note, i) => {
                                    if (i === fieldingRequestIndex) {
                                        return value
                                    }
                                    else {
                                        return note
                                    }
                                })
                            )
                        }
                        onSubmit={() => {
                            setModalAddNotes(false)
                            setNotesSubmitOnModal(false)
                        }}
                        readOnly={!notesSubmitOnModal && typeLocal === "detail" && !isAddFielding}
                        submitOnSave={notesSubmitOnModal}
                        fieldingRequestId={
                            values.projectFieldingRequests.length > 0
                                ? values.projectFieldingRequests[fieldingRequestIndex]?.fieldingRequestId
                                : undefined
                        }
                    />
                }
            />
            <CustomModal
                open={Boolean(markerTemp)}
                onClose={() => setMarkerTemp(null)}
                title="Add Marker"
                component={
                    <ModalAddMarker
                        value={markerTemp || InitialFieldingMarker}
                        setValue={setMarkerTemp}
                        onSubmit={handleChangeFieldingRequest}
                    />
                }
            />
            <div className="page-header">
                <div className="nav-row">
                    <span>
                        Project {">"} {values.projectNumber}
                    </span>
                </div>
                <div style={{ width: "100%", display: "flex" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={1}>
                            <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                variant="outlined"
                                {...fieldProps}
                                value={values.projectNumber}
                                name="projectNumber"
                                label="Project Number"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ProjectCustomerCode
                                readOnly={typeLocal === "detail"}
                                value={values.customerCodeId || ""}
                                onChange={(value) => setValues({ ...values, customerCodeId: value })}
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.isHotJob}
                                        onChange={(e) => typeLocal !== "detail" && setValues({ ...values, isHotJob: e.target.checked })}
                                    />
                                }
                                label={
                                    <Box display={"flex"} alignItems={"center"} gap={1}>
                                        <Typography whiteSpace={"nowrap"} color={typeLocal === "detail" ? "grey" : undefined}>Hot Job</Typography>
                                        <img src={hot} alt="hot" style={{ width: "20px", filter: typeLocal === "detail" ? "grayscale(100%)" : undefined }} />
                                    </Box>
                                }
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3}
                            display={"flex"}
                            flexDirection={"row"}
                            justifyContent={"flex-end"}
                            padding={1}
                        >
                            {typeLocal === "detail" ? (
                                <Fragment>
                                    <Box width={"10px"}></Box>
                                    <ButtonOutlined
                                        startIcon={<Edit />}
                                        color="success"
                                        onClick={() => setTypeLocal("edit")}
                                    >
                                        Edit Project
                                    </ButtonOutlined>
                                </Fragment>
                            ) : typeLocal === "edit" ? (
                                <Fragment>
                                    <Box width={"10px"}></Box>
                                    <ButtonOutlined
                                        startIcon={<Close />}
                                        color="inherit"
                                        onClick={() => setTypeLocal("detail")}
                                    >
                                        Cancel
                                    </ButtonOutlined>
                                </Fragment>
                            ) : null}
                            {typeLocal === "create" && (
                                <Fragment>
                                    <Box width={"10px"}></Box>
                                    <Button variant="contained" onClick={handleSubmitProject}>
                                        Save Project
                                    </Button>
                                </Fragment>
                            )}
                            {typeLocal === "edit" && (
                                <Fragment>
                                    <Box width={"10px"}></Box>
                                    <Button variant="contained" onClick={handleSubmit}>
                                        Save
                                    </Button>
                                </Fragment>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="map">
                <ProjectFormMap
                    fieldingRequestList={values.projectFieldingRequests.map((fieldingRequest) => {
                        return {
                            id: fieldingRequest?.fieldingRequestId || "",
                            name: fieldingRequest?.fieldingRequestName,
                            color: fieldingRequest.colorCode,
                            markers: fieldingRequest.fieldingRequestMarkers
                        }
                    })}
                    onAddMarker={handleAddMarker}
                    onDeleteMarker={handleDeletemarker}
                    onClickGraphic={(props) => {
                        let cek = values.projectFieldingRequests.map((fieldingRequest, i) =>
                            fieldingRequest?.fieldingRequestId === props.fieldingRequestId ? 1 : 0
                        )
                        let index = cek.indexOf(1) < 0 ? 0 : cek.indexOf(1)
                        if (typeLocal === "detail") {
                            navigate(`/project/detail/fieldrequest?projectid=${id}&index=${index}`)
                        }
                    }}
                    editable={!Boolean(values.projectFieldingRequests[fieldingRequestIndex]?.fieldingRequestId)}
                    center={center}
                    onUndoMarker={handleUndoMarker}
                    fieldingIndexSelected={fieldingRequestIndex}
                />
                {values.projectFieldingRequests.length > 0 ? (
                    <Grid container spacing={1} sx={{ padding: 1 }}>
                        <Grid item xs={10} md={5} display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                            <div className="selector">
                                <IconButton
                                    color="primary"
                                    disabled={fieldingRequestIndex <= 0}
                                    onClick={() => setFieldingRequestIndex(fieldingRequestIndex - 1)}
                                >
                                    <ArrowBackIos />
                                </IconButton>
                                <Typography variant="h6">
                                    {values.projectFieldingRequests.length > 0
                                        ? values.projectFieldingRequests[fieldingRequestIndex]?.fieldingRequestName
                                        : ""}
                                </Typography>
                                <IconButton
                                    color="primary"
                                    disabled={fieldingRequestIndex + 1 >= values.projectFieldingRequests.length}
                                    onClick={() => setFieldingRequestIndex(fieldingRequestIndex + 1)}
                                >
                                    <ArrowForwardIos />
                                </IconButton>
                            </div>
                        </Grid>
                        {(typeLocal === "create" || isAddFielding) && (
                            <Grid item xs={2} md={1}>
                                <Tooltip title="Add document">
                                    <IconButton onClick={() => setModalFilesFielding(true)}>
                                        <Attachment />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                        {(typeLocal === "create" || isAddFielding) && (
                            <Fragment>
                                <Grid item xs={10} md={isAddFielding ? 3 : 4}>
                                    <Box display={"flex"} alignItems={"center"} gap={1}>
                                        {values.projectFieldingRequests.length > 0 && (
                                            <TextField
                                                fullWidth={false}
                                                value={
                                                    values.projectFieldingRequests[fieldingRequestIndex].dueDateString
                                                }
                                                onChange={(e) => handleChangeDueDate(e.target.value)}
                                                type="date"
                                                name="dueDateString"
                                                label="Due Date"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                        <ButtonOutlined
                                            startIcon={<NoteAdd />}
                                            onClick={() => setModalAddNotes(true)}
                                        >
                                            Add Notes
                                        </ButtonOutlined>
                                    </Box>
                                </Grid>
                                {!isAddFielding && (
                                    <Grid item xs={2} md={1} display={"flex"} justifyContent={"flex-end"}>
                                        <IconButton
                                            color="error"
                                            sx={{ marginLeft: 1 }}
                                            disabled={values.projectFieldingRequests.length === 1}
                                            onClick={handleDeleteFieldingRequest}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Fragment>
                        )}
                        {typeLocal === "detail" && (
                            <Grid item xs={12} md={3} display={"flex"} flexDirection={"row"} justifyContent={"center"}>
                                {isAddFielding ? (
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={9}>
                                            <Button fullWidth variant="contained" onClick={handleSaveFieldingRequest}>
                                                Save New Field Request
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <ButtonOutlined fullWidth onClick={handleCancelAddFielding}>
                                                Cancel
                                            </ButtonOutlined>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <ButtonOutlined
                                        fullWidth
                                        startIcon={<Add />}
                                        onClick={() => {
                                            setIsAddFielding(true)
                                            handleAddFielding()
                                        }}
                                    >
                                        Add New Field Request
                                    </ButtonOutlined>
                                )}
                            </Grid>
                        )}
                        {typeLocal === "detail" && !isAddFielding && (
                            <Grid
                                item
                                md={4}
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"flex-end"}
                                gap={1}
                                sx={{
                                    minWidth: "200px",
                                    minHeight: "40px"
                                }}
                            >
                                {values.projectFieldingRequests.length > 0 && (
                                    <TextField
                                        fullWidth={false}
                                        value={values.projectFieldingRequests[fieldingRequestIndex].dueDateString}
                                        onChange={(e) => handleChangeDueDate(e.target.value)}
                                        type="date"
                                        name="dueDateString"
                                        label="Due Date"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                                {notes[fieldingRequestIndex].content === "" &&
                                    notes[fieldingRequestIndex].fielderIdList.length === 0 ? (
                                    <ButtonOutlined
                                        startIcon={<NoteAdd />}
                                        onClick={() => {
                                            setModalAddNotes(true)
                                            setNotesSubmitOnModal(true)
                                        }}
                                    >
                                        Add Notes
                                    </ButtonOutlined>
                                ) : (
                                    <ButtonOutlined
                                        startIcon={<Note />}
                                        onClick={() => {
                                            setModalAddNotes(true)
                                            setNotesSubmitOnModal(false)
                                        }}
                                    >
                                        View Notes
                                    </ButtonOutlined>
                                )}
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Grid container spacing={1} sx={{ padding: 1 }}>
                        <Grid item md={5}></Grid>
                        <Grid item md={2}>
                            <ButtonOutlined
                                fullWidth
                                startIcon={<Add />}
                                onClick={() => {
                                    setIsAddFielding(true)
                                    handleAddFielding()
                                }}
                            >
                                Add New Field Request
                            </ButtonOutlined>
                        </Grid>
                        <Grid item md={5}></Grid>
                    </Grid>
                )}
            </div>
            {typeLocal === "create" && (
                <div className="footer">
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <ButtonOutlined fullWidth startIcon={<Add />} onClick={handleAddFielding}>
                                Add New Field Request
                            </ButtonOutlined>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button fullWidth variant="contained" onClick={handleSubmit}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    )
}
