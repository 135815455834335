import { Box } from "@mui/material";
import { Dropzone } from "@dropzone-ui/react";
import { InterfaceProjectFileLocalLIst } from "../project.interface";

export default function ProjectFormFilesFielding(props: { index: number, files: Array<InterfaceProjectFileLocalLIst>, setFiles: any }) {

    const onChangeImage = async (filesLocal: any) => {
        if (filesLocal.length > 0) {
            props.setFiles(props.files.map((files: any, i: number) => {
                if (i === props.index) {
                    return [
                        ...props.files[props.index],
                        ...Array.from(filesLocal).map((file: any) => {
                            return {
                                name: file.name,
                                uploadProgress: 0,
                                file: file.file
                            }
                        })
                    ]
                }
                return files
            }))
        }
    }

    return (
        <Box>
            <Dropzone onChange={onChangeImage} header={false} />
            <br />
            {props.files[props.index].map(file => (
                <Box key={file.file.name} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    {file.file.name}
                </Box>
            ))}
        </Box>
    )
}