import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../state';
import moment from 'moment';
import { userInfo } from '../../shared/utils';
import {
  InterfaceProjectCreate,
  InterfaceProjectEdit,
  InterfaceProjectFieldingAdd,
  InterfaceProjectGetParams,
  InterfaceProjectLinkAppend,
} from './project.interface';
import handleError from '../../shared/handleError';

export const projectCreate = createAsyncThunk(
  'projectCreate',
  async (data: InterfaceProjectCreate) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/Project/FPCreate',
        data: {
          ...data,
          projectFieldingRequests: data.projectFieldingRequests.map(
            (fieldingRequest) => {
              return {
                ...fieldingRequest,
                dueDateString: fieldingRequest.dueDateString
                  ? moment(fieldingRequest.dueDateString).format('MM/DD/YYYY')
                  : null,
                fieldingRequestMarkers:
                  fieldingRequest.fieldingRequestMarkers.map((marker) => {
                    return {
                      ...marker,
                      lineData: JSON.stringify(marker.lineData),
                      shapeData: JSON.stringify(marker.shapeData),
                      timeOffset: moment().utcOffset() * -1,
                    };
                  }),
              };
            }
          ),
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectEdit = createAsyncThunk(
  'projectEdit',
  async (data: InterfaceProjectEdit) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/Project/FPEdit',
        data: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectEditMarker = createAsyncThunk(
  'projectEdit',
  async (data: any) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/FieldingRequestMarker/EditLocation',
        data: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectGet = createAsyncThunk(
  'projectGet',
  async (data: InterfaceProjectGetParams) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/Project/FPGetListByCompanyId',
        data: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectGetForStorage = createAsyncThunk(
  'projectGetForStorage',
  async () => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/Project/FPGetListByCompanyId',
        data: {
          skip: 0,
          take: 999999,
          token: userInfo().token,
          companyId: userInfo().user.companyId,
          filter: '',
          projectStatus: 0,
          sortField: '',
          sortType: 0,
          fielderIds: []
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectDelete = createAsyncThunk(
  'projectDelete',
  async (id: string) => {
    try {
      const response = await axios({
        method: 'delete',
        url: baseUrl + '/api/Project/Delete',
        params: {
          targetProjectId: id,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const markerDelete = createAsyncThunk(
  'markerDelete',
  async (id: string) => {
    try {
      const response = await axios({
        method: 'delete',
        url: baseUrl + '/newapi/FieldingRequestMarker/Delete',
        params: {
          token: userInfo().token,
          targetMarkerId: id,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectGetEnum = createAsyncThunk('projectGetEnum', async () => {
  try {
    const response = await axios({
      method: 'get',
      url: baseUrl + '/newapi/Project/FPGetProjectEnums',
    });
    return response;
  } catch (error: any) {
    handleError(error);
    return error.response;
  }
});

export const projectGetDetail = createAsyncThunk(
  'projectGetDetail',
  async (id: string) => {
    try {
      const response = await axios({
        method: 'get',
        url: baseUrl + '/newapi/Project/FPGetDetails',
        params: {
          projectId: id,
          token: userInfo().token,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const testSendSignalR = createAsyncThunk(
  'testSendSignalR',
  async () => {
    try {
      const response = await axios({
        method: 'get',
        url: baseUrl + '/newapi/Account/TestSendSignalR',
        params: {
          token: userInfo().token,
        },
      });
      // console.log('testSendSignalR', response);

      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectCustomerCodeCreate = createAsyncThunk(
  'projectCustomerCodeCreate',
  async (name: string) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/Project/FPCreateProjectCustomerCode',
        data: {
          token: userInfo().token,
          value: name,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectCustomerCodeGet = createAsyncThunk(
  'projectCustomerCodeGet',
  async () => {
    try {
      const response = await axios({
        method: 'get',
        url: baseUrl + '/newapi/Project/FPGetProjectCustomerCodes',
        params: { token: userInfo().token },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectCustomerCodeDelete = createAsyncThunk(
  'projectCustomerCodeDelete',
  async (id: string) => {
    try {
      const response = await axios({
        method: 'delete',
        url: baseUrl + '/newapi/Project/FPDeleteProjectCustomerCode',
        params: {
          token: userInfo().token,
          customerCodeId: id,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectLinkAppend = createAsyncThunk(
  'projectLinkAppend',
  async (data: InterfaceProjectLinkAppend) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/Project/FPSaveSupportingLinkAttachments',
        data: {
          projectId: data.projectId,
          links: data.links,
          token: userInfo().token,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectAttachmentDelete = createAsyncThunk(
  'projectAttachmentDelete',
  async (id: string) => {
    try {
      const response = await axios({
        method: 'delete',
        url: baseUrl + '/newapi/Project/DeleteProjectAttachment',
        params: {
          token: userInfo().token,
          projectAttachmentId: id,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectComplete = createAsyncThunk(
  'projectComplete',
  async (projectId: string) => {
    try {
      const response = await axios({
        method: 'put',
        url: baseUrl + '/newapi/Project/CompleteFielding',
        params: {
          token: userInfo().token,
          targetProjectId: projectId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectMoveToActive = createAsyncThunk("projectMoveToActive", async (projectId: string) => {
  try {
    const response = await axios({
      method: "put",
      url: baseUrl + "/newapi/Project/Reactivate",
      params: {
        token: userInfo().token,
        targetProjectId: projectId
      }
    });
    return response;
  } catch (error: any) {
    handleError(error);
    return error.response;
  }
});

export const projectMoveToArchive = createAsyncThunk(
  'projectMoveToArchive',
  async (projectId: string) => {
    try {
      const response = await axios({
        method: 'put',
        url: baseUrl + '/newapi/Project/Archive',
        params: {
          token: userInfo().token,
          targetProjectId: projectId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const projectFieldingAdd = createAsyncThunk(
  'projectFieldingAdd',
  async (data: InterfaceProjectFieldingAdd) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/Project/FPAddProjectFieldingRequest',
        data: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const ratingFielderAdd = createAsyncThunk(
  'ratingFielderAdd',
  async (data: InterfaceProjectFieldingAdd) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/Account/FPRateFielder',
        data: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);
