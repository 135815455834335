import FieldingDetailMap from "./FieldingDetailMap"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { fieldingDetailsGet, fieldingMarkerComplete, fieldingMarkerStart } from "../fielding.api"
import { useNavigate, useSearchParams } from "react-router-dom"
import { InitialFieldingDetail, InterfaceFieldingDetail } from "../fielding.interface"
import moment from "moment"
import { ArrowBack } from "@mui/icons-material"
import { Box, Button, useMediaQuery } from "@mui/material"
import CustomModal from "../../../shared/customModal/CustomModal"
import FieldingClock from "./FIeldingClock"
import FieldingDetailEdit from "./FieldingDetailEdit"
import FieldingNotes from "./FieldingNotes"
import FieldingProgress from "./FieldingProgress"
import ButtonOutlined from "../../../shared/ButtonOutlined"
import FieldingCompleteSequence from "./FieldingCompleteSequence"

export default function FieldingDetail() {
    const dispatch = useDispatch<any>()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const isMobile = useMediaQuery("(max-width:768px)")

    const [details, setDetails] = useState<InterfaceFieldingDetail>(InitialFieldingDetail)
    const [markerIdSelected, setMarkerIdSelected] = useState("")
    const [modal, setModal] = useState(false)
    const [modalNotes, setModalNotes] = useState(false)
    const [modalProgress, setModalProgress] = useState(false)
    const [sequenceContinueLater, setSequenceContinueLater] = useState(false)
    const [sequenceComplete, setSequenceComplete] = useState(false)

    useEffect(() => {
        getDetails()
    }, [])

    useEffect(() => {
        if (markerIdSelected !== "") {
            if (modal) {
                dispatch(fieldingMarkerStart(markerIdSelected))
            } else {
                dispatch(fieldingMarkerComplete(markerIdSelected))
            }
        }
    }, [modal])

    const getDetails = () => {
        dispatch(fieldingDetailsGet(searchParams.get("id") || ""))
            .unwrap()
            .then((response: any) => {
                let data: InterfaceFieldingDetail = response.data.data

                setDetails({
                    ...data,
                    dueDateString: moment(data.dueDateString).format("DD/MM/YYYY"),
                    fieldingRequestMarkers: data.fieldingRequestMarkers.map((marker: any) => {
                        return {
                            ...marker,
                            lineData: JSON.parse(marker.lineData),
                            shapeData: JSON.parse(marker.shapeData)
                        }
                    })
                })
            })
    }

    const handleComplete = () => {
        setSequenceComplete(true)
        setSequenceContinueLater(false)
    }

    const handleContinueLater = () => {
        setSequenceComplete(false)
        setSequenceContinueLater(true)
    }

    const handleCloseProgress = () => {
        getDetails()
        setModalProgress(false)
    }

    if (details.fieldingRequestMarkers.length === 0) {
        return <></>
    }

    return (
        <div className="go-fielding-detail">
            <FieldingCompleteSequence details={details} complete={sequenceComplete} continueLater={sequenceContinueLater} onCancel={() => { setSequenceComplete(false); setSequenceContinueLater(false); getDetails() }} onDone={() => navigate(-1)} />
            <CustomModal
                open={modal}
                onClose={() => setModal(false)}
                title="Edit Marker"
                size="sm"
                component={
                    <FieldingDetailEdit markerId={markerIdSelected} details={details} setDetails={setDetails} onClose={() => setModal(false)} />
                }
            />
            <CustomModal
                open={modalNotes}
                onClose={() => setModalNotes(false)}
                title="Notes"
                component={
                    <FieldingNotes
                        fieldingRequestId={details.id}
                        notes={details.fieldingRequestNotes}
                        onUpdateData={getDetails}
                    />
                }
            />
            <CustomModal
                open={modalProgress}
                onClose={() => setModalProgress(false)}
                title="Update Progress"
                styles={{ width: "90%", maxWidth: "700px" }}
                component={
                    <FieldingProgress
                        details={details}
                        isComplete={sequenceComplete}
                        onClose={() => setModalProgress(false)}
                        onUpdateData={handleCloseProgress}
                    />
                }
            />
            <div className="menu-header" style={{ justifyContent: "space-between", flexWrap: "wrap" }}>
                <div className="back">
                    <div>
                        <span>{details.name}</span>
                        <span>Due Date : {details.dueDateString}</span>
                    </div>
                    <div>
                        <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
                            Back
                        </Button>
                    </div>
                </div>
                {isMobile ? (
                    <Fragment>
                        <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "flex-end", gap: "8px" }}>
                            <FieldingClock />
                            <Box display={"flex"} gap={1}>
                                <ButtonOutlined
                                    size="small"
                                    color={details.fieldingRequestNotes.length > 0 ? "primary" : "secondary"}
                                    onClick={() => setModalNotes(true)}
                                >
                                    Notes
                                </ButtonOutlined>
                                <Button variant="contained" onClick={handleComplete}>Complete Fielding</Button>
                                <Button variant="contained" onClick={handleContinueLater} color="warning">Continue Later</Button>
                            </Box>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div>
                            <ButtonOutlined
                                size="small"
                                color={details.fieldingRequestNotes.length > 0 ? "primary" : "secondary"}
                                onClick={() => setModalNotes(true)}
                            >
                                Notes
                            </ButtonOutlined>
                        </div>
                        <div>
                            <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"} gap={1}>
                                <FieldingClock />
                                <Box display={"flex"} gap={1}>
                                    <Button variant="contained" onClick={handleComplete}>Complete Fielding</Button>
                                    <Button variant="contained" onClick={handleContinueLater} color="warning">Continue Later</Button>
                                </Box>
                            </Box>
                        </div>
                    </Fragment>
                )}
            </div>
            <div className="map">
                <FieldingDetailMap
                    center={{
                        latitude: parseFloat(details.firstMarkerLocation.split(",")[1]),
                        longitude: parseFloat(details.firstMarkerLocation.split(",")[0])
                    }}
                    fieldingRequest={{
                        name: details.name,
                        markers: details.fieldingRequestMarkers,
                        color: details.colorCode
                    }}
                    onClickGraphic={(markerId: string) => {
                        if (!Boolean(searchParams.get("preview"))) {
                            setModal(true)
                            setMarkerIdSelected(markerId)
                        }
                    }}
                />
                {!Boolean(searchParams.get("preview")) && (
                    <div
                        style={{
                            position: "absolute",
                            top: 25,
                            right: 8
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => setModalProgress(true)}
                        >
                            Update Progress
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}
