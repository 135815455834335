import { useEffect, useRef, useState } from "react"
import esriConfig from "@arcgis/core/config"
import esriMap from "@arcgis/core/Map"
import esriMapView from "@arcgis/core/views/MapView"
import esriGraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import esriZoom from "@arcgis/core/widgets/Zoom"
import esriSearch from "@arcgis/core/widgets/Search"
import esriGraphics from "@arcgis/core/Graphic"
import esriPoint from "@arcgis/core/geometry/Point"
import esriMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"
import esriPolygon from "@arcgis/core/geometry/Polygon"
import esriSimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol"
import esriSketch from "@arcgis/core/widgets/Sketch"
import { webMercatorToGeographic } from "@arcgis/core/geometry/support/webMercatorUtils"

import { esriToken } from "../../../state"
import { InterfaceMarkerLine, InterfaceMarkerPoint } from "../../fielding/fielding.interface"
import { Button, IconButton, Tooltip, useTheme } from "@mui/material"
import { ShapeLine, Undo, Clear, Done } from "@mui/icons-material"

export default function MyAccountArea(props: {
    center: InterfaceMarkerPoint | null
    shape: InterfaceMarkerLine
    setShape: (params: InterfaceMarkerLine) => void
    onDone: () => void
}) {
    const mapRef = useRef<any>()
    const theme = useTheme()

    const [layerMarker, setLayerMarker] = useState<any>()
    const [palleteActive, setPalleteActive] = useState(0)

    const [sketch, setSketch] = useState<esriSketch>()
    const [layerMarkerTemp, setLayerMarkerTemp] = useState<any>()

    const [tempPolygon, setTempPolygon] = useState<InterfaceMarkerLine>([])
    const [localPolygon, setLocalPolygon] = useState<InterfaceMarkerLine>([])

    useEffect(() => {
        setLocalPolygon(props.shape)
    }, [props.shape])

    useEffect(() => {
        // initialize
        esriConfig.apiKey = esriToken
        let map = new esriMap({
            basemap: "topo-vector", //"satellite"
        })
        let layerMarkerLocal = new esriGraphicsLayer()
        let layerMarkerTempLocal = new esriGraphicsLayer()
        map.add(layerMarkerLocal)
        map.add(layerMarkerTempLocal)
        let view = new esriMapView({
            container: mapRef.current,
            map: map,
            zoom: props.center !== null ? 15 : 5,
            center:
                props.center !== null
                    ? [props.center.longitude, props.center.latitude]
                    : [-99.09764528271961, 33.173506394266624],
        })
        setLayerMarker(layerMarkerLocal)
        // widget
        let viewZoom = new esriZoom({ view: view })
        view.ui.remove(viewZoom)
        let viewSearch = new esriSearch({ view: view, locationEnabled: false })
        view.ui.add(viewSearch, { position: "top-left" })
        // listener
        view.on("layerview-create", () => {
            if (document.getElementById("searchbox-input")) {
                ; (document.getElementById("searchbox-input") as HTMLInputElement).placeholder = "Enter adress or GPS"
            }
        })
        const sketchLocal = new esriSketch({
            layer: layerMarkerTempLocal,
            view: view
        })
        setSketch(sketchLocal)
        setLayerMarkerTemp(layerMarkerTempLocal)

        return () => view?.destroy()
    }, [])

    useEffect(() => {
        if (palleteActive === 0) {
            sketch?.cancel()
        }
        else if (palleteActive === 3) {
            sketch?.create("polygon")
        }
        sketch?.on("create", function (event) {
            if (event.state === "complete") {
                layerMarkerTemp.removeAll()
                var polygonGeometry = event.graphic.geometry;
                var wgs84Geometry = webMercatorToGeographic(polygonGeometry);
                const geometry: any = wgs84Geometry
                if (geometry.rings && geometry.rings.length > 0) {
                    const newShapeData: InterfaceMarkerLine = geometry.rings[0].map((latlng: any) => {
                        return {
                            latitude: latlng[1],
                            longitude: latlng[0]
                        }
                    })
                    setTempPolygon(newShapeData)
                }
            }
        });
    }, [palleteActive])

    useEffect(() => {
        if (tempPolygon.length > 0) {
            setLocalPolygon(tempPolygon)
            props.setShape(tempPolygon)
        }
    }, [tempPolygon])

    useEffect(() => {
        if (layerMarker) {
            layerMarker.removeAll()
            if (props.center !== null) {
                let pointMarker = new esriGraphics({
                    geometry: new esriPoint({
                        latitude: props.center?.latitude,
                        longitude: props.center?.longitude,
                    }),
                    symbol: new esriMarkerSymbol({
                        style: "circle",
                        color: "red",
                    }),
                })
                layerMarker.addMany([pointMarker])
            }
            if (localPolygon.length > 0) {
                let shapeMarker = new esriGraphics({
                    geometry: new esriPolygon({
                        rings: [localPolygon.map((shape) => [shape.longitude, shape.latitude])],
                    }),
                    symbol: new esriSimpleFillSymbol({
                        color: [10, 10, 10, 0.5],
                        outline: {
                            color: "red",
                            width: 2,
                        },
                    }),
                    attributes: {
                        type: "FIELDING_SHAPE",
                    },
                })
                layerMarker.addMany([shapeMarker])
            }
        }
    }, [layerMarker, props.center, localPolygon])

    const handleUndo = () => {
        if (localPolygon.length > 0) {
            setLocalPolygon(localPolygon.filter((s, i) => i !== (localPolygon.length || 1) - 1))
        }
    }

    const handleClear = () => {
        setLocalPolygon([])
    }

    const handleFinish = () => {
        sketch?.complete()
        setPalleteActive(0)
    }

    return (
        <div className="working-area">
            <div className="arcgis-container">
                <div ref={mapRef} style={{ height: "100%", width: "100%" }} />
                <div className="map-pallete top-right">
                    Markers:
                    <Tooltip title="Area" placement="left-end">
                        <IconButton
                            sx={{ border: palleteActive === 3 ? 1 : undefined }}
                            color={palleteActive === 3 ? "primary" : undefined}
                            onClick={() => setPalleteActive(palleteActive === 3 ? 0 : 3)}
                        >
                            <ShapeLine />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="undo" placement="left-end">
                        <IconButton color={palleteActive === 3 ? "primary" : undefined} onClick={handleUndo}>
                            <Undo />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="clear" placement="left-end">
                        <IconButton color={palleteActive === 3 ? "primary" : undefined} onClick={handleClear}>
                            <Clear />
                        </IconButton>
                    </Tooltip>
                </div>
                {palleteActive === 3 && (
                    <div className="map-pallete end" style={{ backgroundColor: theme.palette.primary.main }}>
                        <Button
                            onClick={handleFinish}
                            startIcon={<Done />}
                            sx={{ color: "white" }}
                        >
                            Finish
                        </Button>
                    </div>
                )}
            </div>
            <Button variant="outlined" sx={{ marginTop: 1 }} onClick={props.onDone}>Done</Button>
        </div>
    )
}
