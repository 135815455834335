import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { FormEvent, useState } from "react";

import logo_paypal from "../../../assets/images/logo_paypal.png";
import logo_mastercard from "../../../assets/images/logo_mastercard.png";

export default function CreditTopup(props: any) {

    const [values, setValues] = useState<any>({})

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
    }


    return (
        <Box width="30vw">
            <form onSubmit={(handleSubmit)}>
                <TextField fullWidth label="Amount" name="amount" value={values?.amount} onChange={handleChange} />
                <br />
                <br />
                <Divider />
                <br />
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Payment Method</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="1"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel control={<Radio />} labelPlacement="start" value="1" label={
                            <Box width={200} display="flex" alignItems="center">
                                <span style={{ marginRight: 10 }}>Paypal</span>
                                <img src={logo_paypal} alt="paypal" />
                            </Box>
                        } />
                        <FormControlLabel control={<Radio />} labelPlacement="start" value="2" label={
                            <Box width={200} display="flex" alignItems="center">
                                <span style={{ marginRight: 10 }}>Debit or Credit Card</span>
                                <img src={logo_mastercard} alt="paypal" />
                            </Box>
                        } />
                        <FormControlLabel control={<Radio />} labelPlacement="start" value="3" label={
                            <Box width={200} display="flex" alignItems="center">
                                <span>Other</span>
                            </Box>
                        } />
                    </RadioGroup>
                </FormControl>
                <br />
                <br />
                <Button variant="contained">Next</Button>
            </form>
        </Box>
    )
}