import { createSlice } from '@reduxjs/toolkit'
import { accountActivate, accountSendVerification, createCompany, login } from './auth.api';

export interface AuthState {
    loading: boolean
}
const initialState: AuthState = {
    loading: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    },
    extraReducers: (builder) => {

        builder.addCase(login.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(createCompany.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(createCompany.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(createCompany.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(accountSendVerification.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(accountSendVerification.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(accountSendVerification.rejected, (state, action) => {
            state.loading = false
        })

        builder.addCase(accountActivate.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(accountActivate.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(accountActivate.rejected, (state, action) => {
            state.loading = false
        })

    },
})

export const { setLoading } = authSlice.actions

export default authSlice.reducer