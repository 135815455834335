import { Box, List, ListItemButton, ListItemIcon, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import roles from "../../shared/roles"
import Storage from "../../pages/storage/component/Storage"
import routeList from "../../routeList"

export default function Sidetab(props: { expand: boolean }) {

    const navigate = useNavigate()

    const [hoverActive, setHoverActive] = useState<string>("")

    const menuList = routeList.filter(route => Boolean(route.icon))

    useEffect(() => {
    }, [])

    const handleClickMenu = (menu: any) => {
        navigate(menu.path)
    }

    return (
        <div
            className="sidetab"
        >
            <List>
                {menuList.filter(menu => roles(menu.access)).map((menu, i) => (
                    <ListItemButton
                        key={i}
                        className={(
                            window.location.hash.includes(menu.path) || hoverActive === menu.path
                                ? " active"
                                : ""
                        )}
                        onMouseEnter={() => setHoverActive(menu.path)}
                        onMouseLeave={() => setHoverActive("")}
                        onClick={() => handleClickMenu(menu)}
                    >
                        <ListItemIcon>
                            {menu.icon}
                        </ListItemIcon>
                    </ListItemButton>
                ))}
            </List>
            <List className="sidetab-expand" style={{ display: props.expand ? "initial" : "none" }}>
                {menuList.filter(menu => roles(menu.access)).map((menu, i) => (
                    <ListItemButton
                        key={i}
                        className={(
                            window.location.hash.includes(menu.path) || hoverActive === menu.path
                                ? " active"
                                : ""
                        )}
                        onMouseEnter={() => setHoverActive(menu.path)}
                        onMouseLeave={() => setHoverActive("")}
                        onClick={() => handleClickMenu(menu)}
                    >
                        <Typography>
                            {menu.name}
                        </Typography>
                    </ListItemButton>
                ))}
                <Box sx={{ padding: 2 }} position={"absolute"} bottom={0} left={0} right={0} >
                    <Storage />
                </Box>
            </List>
        </div>
    )
}