import { Box, Divider, Stack, TextField, Typography } from "@mui/material"
import { InterfaceAccountData } from "../account.interface"
import { useDispatch, useSelector } from "react-redux"
import { getFielderRating } from "../account.api"
import { Fragment, useEffect } from "react"
import { formatName, userInfo } from "../../../shared/utils"
import { Star } from "@mui/icons-material"
import { RootState } from "../../../store"

const dummyList = {
    name: "John Doe",
    summaryRate: 4.5,
    review: [
        {
            rater: "Aji Muhammad Fauji",
            rating: 4,
            comment: "Good Job and nice Staff !",
        },
        {
            rater: "Laura Angelina",
            rating: 5,
            comment: "Good Job and keep up the good work !",
        },
        {
            rater: "Jibon",
            rating: 1,
            comment: "His work Too slow !",
        },
    ],
}
export default function AccountRatingList(props: { data: InterfaceAccountData; onClose: any }) {
    const dispatch = useDispatch<any>()

    const { ratingFielderList, loading } = useSelector((state: RootState) => state.account)

    useEffect(() => {
        dispatch(getFielderRating({ token: userInfo().token, fielderId: props.data.id }))
    }, [])

    return (
        <Box>
            <Stack spacing={{ sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                <Typography variant={"h2"} sx={{ fontWeight: "bold" }}>
                    {props.data.averageRatingString}
                    {/* {dummyList.summaryRate} */}
                </Typography>

                <Stack justifyContent="center" direction="column">
                    <Typography sx={{ fontWeight: "bold" }}>
                        {formatName(props.data.firstName)} {formatName(props.data.lastName)}
                    </Typography>
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                        <Box sx={{ color: "gold" }}>
                            <Star
                                sx={{
                                    color: parseFloat(props.data.averageRatingString) >= 1 ? "gold" : "lightgrey",
                                }}
                            />
                            <Star
                                sx={{
                                    color: parseFloat(props.data.averageRatingString) >= 2 ? "gold" : "lightgrey",
                                }}
                            />
                            <Star
                                sx={{
                                    color: parseFloat(props.data.averageRatingString) >= 3 ? "gold" : "lightgrey",
                                }}
                            />
                            <Star
                                sx={{
                                    color: parseFloat(props.data.averageRatingString) >= 4 ? "gold" : "lightgrey",
                                }}
                            />
                            <Star
                                sx={{
                                    color: parseFloat(props.data.averageRatingString) >= 5 ? "gold" : "lightgrey",
                                }}
                            />
                        </Box>
                        <Typography>{props.data.ratingTotal ?? 0} Review</Typography>
                    </Stack>
                </Stack>
            </Stack>
            {!loading &&
                ratingFielderList.map((data) => (
                    <Fragment key={data.createdById}>
                        <Divider orientation="horizontal" flexItem sx={{ margin: "12px 0" }} />
                        <Stack
                            spacing={{ xs: 1, sm: 2 }}
                            direction="row"
                            useFlexGap
                            flexWrap="wrap"
                            alignItems="center"
                        >
                            <Box
                                borderRadius={"50%"}
                                sx={{
                                    backgroundColor: "green",
                                    color: "white",
                                    width: 30,
                                    height: 30,
                                    paddingTop: 1,
                                    fontSize: 10,
                                    textAlign: "center",
                                }}
                            >
                                {data.ratedByName
                                    .split(" ")
                                    .map((n, i) => {
                                        if (i < 2) return n[0]
                                    })
                                    .join("")}
                            </Box>
                            <Stack sx={{ paddingTop: "8px", width: "90%" }} direction="column">
                                <Stack direction="row" spacing={1} justifyContent="space-between">
                                    <Typography sx={{ fontWeight: "bold" }}>{formatName(data.ratedByName)}</Typography>
                                    <Box>
                                        <Star
                                            sx={{
                                                color: data.rating >= 1 ? "gold" : "lightgrey",
                                            }}
                                        />
                                        <Star
                                            sx={{
                                                color: data.rating >= 2 ? "gold" : "lightgrey",
                                            }}
                                        />
                                        <Star
                                            sx={{
                                                color: data.rating >= 3 ? "gold" : "lightgrey",
                                            }}
                                        />
                                        <Star
                                            sx={{
                                                color: data.rating >= 4 ? "gold" : "lightgrey",
                                            }}
                                        />
                                        <Star
                                            sx={{
                                                color: data.rating >= 5 ? "gold" : "lightgrey",
                                            }}
                                        />
                                    </Box>
                                </Stack>
                                <TextField
                                    multiline
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    style={{ wordWrap: "break-word" }}
                                    value={data.review}
                                />
                            </Stack>
                        </Stack>
                    </Fragment>
                ))}
        </Box>
    )
}
