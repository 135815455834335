import { Box, Button, Grid, Input, Slider, TextField, Typography } from "@mui/material"
import { InterfaceFieldingDetail } from "../fielding.interface"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { userInfo } from "../../../shared/utils"
import { fieldingSubmitProgress } from "../fielding.api"
import Swal from "sweetalert2"
import warning from "../../../assets/images/warning.png"

interface InterfaceValues {
    customTypeId: string
    customTypeString: string | null,
    progressPercentage: number | undefined
    actualFielding: number
    requestFielding: number
    finalActualFielding: number
}

export default function FieldingProgress(props: { details: InterfaceFieldingDetail; isComplete: boolean; onUpdateData: () => void; onClose: () => void }) {

    const dispatch = useDispatch<any>()

    const [values, setValues] = useState<InterfaceValues[]>([])
    const [isEdit, setIsEdit] = useState(false)
    const [completeWarningCount, setCompleteWarningCount] = useState(0)
    const [indexToEdit, setIndexToEdit] = useState<undefined | number>()
    const [tempEditValue, setTempEditValue] = useState<number>(0)

    useEffect(() => {
        if (props.details) {
            initValues()
        }
    }, [props.details])

    const initValues = () => {
        const fieldingIdList = props.details.fieldingRequestMarkers.map((marker) => ({ customTypeId: marker.customTypeId, customTypeString: marker.customTypeString }))
        const fieldingIdListUnique = fieldingIdList.filter((value, index, self) => self.map(d => d.customTypeId).indexOf(value.customTypeId) === index)
        let mapValue: InterfaceValues[] = fieldingIdListUnique.map((data) => {
            const actual = props.details.fieldingRequestMarkers.filter((marker) => marker.customTypeId === data.customTypeId).reduce((a, b) => a + parseInt(b.actualFielding || "0"), 0)
            const request = props.details.fieldingRequestMarkers.filter((marker) => marker.customTypeId === data.customTypeId).reduce((a, b) => a + parseInt(b.requestedFielding || "0"), 0)
            const slideProgress = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.sliderProgressPercentage || 0
            const totalActual = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === data.customTypeId)?.fieldingRequestActualFielding || '0'
            const calculationProgress = Math.round((actual / request) * 100)
            return {
                customTypeId: data.customTypeId,
                customTypeString: data.customTypeString,
                progressPercentage: slideProgress || (calculationProgress > 100 ? 100 : calculationProgress),
                actualFielding: actual,
                requestFielding: request,
                finalActualFielding: parseInt(totalActual) > actual ? parseInt(totalActual) : actual
            }
        })
        setValues(mapValue)

    }

    const handleSliderChange = async (e: any, customTypeId: any) => {
        let value = e.target.value
        value = value > 100 ? 100 : value < 0 ? 0 : value
        const minValue = props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === customTypeId)?.progressPercentage || 0
        if (value >= minValue) {
            if (values.find((v) => v.customTypeId === customTypeId)) {
                setValues(
                    values.map((v) => ({
                        ...v,
                        progressPercentage: v.customTypeId === customTypeId ? value : v.progressPercentage
                    }))
                )
            }
        }
    }

    const submitComplete = async (isForce?: boolean, isEdit?: boolean) => {
        await dispatch(fieldingSubmitProgress(values.map(value => ({
            token: userInfo().token,
            customTypeId: value.customTypeId,
            progressPercentage: isForce ? 100 : value.progressPercentage || 0,
            sliderProgressPercentage: isEdit ? value.progressPercentage || 0 : props.details.fieldingRequestMarkersProgress.find(progress => progress.customTypeId === value.customTypeId)?.sliderProgressPercentage || 0,
            fieldingRequestId: props.details.id,
            fieldingRequestActualFielding: value.finalActualFielding > value.actualFielding ? String(value.finalActualFielding) : String(value.actualFielding)
        }))))
            .unwrap()
            .then((res: any) => {
                if (res.status === 200) {
                    Swal.fire("Success!", "Progress updated!", "success").then(() => props.onUpdateData())
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Failed!",
                        text: "Failed update Progress"
                    })
                }
            })
    }

    const handlerSubmit = async (isEdit?: boolean) => {
        if (props.isComplete && values.some((v) => v.progressPercentage && v.progressPercentage < 100) && completeWarningCount === 0) {
            Swal.fire({
                icon: "error",
                title: "Failed!",
                text: "Fielding progress must be 100% in order to be completed"
            })
            setCompleteWarningCount(1)
        }
        else if (completeWarningCount === 1) {
            Swal.fire({
                icon: "question",
                title: "Are you sure to complete this request?",
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No"
            })
                .then((response) => {
                    if (response.isConfirmed) {
                        submitComplete(true, isEdit)
                    }
                })
        }
        else {
            submitComplete(false, isEdit)
        }
    }

    const isContainedLess80 = (customTypeId: string) => {
        return props.details.fieldingRequestMarkers.filter(marker => marker.customTypeId === customTypeId).some(marker => {
            const actual = typeof marker.actualFielding === 'string' ? parseInt(marker.actualFielding || '0') : 0
            const requested = typeof marker.requestedFielding === 'string' ? parseInt(marker.requestedFielding || '0') : 0
            return actual < 0.8 * requested
        })
    }

    const average = values.reduce((acc, curr) => acc + (curr.progressPercentage || 0), 0) / values.length

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
                paddingTop: 16
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    right: "22px",
                    top: 0,
                    display: "flex",
                    width: "150px",
                    justifyContent: "space-between"
                }}
            >
                <Typography fontWeight={"bold"} sx={{ fontSize: 18 }}>{`Average :`}</Typography>
                <Typography fontWeight={"bold"} sx={{ fontSize: 18 }}>{`${average.toFixed(2)}`}</Typography>
                <Typography fontWeight={"bold"} sx={{ fontSize: 18 }}>%</Typography>
            </Box>
            {values?.map((value, index) => (
                <Box sx={{ padding: "20px", width: "100%" }} key={value.customTypeId}>
                    <Typography
                        sx={{
                            fontSize: "16px"
                        }}
                    >
                        {value.customTypeString}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "auto", flexGrow: 1 }}>
                        <Slider
                            defaultValue={0}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            value={value.progressPercentage || 0}
                            onChange={(e) => isEdit && handleSliderChange(e, value.customTypeId)}
                            sx={{ flexGrow: 1 }}
                        />
                        <Input
                            value={value.progressPercentage}
                            onChange={(e) => handleSliderChange(e, value.customTypeId)}
                            disableUnderline
                            sx={{
                                marginLeft: "10px",
                                paddingLeft: "10px",
                                fontSize: "18px",
                                width: "50px"
                            }}
                            inputProps={{
                                min: 0,
                                max: 100
                            }}
                        />
                        <Typography
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "18px"
                            }}
                        >
                            %
                        </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} gap={1}>
                        <Typography>Total Request: {value.requestFielding}</Typography>
                        <Box display={"flex"} gap={1} flexGrow={1} alignItems={"center"} flexWrap={"wrap"} justifyContent={"flex-end"}>
                            {isContainedLess80(value.customTypeId) && <img src={warning} alt="wrning" height={42} width={42} />}
                            {index !== indexToEdit && (
                                <Button size="small" variant="contained" onClick={() => { setIndexToEdit(index); setTempEditValue(value.finalActualFielding) }}>Edit</Button>
                            )}
                            <Typography sx={{ whiteSpace: "nowrap" }}>Total Actual:</Typography>
                            {index === indexToEdit
                                ? (
                                    <Fragment>
                                        <TextField
                                            type="number"
                                            value={tempEditValue}
                                            sx={{ width: 70 }}
                                            onChange={(e) => setTempEditValue(parseInt(e.target.value))}
                                        />
                                        <Box display={"flex"} gap={1}>
                                            <Button size="small" variant="outlined" onClick={() => setIndexToEdit(undefined)}>Cancel</Button>
                                            <Button size="small" variant="contained" onClick={() => { setIndexToEdit(undefined); setValues(values.map((value, i) => i === index ? ({ ...value, finalActualFielding: tempEditValue }) : value)) }}>Done</Button>
                                        </Box>
                                    </Fragment>
                                )
                                : (
                                    <Typography>{value.finalActualFielding}</Typography>
                                )
                            }
                        </Box>
                    </Box>
                </Box>
            ))}
            {isEdit
                ? (
                    <Button
                        variant="contained"
                        onClick={() => handlerSubmit(true)}
                        size="large"
                        sx={{ margin: "16px 0", width: "96%" }}
                        fullWidth
                    >
                        Submit Progress
                    </Button>
                )
                : (
                    <Grid container spacing={2} marginY={2}>
                        <Grid item xs={12} lg={6}>
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={() => setIsEdit(true)}
                                fullWidth
                            >
                                Click here to edit %
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Button
                                variant="contained"
                                onClick={() => handlerSubmit()}
                                size="large"
                                fullWidth
                            >
                                Submit Progress
                            </Button>
                        </Grid>
                    </Grid>
                )
            }
            <Typography color={"error"} variant="h6" align="center">
                WARNING: PROGRESS UPDATE & QUANTITY COMPLETION IS MANDATORY FOR MILAGE CLAIM TO BE ACKNOWLEDGED
            </Typography>
        </div>
    )
}
