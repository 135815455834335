import { useEffect, useRef, useState } from "react"

import esriConfig from "@arcgis/core/config"
import esriMap from "@arcgis/core/Map"
import esriMapView from "@arcgis/core/views/MapView"
import esriGraphicsLayer from "@arcgis/core/layers/GraphicsLayer"
import esriZoom from "@arcgis/core/widgets/Zoom"
import esriSearch from "@arcgis/core/widgets/Search"
import esriGraphics from "@arcgis/core/Graphic"
import esriPoint from "@arcgis/core/geometry/Point"
import esriMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol"
import esriSketch from "@arcgis/core/widgets/Sketch"

import { esriToken } from "../../../state"
import { locationToAddress } from "@arcgis/core/rest/locator";
import { InterfaceMarkerPoint } from "../../fielding/fielding.interface"
import axios from "axios"
import { Button } from "@mui/material"
import { webMercatorToGeographic } from "@arcgis/core/geometry/support/webMercatorUtils"

export default function MyAccountChooseAddress(props: {
    center: InterfaceMarkerPoint | null,
    setAddress: (params: string) => void,
    onDone: () => void,
}) {

    const mapRef = useRef<any>()

    const [view, setView] = useState<any>()
    const [sketch, setSketch] = useState<esriSketch>()
    const [layerMarker, setLayerMarker] = useState<any>()
    const [layerMarkerTemp, setLayerMarkerTemp] = useState<any>()
    const [palleteActive, setPalleteActive] = useState(0)

    const [tempPoint, setTempPoint] = useState<InterfaceMarkerPoint | undefined>()
    const [localPoint, setLocalPoint] = useState<InterfaceMarkerPoint | null>()

    useEffect(() => {
        setLocalPoint(props.center)
    }, [props.center])

    useEffect(() => {
        axios("https://ipapi.co/json")
            .then(response => {
                // initialize
                esriConfig.apiKey = esriToken
                let map = new esriMap({
                    basemap: "topo-vector" //"satellite"
                })
                let layerMarkerLocal = new esriGraphicsLayer()
                let layerMarkerTempLocal = new esriGraphicsLayer()
                map.add(layerMarkerLocal)
                map.add(layerMarkerTempLocal)
                let viewLocal = new esriMapView({
                    container: mapRef.current,
                    map: map,
                    zoom: props.center !== null ? 15 : 11,
                    center: props.center !== null ? [props.center.longitude, props.center.latitude] : [response.data.longitude, response.data.latitude]
                })
                setLayerMarker(layerMarkerLocal)
                setLayerMarkerTemp(layerMarkerTempLocal)
                // widget
                let viewZoom = new esriZoom({ view: viewLocal })
                viewLocal.ui.remove(viewZoom)
                let viewSearch = new esriSearch({ view: viewLocal, locationEnabled: false })
                viewLocal.ui.add(viewSearch, { position: "top-left" })
                const sketchLocal = new esriSketch({
                    layer: layerMarkerTempLocal,
                    view: viewLocal
                })
                setSketch(sketchLocal)
                // listener
                viewLocal.on("layerview-create", () => {
                    if (document.getElementById("searchbox-input")) {
                        (document.getElementById("searchbox-input") as HTMLInputElement).placeholder = "Enter adress or GPS"
                    }
                })
                setView(viewLocal)
            })
        return () => view?.destroy()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (palleteActive === 0) {
            sketch?.cancel()
        }
        else if (palleteActive === 1) {
            sketch?.create("point")
        }
        sketch?.on("create", function (event) {
            if (event.state === "complete") {
                layerMarkerTemp.removeAll()
                var polygonGeometry = event.graphic.geometry
                var wgs84Geometry = webMercatorToGeographic(polygonGeometry)
                const geometry: any = wgs84Geometry
                if (geometry.latitude && geometry.longitude) {
                    setTempPoint({ latitude: geometry.latitude, longitude: geometry.longitude })
                }
                setPalleteActive(0)
                sketch?.cancel()
            }
        })
        // eslint-disable-next-line
    }, [palleteActive, sketch, palleteActive])

    useEffect(() => {
        if (tempPoint !== undefined && tempPoint.latitude !== 0) {
            setLocalPoint({ latitude: tempPoint?.latitude || 0, longitude: tempPoint?.longitude || 0 })
        }
    }, [tempPoint])

    useEffect(() => {
        if (layerMarker) {
            layerMarker.removeAll()
            let pointMarker = new esriGraphics({
                geometry: new esriPoint({
                    latitude: localPoint?.latitude,
                    longitude: localPoint?.longitude
                }),
                symbol: new esriMarkerSymbol({
                    style: "circle",
                    color: "red"
                })
            })
            layerMarker.addMany([pointMarker])
        }
    }, [layerMarker, localPoint])

    useEffect(() => {
        if (tempPoint?.latitude !== 0) {
            locationToAddress("http://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer?token=" + esriToken + "&featureTypes=PointAddress", { location: new esriPoint({ latitude: tempPoint?.latitude, longitude: tempPoint?.longitude }) })
                .then(response => {
                    props.setAddress(response.address)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [tempPoint])

    return (
        <div className="working-area">
            <div className="arcgis-container">
                <div ref={mapRef} style={{ height: "100%", width: "100%" }} />
                <div className="map-pallete transparent top-right">
                    <Button
                        onClick={() => setPalleteActive(palleteActive === 1 ? 0 : 1)}
                        variant={palleteActive === 1 ? "outlined" : "contained"}
                    >
                        {palleteActive === 1 ? "Cancel" : "Edit"}
                    </Button>
                </div>
            </div>
            <Button variant="outlined" sx={{ marginTop: 1 }} onClick={props.onDone}>Done</Button>
        </div>
    )
}