export interface InterfaceExpense {
    id: string,
    project: string,
    fieldingRequestId: string,
    fieldingRequest: string,
    createdDate: string,
    createdDateString: string,
    createdById: string,
    createdBy: string,
    status: number,
    statusString: string,
    notes: string,
    amount: number
}

export interface InterfaceExpenseDetail extends InterfaceExpense {
    attachments: {
        id: string,
        fileName: string,
        filePath: string
    }[],
    modifiedDate: string,
    modifiedDateString: string,
    approvalMessage: string
}

export interface InterfaceExpenseGet {
    skip: number,
    take: number,
    startDateString: string,
    endDateString: string,
    filter: string,
    status: number
}

export const InitialExpenseGet: InterfaceExpenseGet = {
    skip: 0,
    take: 50,
    startDateString: '',
    endDateString: '',
    filter: '',
    status: 0
}