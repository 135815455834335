import {
    ArrowDownward,
    ArrowUpward,
    OpenInNew,
    UnfoldMore,
    List as ListIcon,
    Image,
    FileCopyOutlined as IconFlie
} from "@mui/icons-material"
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    Typography,
    styled
} from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import CustomModal from "../../../shared/customModal/CustomModal"
import { useDispatch, useSelector } from "react-redux"
import {
    accountStorageGet,
    accountStorageGetByFielding,
    accountStorageGetByProject,
    accountStorageGetByTools,
    getAccount
} from "../../account/account.api"
import { RootState } from "../../../store"
import { InterfaceAccountStorageFileList } from "../../account/account.interface"
import { Pie } from "react-chartjs-2"
import { Chart, ArcElement } from "chart.js"
import { projectGetForStorage } from "../../project/project.api"
import { InterfaceProjectDataList } from "../../project/project.interface"
import { InterfaceFieldingNameList, InterfaceToolNameList } from "../../fielding/fielding.interface"
import {
    FieldingNameGetByFieldingId,
    FieldingNameGetByProjectId,
    fieldingAttachmentDelete,
    fieldingMarkerAttachmentDelete,
    requestDeleteAttachment
} from "../../fielding/fielding.api"
import { useWindowSize, userInfo } from "../../../shared/utils"
Chart.register(ArcElement)

const StyledList = styled(List)({
    // selected and (selected + hover) states
    root: { padding: 0 },
    "&, & .ListItemButton-root": {
        pading: 0
        // color: "white"
    },
    "&& .Mui-selected, && .Mui-selected:hover": {
        backgroundColor: "gray",
        "&, & .ListItemButton-root": {
            pading: 0
            // color: "white"
        }
    },
    "& .MuiList-root": {
        padding: 0,
        "&, & .MuiList-root": {
            padding: 0
        }
    },
    "& .List-root": {
        padding: 0
    },
    // hover states
    "& .MuiListItemButton-root:hover": {
        backgroundColor: "#c7c7c7",
        "&, & .MuiListItemIcon-root": {
            color: "black"
        }
    }
})

export default function Storage() {
    const dispatch = useDispatch<any>()
    const { width } = useWindowSize()

    const { storageDetails, parameterGetData, storageDetailsPerGroup, loading } = useSelector(
        (state: RootState) => state.account
    )

    const [statusModal, setStatusModal] = useState(false)
    const [selectedFieldingId, setSelectedFieldingId] = useState<string>("")
    const [SelectedUser, setSelectedUser] = useState<string>("")
    const [userList, setUserList] = useState<any>([])
    const [selectedFile, setSelectedFile] = useState<any>([])
    const [projectList, setProjectList] = useState<InterfaceProjectDataList>([])
    const [fieldingNameList, setFieldingNameList] = useState<InterfaceFieldingNameList>([])
    const [typeList, setTypeList] = useState<string>("list")
    const [toolsNameList, setToolsNameList] = useState<InterfaceToolNameList>([])
    const [fileList, setFileList] = useState<InterfaceAccountStorageFileList>([])
    const [sortColumn, setSortColumn] = useState<"NAME" | "SIZE" | "DATE">("NAME")
    const [sortType, setSortType] = useState<"ASC" | "DESC">("ASC")
    const [projectIdSelected, setProjectIdSelected] = useState("")
    const [fieldingIdSelected, setFieldingIdSelected] = useState("")
    const [toolIdSelected, setToolIdSelected] = useState<string>("")

    const dataList = [
        {
            name: "Images",
            color: "skyblue",
            valueSize: storageDetails.totalImageSize,
            valuePercentage: storageDetails.totalImageSizePercentage
        },
        {
            name: "Documents",
            color: "magenta",
            valueSize: storageDetails.totalDocumentSize,
            valuePercentage: storageDetails.totalDocumentSizePercentage
        }
    ]

    useEffect(() => {
        checkAccountList()
    }, [statusModal])

    useEffect(() => {
        // if (toolIdSelected !== "")
        //     dispatch(accountStorageGetByTools({ fieldingRequestMarkerId: toolIdSelected, SelectedUser }))
        // else if (fieldingIdSelected !== "") {
        //     dispatch(accountStorageGetByFielding({ fieldingRequestId: fieldingIdSelected, SelectedUser }))
        //     setToolIdSelected("")
        // }
        dispatch(accountStorageGet(SelectedUser))
        // console.log("SelectedUser", SelectedUser)
    }, [SelectedUser])

    useEffect(() => {
        setProjectIdSelected("")
        setSelectedFieldingId("")
        setToolIdSelected("")
        setFieldingIdSelected("")
        setSelectedFile([])

        if (statusModal) {
            dispatch(projectGetForStorage())
                .unwrap()
                .then((response: any) => {
                    setProjectList(response.data.data.data)
                    setFileList([])
                })
        }
    }, [statusModal, SelectedUser])

    const checkAccountList = async () => {
        dispatch(getAccount({ ...parameterGetData, take: 500 }))
            .unwrap()
            .then((res: any) => {
                if (res.status === 200) {
                    const data = res.data.data.data
                    let checkYourId = data.find((dt: any) => dt.id === userInfo()?.user?.id)
                    checkYourId = { ...checkYourId, name: checkYourId?.firstName + " " + checkYourId?.lastName }

                    let alluser = userInfo()?.user?.roles?.includes("CompanyAdminCompanyStorage")
                    if (alluser) {
                        setSelectedUser(checkYourId?.id)
                        let resUser = data
                            .filter((dt: any) => dt.id !== userInfo()?.user?.id)
                            .map((dt: any) => ({ ...dt, name: dt?.firstName + " " + dt?.lastName }))

                        let allUser = { id: 9999999999, name: "ALL USER" }
                        let dataDropdown = [checkYourId, allUser, ...resUser]
                        // console.log("dataDropdown", dataDropdown)

                        setUserList(dataDropdown)
                    } else {
                        setSelectedUser(checkYourId?.id)
                        setUserList([checkYourId])
                    }
                }
            })
            .catch((err: any) => console.log(err))
    }

    const handleProject = (projectId: string) => {
        setToolIdSelected("")
        setFieldingIdSelected("")
        setFileList([])
        setSelectedFile([])
        if (projectIdSelected !== projectId) {
            setProjectIdSelected(projectId)
            dispatch(accountStorageGetByProject(projectId))
            dispatch(FieldingNameGetByProjectId(projectId))
                .unwrap()
                .then((response: any) => {
                    let data = response.data.data
                    setFieldingNameList(data)
                })
        } else {
            setProjectIdSelected("")
        }
    }

    const handleFielding = (fieldingId: string) => {
        setToolIdSelected("")
        setSelectedFieldingId(fieldingId)
        setFileList([])
        setSelectedFile([])

        if (fieldingIdSelected !== fieldingId) {
            setFieldingIdSelected(fieldingId)
            dispatch(FieldingNameGetByFieldingId(fieldingId))
                .unwrap()
                .then((response: any) => {
                    let dot: number = 0
                    let line: number = 0
                    let area: number = 0
                    let data = response.data?.data.map((dt: any, i: number) => {
                        let name = ""

                        if (dt.markerTypeString === "Dot") {
                            dot = dot + 1
                            name = `Dot ${dot}`
                        }
                        if (dt.markerTypeString === "Line") {
                            line = line + 1
                            name = `Line ${line}`
                        }
                        if (dt.markerTypeString === "Area") {
                            area = area + 1
                            name = `Area ${area}`
                        }
                        return {
                            id: dt.id,
                            name: name
                        }
                    })
                    setToolsNameList(data)
                })
            dispatch(accountStorageGetByFielding({ fieldingRequestId: fieldingId, SelectedUser }))
                .unwrap()
                .then((response: any) => {
                    let dataFile = response?.data?.data?.fileList
                    setFileList([...dataFile])
                })
        } else {
            setFieldingIdSelected("")
        }
    }

    const handleTools = (toolId: string) => {
        setToolIdSelected(toolId)
        setFileList([])
        setSelectedFile([])
        if (toolIdSelected !== toolId) {
            setToolIdSelected(toolId)
            dispatch(accountStorageGetByTools({ fieldingRequestMarkerId: toolId, SelectedUser }))
                .unwrap()
                .then((response: any) => {
                    let dataFile = response?.data?.data?.fileList
                    setFileList([...dataFile])
                })
        } else setToolIdSelected("")
    }

    const handleSelect = (fileId: string) => {
        if (selectedFile.find((idx: string) => idx === fileId))
            setSelectedFile(selectedFile.filter((idx: string) => idx !== fileId))
        else setSelectedFile([...selectedFile, fileId])
    }

    const handleDeleteSelected = async () => {
        for (const i in selectedFile) {
            // if (toolIdSelected !== "") await dispatch(fieldingMarkerAttachmentDelete(selectedFile[i]))
            // else await dispatch(fieldingAttachmentDelete(selectedFile[i]))
            await dispatch(requestDeleteAttachment(selectedFile[i]))
        }
        setSelectedFile([])
        if (selectedFieldingId !== "") await dispatch(accountStorageGetByFielding(selectedFieldingId))
        // else setFieldingIdSelected("")
        if (toolIdSelected !== "") {
            dispatch(accountStorageGetByTools({ fieldingRequestMarkerId: toolIdSelected, SelectedUser }))
                .unwrap()
                .then((response: any) => {
                    let dataFile = response?.data?.data?.fileList
                    setFileList([...dataFile])
                })
        } else if (fieldingIdSelected !== "") {
            dispatch(accountStorageGetByFielding({ fieldingRequestId: fieldingIdSelected, SelectedUser }))
                .unwrap()
                .then((response: any) => {
                    let dataFile = response?.data?.data?.fileList
                    setFileList([...dataFile])
                })
        }
        dispatch(accountStorageGet(SelectedUser))
    }

    return (
        <Fragment>
            <CustomModal
                open={statusModal}
                title="Storage Detail"
                size="lg"
                onClose={() => setStatusModal(false)}
                component={
                    <Box className={"custom-storage"} style={{ height: "67vh" }}>
                        <Grid container spacing={2} sx={{ display: "flex", alignItems: "flex-start" }}>
                            <Grid item xs={12} md={3}>
                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            marginBottom: "16px"
                                        }}
                                    >
                                        <Typography>User</Typography>
                                        <Select
                                            defaultValue={10}
                                            className="user-storage-select"
                                            name="user-storage-select"
                                            value={userList.find((user: any) => user.id === SelectedUser)?.id}
                                            onChange={(e: any) => {
                                                setSelectedUser(e.target.value)
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: userList.find(
                                                                (user: any) => user.id === SelectedUser
                                                            )?.id
                                                                ? "lightgray"
                                                                : ""
                                                        },
                                                        "&& .Mui-selected": {
                                                            backgroundColor: userList.find(
                                                                (user: any) => user.id === SelectedUser
                                                            )?.id
                                                                ? "#9dc0ff"
                                                                : ""
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            {userList.map((user: any, i: number) => (
                                                <MenuItem
                                                    key={i}
                                                    value={user.id}
                                                    sx={{
                                                        borderBottom: i === 0 ? "1px solid lightgray" : "",
                                                        // backgroundColor: "blue" ,
                                                        backgroundColor: SelectedUser === user.id ? "#9dc0ff" : "",
                                                        // borderBottom: i === 0 ? "1px solid lightgray" : "",
                                                        position: "relative",
                                                        fontWeight: i === 0 ? "bold" : ""
                                                    }}
                                                >
                                                    {user.name}
                                                    {i === 0 ? (
                                                        <p
                                                            className="default-user"
                                                            style={{
                                                                position: "absolute",
                                                                fontSize: 10,
                                                                right: 10,
                                                                color: "gray",
                                                                fontWeight: "normal"
                                                            }}
                                                        >
                                                            ( Default )
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                    <List>
                                        {[
                                            ...dataList
                                            // {
                                            //     name: "free",
                                            //     color: "rgba(200, 200, 200, 1)",
                                            //     valueSize: storageDetails.totalStorageUsed.split("GB")[0]
                                            // }
                                        ].map((data, i) => (
                                            <ListItemButton key={i} selected={i === 0}>
                                                <Box
                                                    minHeight={15}
                                                    minWidth={15}
                                                    bgcolor={data.color}
                                                    marginRight={2}
                                                    borderRadius={"50%"}
                                                ></Box>
                                                <ListItemText primary={data.name} />
                                                <ListItemSecondaryAction>{data.valueSize}</ListItemSecondaryAction>
                                            </ListItemButton>
                                        ))}
                                    </List>
                                    <Pie
                                        data={{
                                            labels: [...dataList.map((data) => data.name), "Free"],
                                            datasets: [
                                                {
                                                    data: [
                                                        ...dataList.map((data) => data.valuePercentage),
                                                        100 -
                                                            dataList.reduce(
                                                                (total, data) => total + data.valuePercentage,
                                                                0
                                                            )
                                                    ],
                                                    backgroundColor: ["skyblue", "magenta", "rgba(200, 200, 200, 1)"]
                                                }
                                            ]
                                        }}
                                    />
                                    <Typography align="center" variant="h6">
                                        {storageDetails.totalStorageUsed}
                                    </Typography>
                                    <Typography align="center" variant="body1">
                                        Storage used
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Card variant="outlined">
                                    <CardContent
                                        sx={{
                                            padding: 0,
                                            overflow: "hidden !important",
                                            display: "flex",
                                            flexDirection: width < 900 ? "column" : "row"
                                        }}
                                    >
                                        <Box
                                            width={width < 900 ? "100%" : "50%"}
                                            height={width < 900 ? "250px" : "100%"}
                                            sx={{ display: "flex" }}
                                        >
                                            <Box
                                                width={"40%"}
                                                // width={"20%"}
                                                borderRight={"1px solid lightgray"}
                                                position={"relative"}
                                            >
                                                <Typography
                                                    textAlign={"center"}
                                                    sx={{ borderBottom: "1px solid gray", background: "white" }}
                                                    padding={1}
                                                    color={"white"}
                                                    style={{ background: "#2e6bd8" }}
                                                >
                                                    Project
                                                </Typography>
                                                <List sx={{ maxHeight: "52vh", overflow: "auto", height: "100%" }}>
                                                    {projectList.map((project, i) => (
                                                        <StyledList key={i}>
                                                            <ListItemButton
                                                                sx={{
                                                                    bgcolor:
                                                                        projectIdSelected === project.id ? "gray" : "",
                                                                    color:
                                                                        projectIdSelected === project.id
                                                                            ? "white"
                                                                            : "black"
                                                                }}
                                                                onClick={() => handleProject(project.id)}
                                                            >
                                                                {project.projectNumber}
                                                            </ListItemButton>
                                                        </StyledList>
                                                    ))}
                                                </List>
                                            </Box>
                                            {projectIdSelected !== "" && (
                                                <Box
                                                    width={"40%"}
                                                    // width={"18%"}
                                                    borderRight={"1px solid lightgray"}
                                                    position={"relative"}
                                                >
                                                    <Typography
                                                        textAlign={"center"}
                                                        sx={{ borderBottom: "1px solid gray", background: "white" }}
                                                        padding={1}
                                                        style={{ background: "#5690f7" }}
                                                        color={"#17366f"}
                                                    >
                                                        Request
                                                    </Typography>
                                                    <List sx={{ maxHeight: "52vh", overflow: "auto", height: "100%" }}>
                                                        {fieldingNameList.map((fielding, i) => (
                                                            <StyledList key={i}>
                                                                <ListItemButton
                                                                    sx={{
                                                                        bgcolor:
                                                                            fieldingIdSelected === fielding.id
                                                                                ? "gray"
                                                                                : "",
                                                                        color:
                                                                            fieldingIdSelected === fielding.id
                                                                                ? "white"
                                                                                : "black"
                                                                    }}
                                                                    onClick={() => handleFielding(fielding.id)}
                                                                >
                                                                    {fielding.name}
                                                                </ListItemButton>
                                                            </StyledList>
                                                        ))}
                                                    </List>
                                                </Box>
                                            )}
                                            {fieldingIdSelected !== "" && (
                                                <Box
                                                    width={"20%"}
                                                    // width={"12%"}
                                                    borderRight={"1px solid lightgray"}
                                                    position={"relative"}
                                                >
                                                    <Typography
                                                        textAlign={"center"}
                                                        sx={{ borderBottom: "1px solid gray", background: "white" }}
                                                        padding={1}
                                                        style={{ background: "#9dc0ff" }}
                                                        color={"#2051ab"}
                                                    >
                                                        Markers
                                                    </Typography>
                                                    <List sx={{ maxHeight: "52vh", overflow: "auto", height: "100%" }}>
                                                        {toolsNameList.map((tool, i) => (
                                                            <StyledList key={i}>
                                                                <ListItemButton
                                                                    sx={{
                                                                        bgcolor:
                                                                            toolIdSelected === tool.id ? "gray" : "",
                                                                        color:
                                                                            toolIdSelected === tool.id
                                                                                ? "white"
                                                                                : "black"
                                                                    }}
                                                                    onClick={() => handleTools(tool.id)}
                                                                >
                                                                    {tool.name}
                                                                </ListItemButton>
                                                            </StyledList>
                                                        ))}
                                                    </List>
                                                </Box>
                                            )}
                                        </Box>
                                        <Box
                                            width={width < 900 ? "100%" : "50%"}
                                            height={width < 900 ? "300px" : "100%"}
                                            sx={{ display: "flex", overflow: "auto" }}
                                            marginTop={width < 900 ? "37px" : "0"}
                                            borderTop={"1px solid lightgray"}
                                        >
                                            {fileList.length > 0 && (
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        maxHeight: "57.5vh",
                                                        width: "100%"
                                                        // width: "50%"
                                                    }}
                                                >
                                                    {selectedFieldingId !== "" && projectIdSelected !== "" && (
                                                        <>
                                                            <div
                                                                style={{
                                                                    height: "56.5vh"
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        overflow: "auto",
                                                                        position: "relative",
                                                                        height: "100%"
                                                                    }}
                                                                >
                                                                    {typeList === "list" ? (
                                                                        <table className="storage">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        <Checkbox
                                                                                            checked={Boolean(
                                                                                                selectedFile.length ===
                                                                                                    fileList.length
                                                                                            )}
                                                                                            onClick={() => {
                                                                                                if (
                                                                                                    selectedFile.length ===
                                                                                                    fileList.length
                                                                                                )
                                                                                                    setSelectedFile([])
                                                                                                else
                                                                                                    setSelectedFile(
                                                                                                        fileList.map(
                                                                                                            (
                                                                                                                list: any
                                                                                                            ) =>
                                                                                                                toolIdSelected !==
                                                                                                                ""
                                                                                                                    ? list?.fieldingRequestMarkerAttachmentId
                                                                                                                    : list?.fieldingRequestAttachmentId
                                                                                                        )
                                                                                                    )
                                                                                            }}
                                                                                        />
                                                                                        {/* Select All */}
                                                                                    </th>
                                                                                    <th>
                                                                                        Name{" "}
                                                                                        <IconButton
                                                                                            color={
                                                                                                sortColumn === "NAME"
                                                                                                    ? "primary"
                                                                                                    : "default"
                                                                                            }
                                                                                            onClick={() => {
                                                                                                if (
                                                                                                    sortColumn ===
                                                                                                    "NAME"
                                                                                                ) {
                                                                                                    setSortType(
                                                                                                        sortType ===
                                                                                                            "ASC"
                                                                                                            ? "DESC"
                                                                                                            : "ASC"
                                                                                                    )
                                                                                                } else {
                                                                                                    setSortColumn(
                                                                                                        "NAME"
                                                                                                    )
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {sortColumn === "NAME" ? (
                                                                                                sortType === "ASC" ? (
                                                                                                    <ArrowDownward />
                                                                                                ) : (
                                                                                                    <ArrowUpward />
                                                                                                )
                                                                                            ) : (
                                                                                                <UnfoldMore />
                                                                                            )}
                                                                                        </IconButton>
                                                                                    </th>
                                                                                    <th>
                                                                                        Size{" "}
                                                                                        <IconButton
                                                                                            color={
                                                                                                sortColumn === "SIZE"
                                                                                                    ? "primary"
                                                                                                    : "default"
                                                                                            }
                                                                                            onClick={() => {
                                                                                                if (
                                                                                                    sortColumn ===
                                                                                                    "SIZE"
                                                                                                ) {
                                                                                                    setSortType(
                                                                                                        sortType ===
                                                                                                            "ASC"
                                                                                                            ? "DESC"
                                                                                                            : "ASC"
                                                                                                    )
                                                                                                } else {
                                                                                                    setSortColumn(
                                                                                                        "SIZE"
                                                                                                    )
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {sortColumn === "SIZE" ? (
                                                                                                sortType === "ASC" ? (
                                                                                                    <ArrowDownward />
                                                                                                ) : (
                                                                                                    <ArrowUpward />
                                                                                                )
                                                                                            ) : (
                                                                                                <UnfoldMore />
                                                                                            )}
                                                                                        </IconButton>
                                                                                    </th>
                                                                                    <th>
                                                                                        Date{" "}
                                                                                        <IconButton
                                                                                            color={
                                                                                                sortColumn === "DATE"
                                                                                                    ? "primary"
                                                                                                    : "default"
                                                                                            }
                                                                                            onClick={() => {
                                                                                                if (
                                                                                                    sortColumn ===
                                                                                                    "DATE"
                                                                                                ) {
                                                                                                    setSortType(
                                                                                                        sortType ===
                                                                                                            "ASC"
                                                                                                            ? "DESC"
                                                                                                            : "ASC"
                                                                                                    )
                                                                                                } else {
                                                                                                    setSortColumn(
                                                                                                        "DATE"
                                                                                                    )
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            {sortColumn === "DATE" ? (
                                                                                                sortType === "ASC" ? (
                                                                                                    <ArrowDownward />
                                                                                                ) : (
                                                                                                    <ArrowUpward />
                                                                                                )
                                                                                            ) : (
                                                                                                <UnfoldMore />
                                                                                            )}
                                                                                        </IconButton>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {sortColumn === "NAME" ? (
                                                                                    fileList
                                                                                        .sort((a, b) => {
                                                                                            if (
                                                                                                a.fileName < b.fileName
                                                                                            ) {
                                                                                                return sortType ===
                                                                                                    "ASC"
                                                                                                    ? -1
                                                                                                    : 1
                                                                                            }
                                                                                            if (
                                                                                                a.fileName > b.fileName
                                                                                            ) {
                                                                                                return sortType ===
                                                                                                    "ASC"
                                                                                                    ? 1
                                                                                                    : -1
                                                                                            }
                                                                                            return 0
                                                                                        })
                                                                                        .map((file) => (
                                                                                            <tr
                                                                                                key={
                                                                                                    file.fieldingRequestMarkerAttachmentId ||
                                                                                                    file.projectAttachmentId ||
                                                                                                    file.fieldingRequestAttachmentId
                                                                                                }
                                                                                            >
                                                                                                <td>
                                                                                                    <Checkbox
                                                                                                        checked={Boolean(
                                                                                                            selectedFile.find(
                                                                                                                (
                                                                                                                    idx: string
                                                                                                                ) =>
                                                                                                                    toolIdSelected !==
                                                                                                                    ""
                                                                                                                        ? idx ===
                                                                                                                          file?.fieldingRequestMarkerAttachmentId
                                                                                                                        : idx ===
                                                                                                                          file?.fieldingRequestAttachmentId
                                                                                                            )
                                                                                                        )}
                                                                                                        onClick={() =>
                                                                                                            handleSelect(
                                                                                                                toolIdSelected !==
                                                                                                                    ""
                                                                                                                    ? file?.fieldingRequestMarkerAttachmentId
                                                                                                                    : file?.fieldingRequestAttachmentId
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                                <td>{file.fileName}</td>
                                                                                                <td>{file.fileSize}</td>
                                                                                                <td>
                                                                                                    {
                                                                                                        file.uploadDateString
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                ) : sortColumn === "SIZE" ? (
                                                                                    fileList
                                                                                        .sort((a, b) => {
                                                                                            if (
                                                                                                a.fileSize < b.fileSize
                                                                                            ) {
                                                                                                return sortType ===
                                                                                                    "ASC"
                                                                                                    ? -1
                                                                                                    : 1
                                                                                            }
                                                                                            if (
                                                                                                a.fileSize > b.fileSize
                                                                                            ) {
                                                                                                return sortType ===
                                                                                                    "ASC"
                                                                                                    ? 1
                                                                                                    : -1
                                                                                            }
                                                                                            return 0
                                                                                        })
                                                                                        .map((file) => (
                                                                                            <tr
                                                                                                key={
                                                                                                    file.fieldingRequestMarkerAttachmentId ||
                                                                                                    file.projectAttachmentId ||
                                                                                                    file.fieldingRequestAttachmentId
                                                                                                }
                                                                                            >
                                                                                                <td>
                                                                                                    <Checkbox
                                                                                                        checked={Boolean(
                                                                                                            selectedFile.find(
                                                                                                                (
                                                                                                                    idx: string
                                                                                                                ) =>
                                                                                                                    toolIdSelected !==
                                                                                                                    ""
                                                                                                                        ? idx ===
                                                                                                                          file?.fieldingRequestMarkerAttachmentId
                                                                                                                        : idx ===
                                                                                                                          file?.fieldingRequestAttachmentId
                                                                                                            )
                                                                                                        )}
                                                                                                        onClick={() =>
                                                                                                            handleSelect(
                                                                                                                toolIdSelected !==
                                                                                                                    ""
                                                                                                                    ? file?.fieldingRequestMarkerAttachmentId
                                                                                                                    : file?.fieldingRequestAttachmentId
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                                <td>{file.fileName}</td>
                                                                                                <td>{file.fileSize}</td>
                                                                                                <td>
                                                                                                    {
                                                                                                        file.uploadDateString
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                ) : sortColumn === "DATE" ? (
                                                                                    fileList
                                                                                        .sort((a, b) => {
                                                                                            if (
                                                                                                a.uploadDate <
                                                                                                b.uploadDate
                                                                                            ) {
                                                                                                return sortType ===
                                                                                                    "ASC"
                                                                                                    ? -1
                                                                                                    : 1
                                                                                            }
                                                                                            if (
                                                                                                a.uploadDate >
                                                                                                b.uploadDate
                                                                                            ) {
                                                                                                return sortType ===
                                                                                                    "ASC"
                                                                                                    ? 1
                                                                                                    : -1
                                                                                            }
                                                                                            return 0
                                                                                        })
                                                                                        .map((file) => (
                                                                                            <tr
                                                                                                key={
                                                                                                    file.fieldingRequestMarkerAttachmentId ||
                                                                                                    file.projectAttachmentId
                                                                                                }
                                                                                            >
                                                                                                <td>
                                                                                                    <Checkbox
                                                                                                        checked={Boolean(
                                                                                                            selectedFile.find(
                                                                                                                (
                                                                                                                    idx: string
                                                                                                                ) =>
                                                                                                                    toolIdSelected !==
                                                                                                                    ""
                                                                                                                        ? idx ===
                                                                                                                          file?.fieldingRequestMarkerAttachmentId
                                                                                                                        : idx ===
                                                                                                                          file?.fieldingRequestAttachmentId
                                                                                                            )
                                                                                                        )}
                                                                                                        onClick={() =>
                                                                                                            handleSelect(
                                                                                                                toolIdSelected !==
                                                                                                                    ""
                                                                                                                    ? file?.fieldingRequestMarkerAttachmentId
                                                                                                                    : file?.fieldingRequestAttachmentId
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                                <td>{file.fileName}</td>
                                                                                                <td>{file.fileSize}</td>
                                                                                                <td>
                                                                                                    {
                                                                                                        file.uploadDateString
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                flexWrap: "wrap",
                                                                                padding: "10px",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                position: "relative",
                                                                                paddingTop: "40px"
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    position: "absolute",
                                                                                    top: 0,
                                                                                    left: "10px",
                                                                                    display: "flex"
                                                                                }}
                                                                            >
                                                                                <Checkbox
                                                                                    checked={Boolean(
                                                                                        selectedFile.length ===
                                                                                            fileList.length
                                                                                    )}
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            selectedFile.length ===
                                                                                            fileList.length
                                                                                        )
                                                                                            setSelectedFile([])
                                                                                        else
                                                                                            setSelectedFile(
                                                                                                fileList.map(
                                                                                                    (list: any) =>
                                                                                                        toolIdSelected !==
                                                                                                        ""
                                                                                                            ? list?.fieldingRequestMarkerAttachmentId
                                                                                                            : list?.fieldingRequestAttachmentId
                                                                                                )
                                                                                            )
                                                                                    }}
                                                                                />
                                                                                <p
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            selectedFile.length ===
                                                                                            fileList.length
                                                                                        )
                                                                                            setSelectedFile([])
                                                                                        else
                                                                                            setSelectedFile(
                                                                                                fileList.map(
                                                                                                    (list: any) =>
                                                                                                        toolIdSelected !==
                                                                                                        ""
                                                                                                            ? list?.fieldingRequestMarkerAttachmentId
                                                                                                            : list?.fieldingRequestAttachmentId
                                                                                                )
                                                                                            )
                                                                                    }}
                                                                                >
                                                                                    Select All
                                                                                </p>
                                                                            </div>
                                                                            {fileList.map((file) => (
                                                                                <div
                                                                                    key={
                                                                                        file.fieldingRequestMarkerAttachmentId ||
                                                                                        file.projectAttachmentId
                                                                                    }
                                                                                    style={{
                                                                                        width: "90px",
                                                                                        height: "90px",
                                                                                        position: "relative",
                                                                                        // padding: "2px",
                                                                                        margin: "4px",
                                                                                        border: "1px solid lightgray",
                                                                                        overflow: "hidden",
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        handleSelect(
                                                                                            toolIdSelected !== ""
                                                                                                ? file?.fieldingRequestMarkerAttachmentId
                                                                                                : file?.fieldingRequestAttachmentId
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Checkbox
                                                                                        checked={Boolean(
                                                                                            selectedFile.find(
                                                                                                (idx: string) =>
                                                                                                    toolIdSelected !==
                                                                                                    ""
                                                                                                        ? idx ===
                                                                                                          file?.fieldingRequestMarkerAttachmentId
                                                                                                        : idx ===
                                                                                                          file?.fieldingRequestAttachmentId
                                                                                            )
                                                                                        )}
                                                                                        onClick={() =>
                                                                                            handleSelect(
                                                                                                toolIdSelected !== ""
                                                                                                    ? file?.fieldingRequestMarkerAttachmentId
                                                                                                    : file?.fieldingRequestAttachmentId
                                                                                            )
                                                                                        }
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            top: "-8px",
                                                                                            left: "-8px"
                                                                                        }}
                                                                                    />
                                                                                    {file.filePath
                                                                                        .split(".")
                                                                                        .slice(-1)[0]
                                                                                        .toLocaleLowerCase() ===
                                                                                        "png" ||
                                                                                    file.filePath
                                                                                        .split(".")
                                                                                        .slice(-1)[0]
                                                                                        .toLocaleLowerCase() ===
                                                                                        "jpg" ||
                                                                                    file.filePath
                                                                                        .split(".")
                                                                                        .slice(-1)[0]
                                                                                        .toLocaleLowerCase() ===
                                                                                        "jpeg" ||
                                                                                    file.filePath
                                                                                        .split(".")
                                                                                        .slice(-1)[0]
                                                                                        .toLocaleLowerCase() ===
                                                                                        "webp" ? (
                                                                                        <div
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                height: "50px",
                                                                                                overflow: "hidden"
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                src={
                                                                                                    file.filePath +
                                                                                                    "?width=100"
                                                                                                }
                                                                                                style={{
                                                                                                    width: "100%"
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    ) : (
                                                                                        <p
                                                                                            style={{
                                                                                                fontSize: "18px",
                                                                                                fontWeight: "bold"
                                                                                            }}
                                                                                        >
                                                                                            {file.filePath
                                                                                                .split(".")
                                                                                                .slice(-1)[0]
                                                                                                .toUpperCase()}
                                                                                            {/* <IconFlie /> */}
                                                                                        </p>
                                                                                    )}
                                                                                    <p className="filename-wrap">
                                                                                        {file.fileName
                                                                                            .split("")
                                                                                            .map((t, i) =>
                                                                                                i == 12
                                                                                                    ? "..."
                                                                                                    : i < 12
                                                                                                    ? t
                                                                                                    : ""
                                                                                            )}
                                                                                    </p>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </Box>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </Box>
                                    </CardContent>
                                    <CardActions style={{ borderTop: "1px solid #ddd" }}>
                                        {width < 900 ? "" : <div style={{ width: "49%" }} />}
                                        <div style={{ width: width < 900 ? "100%" : "51%", position: "relative" }}>
                                            {fieldingIdSelected !== "" && fileList.length > 0 && (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        left: "1px",
                                                        top: "-8px",
                                                        display: "flex",
                                                        zIndex: 10,
                                                        cursor: "pointer",
                                                        borderRadius: "3px",
                                                        border: "1px solid lightgray"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            paddingTop: "4px",
                                                            width: "40px",
                                                            textAlign: "center",
                                                            background: typeList === "list" ? "#093a93" : "white",
                                                            color: typeList === "list" ? "white" : "dimgray"
                                                        }}
                                                        onClick={() => setTypeList("list")}
                                                    >
                                                        <ListIcon />
                                                    </div>
                                                    <div
                                                        style={{
                                                            paddingTop: "4px",
                                                            width: "40px",
                                                            textAlign: "center",
                                                            background: typeList === "img" ? "#093a93" : "white",
                                                            color: typeList === "img" ? "white" : "dimgray"
                                                        }}
                                                        onClick={() => setTypeList("img")}
                                                    >
                                                        <Image />
                                                    </div>
                                                </div>
                                            )}
                                            <Box
                                                margin={1}
                                                display={"flex"}
                                                flex={1}
                                                flexDirection={"row"}
                                                justifyContent={"flex-end"}
                                            >
                                                {/* <Typography>Sort through images you no longer needed</Typography> */}
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    disabled={Boolean(selectedFile.length === 0)}
                                                    onClick={() => handleDeleteSelected()}
                                                >
                                                    Delete
                                                </Button>
                                            </Box>
                                        </div>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                }
            />
            <Typography>
                {storageDetails.totalStorageUsed}
                <IconButton
                    color="inherit"
                    onClick={() => setStatusModal(true)}
                    sx={{ textWrap: "nowrap", paddingLeft: 0 }}
                >
                    <Typography sx={{ marginRight: "4px" }}>Storage Detail</Typography>
                    <OpenInNew />
                </IconButton>
            </Typography>
            {storageDetails && (
                <LinearProgress
                    sx={{ height: 5 }}
                    color="inherit"
                    variant="determinate"
                    value={storageDetails.totalDocumentSizePercentage + storageDetails.totalImageSizePercentage}
                />
            )}
        </Fragment>
    )
}
