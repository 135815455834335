import { Box, Button, Card, CardContent, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import CustomModal from "../../../shared/customModal/CustomModal";
import FieldingProgress from "./FieldingProgress";
import { InterfaceFieldingDetail } from "../fielding.interface";
import FieldingDetailEnd from "./FieldingDetailEnd";
import { useDispatch } from "react-redux";
import { fieldingComplete } from "../fielding.api";
import Swal from "sweetalert2";

export default function FieldingCompleteSequence(props: {
    details: InterfaceFieldingDetail,
    complete: boolean,
    continueLater: boolean,
    onDone: () => void,
    onCancel: () => void
}) {

    const dispatch = useDispatch<any>()

    const [step, setStep] = useState(0)

    useEffect(() => {
        if (!props.complete && !props.continueLater) {
            setStep(0)
        }
    }, [props.complete, props.continueLater])

    useEffect(() => {
        if (props.complete || props.continueLater) {
            setStep(1)
        }
    }, [props.complete, props.continueLater])

    const handleSubmitEnd = async () => {
        if (props.complete) {
            await dispatch(fieldingComplete(props.details.id))
            Swal.fire({
                icon: "success",
                title: "Fielding Completed"
            })
                .then(() => {
                    props.onDone()
                })
        }
        else {
            props.onDone()
        }
    }

    if (!props.complete && !props.continueLater) {
        return <></>
    }

    return (
        <Fragment>
            <Modal open={step === 1}>
                <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
                    <Card>
                        <CardContent>
                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={2}>
                                <Typography variant="h6">Please make sure to update fielding progress</Typography>
                                <Button variant="contained" onClick={() => setStep(2)}>Ok</Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
            <CustomModal
                open={step === 2}
                onClose={props.onCancel}
                title="Update Progress"
                styles={{ width: "90%", maxWidth: "700px" }}
                component={
                    <FieldingProgress
                        details={props.details}
                        isComplete={props.complete}
                        onClose={props.onCancel}
                        onUpdateData={() => setStep(3)}
                    />
                }
            />
            <CustomModal
                open={step === 3}
                onClose={props.onCancel}
                title="Travel Confirmation"
                component={<FieldingDetailEnd details={props.details} isContinueLater={props.continueLater} onCancel={props.onDone} onConfirm={handleSubmitEnd} />}
            />
        </Fragment>
    )
}