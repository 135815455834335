import { Box, Button, Card, CardContent, Checkbox, Grid, IconButton, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { FormEvent, Fragment, useState } from "react";
import icon from "../../../assets/images/icon.png";
import CustomLoading from "../../../shared/CustomLoading";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accountSendVerification, createCompany } from "../auth.api";
import { AuthCompanyInitialCreate, AuthCompanyInterfaceCreate } from "../auth.interface";
import notificationError from "../../../shared/notificationError";
import notificationSuccess from "../../../shared/notificationSuccess";
import disclaimerPdf from "../../../assets/Disclaimer.pdf";
import { Document, Page, pdfjs } from 'react-pdf';
import ButtonOutlined from "../../../shared/ButtonOutlined";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SignUp() {

    const navigate = useNavigate()
    const dispatch = useDispatch<any>()

    const [values, setValues] = useState<AuthCompanyInterfaceCreate>(AuthCompanyInitialCreate)
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
    const [step, setStep] = useState<0 | 1 | 2>(0)
    const [isAddressTwo, setIsAddressTwo] = useState(false)
    const [numOfPages, setNumOfPages] = useState(0)
    const [isAgree, setIsAgree] = useState(false)

    const handleChangeValues = (e: any) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        dispatch(createCompany(values))
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(accountSendVerification(values.email))
                        .unwrap()
                        .then((response: any) => {
                            if (response.status === 200) {
                                notificationSuccess("Check your email for verification")
                                    .then(() => {
                                        navigate("/signin")
                                    })
                            }
                            else {
                                notificationError(response)
                            }
                        })
                }
                else {
                    notificationError(response)
                }
            })
    }

    return (
        <div className="signin">
            <CustomLoading />
            <img src={icon} alt="icon" height={128} width={128} />
            <br />
            <br />
            <Card elevation={20} sx={{ width: "50vw" }}>
                <CardContent className="">
                    <Typography variant="h5">Create Company Account</Typography>
                    <br />
                    {step === 0
                        ? (
                            <form onSubmit={(e) => { e.preventDefault(); setStep(1) }}>
                                <Grid container spacing={2}>
                                    <Grid item md={12}>
                                        <TextField margin="none" fullWidth onChange={handleChangeValues} required label="Company Name" name="name" />
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField margin="none" fullWidth onChange={handleChangeValues} required label="Primary Name" name="firstName" />
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField margin="none" fullWidth onChange={handleChangeValues} required label="Email" name="email" />
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField margin="none" fullWidth onChange={handleChangeValues} required label="Phone" name="phone" type="tel" style={{ color: "red !important" }} />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField margin="none" fullWidth onChange={handleChangeValues} required label="Street Address" name="suite" />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField margin="none" fullWidth onChange={handleChangeValues} required label="Suite or Unit Number" name="unitNumber" />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField margin="none" fullWidth onChange={handleChangeValues} required label="City" name="city" />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField margin="none" fullWidth onChange={handleChangeValues} required label="Zip Code" name="zipCode" />
                                    </Grid>
                                    {isAddressTwo
                                        ? (
                                            <Fragment>
                                                <Grid item md={6}>
                                                    <TextField margin="none" fullWidth onChange={handleChangeValues} required label="Street Address" name="suite2" />
                                                </Grid>
                                                <Grid item md={6}>
                                                    <TextField margin="none" fullWidth onChange={handleChangeValues} required label="Suite or Unit Number" name="unitNumber2" />
                                                </Grid>
                                                <Grid item md={6}>
                                                    <TextField margin="none" fullWidth onChange={handleChangeValues} required label="City" name="city2" />
                                                </Grid>
                                                <Grid item md={6}>
                                                    <TextField margin="none" fullWidth onChange={handleChangeValues} required label="Zip Code" name="zipCode2" />
                                                </Grid>
                                            </Fragment>
                                        )
                                        : (
                                            <Grid item md={12}>
                                                <Box display={"flex"} justifyContent={"flex-end"}>
                                                    <ButtonOutlined onClick={() => setIsAddressTwo(true)}>Add adress</ButtonOutlined>
                                                </Box>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <br />
                                <Button type="submit" variant="contained">Next</Button>
                            </form>
                        )
                        : step === 1
                            ? (
                                <Modal
                                    open={true}
                                    children={(
                                        <Box height={"100vh"} overflow={"auto"} bgcolor={"white"}>
                                            <Box
                                                marginTop={10}
                                                marginBottom={-100}
                                            >
                                                <Document
                                                    file={disclaimerPdf}
                                                    onLoadSuccess={(doc) => setNumOfPages(doc.numPages)}
                                                    renderMode='svg'
                                                >
                                                    {Array.from(new Array(numOfPages), (el, index) => (
                                                        <Page
                                                            renderTextLayer={false}
                                                            renderAnnotationLayer={false}
                                                            key={`page_${index + 1}`}
                                                            pageNumber={index + 1}
                                                            width={window.innerWidth * 0.9}

                                                        />
                                                    ))}
                                                </Document>
                                            </Box>
                                            <Box
                                                display={"flex"}
                                                flexDirection={"column"}
                                                justifyContent={"center"}
                                                alignItems={"center"}
                                                position={"relative"}
                                                marginTop={30}
                                            >
                                                <Box>
                                                    <Checkbox onChange={(e) => setIsAgree(e.target.checked)} />
                                                    <span>I understand and Agree to the Terms and Conditions, including the <a href="/#/terms" target="_blank">User Agreement</a> and <a href="/#/privacy" target="_blank">Privacy Policy</a></span>
                                                </Box>
                                                <br />
                                                <Box
                                                    display={"flex"}
                                                    flexDirection={"row"}
                                                >
                                                    <ButtonOutlined onClick={() => setStep(0)}>Back</ButtonOutlined>
                                                    <Box width={3}></Box>
                                                    <Button variant="contained" disabled={!isAgree} onClick={() => setStep(2)}>Next</Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                />
                            )
                            : step === 2
                                ? (
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            onChange={handleChangeValues}
                                            type={showPassword ? "text" : "password"}
                                            required
                                            label="Password"
                                            name="password"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <TextField
                                            margin="dense"
                                            fullWidth
                                            onChange={handleChangeValues}
                                            type={showPasswordConfirm ? "text" : "password"}
                                            required
                                            label="Password Confirmation"
                                            name="passwordConfirm"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                                        >
                                                            {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <br />
                                        <br />
                                        <Grid container>
                                            <Grid item md={6}>
                                                <ButtonOutlined onClick={() => setStep(1)}>Back</ButtonOutlined>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Button fullWidth type="submit" variant="contained">Sign Up</Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )
                                : ""
                    }
                    <br />
                    Already have an account? <Button variant="text" onClick={() => navigate("/signin")}>Login</Button>
                </CardContent>
            </Card>
        </div>
    )
}