import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../state';
import { userInfo } from '../../shared/utils';
import {
  InterfaceFieldingAssignFtr,
  InterfaceFieldingEdit,
  InterfaceFieldingMarkerAdd,
  InterfaceFieldingNoteCommentCreate,
  InterfaceFieldingNoteCreate,
} from './fielding.interface';
import handleError from '../../shared/handleError';
import { InterfaceUpdateProgress } from '../project/project.interface';
import moment from 'moment';

export const fieldingEdit = createAsyncThunk(
  'fieldingEdit',
  async (data: InterfaceFieldingEdit) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/Project/FPEditProjectFieldingRequest',
        data: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingTypeCreate = createAsyncThunk(
  'fieldingTypeCreate',
  async (name: string) => {
    try {
      const response = await axios({
        method: 'post',
        url:
          baseUrl + '/newapi/FieldingRequest/FPCreateCustomFieldingRequestType',
        data: {
          token: userInfo().token,
          companyId: userInfo().user.companyId,
          name: name,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingTypeDelete = createAsyncThunk(
  'fieldingTypeDelete',
  async (id: string) => {
    try {
      const response = await axios({
        method: 'delete',
        url:
          baseUrl + '/newapi/FieldingRequest/FPDeleteCustomFieldingRequestType',
        params: {
          token: userInfo().token,
          fieldingRequestCustomTypeId: id,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingTypeGet = createAsyncThunk('fieldingTypeGet', async () => {
  try {
    const response = await axios({
      method: 'get',
      url: baseUrl + '/newapi/FieldingRequest/FPGetCustomFieldingRequestTypes',
      params: {
        token: userInfo().token,
        companyId: userInfo().user.companyId,
      },
    });
    return response;
  } catch (error: any) {
    handleError(error);
    return error.response;
  }
});

export const fieldingDetailsGet = createAsyncThunk(
  'fieldingDetailsGet',
  async (id: string) => {
    try {
      const response = await axios({
        method: 'get',
        url: baseUrl + '/newapi/FieldingRequest/GetDetails',
        params: {
          token: userInfo().token,
          fieldingRequestId: id,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingAssignedGet = createAsyncThunk(
  'fieldingAssignedGet',
  async (status: number) => {
    try {
      const response = await axios({
        method: 'post',
        url:
          baseUrl +
          '/newapi/FieldingRequest/GetAssignedFieldingRequestGroupedByJob',
        data: {
          token: userInfo().token,
          fieldingRequestStatus: status,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingAssignedActiveGet = createAsyncThunk(
  'fieldingAssignedActiveGet',
  async () => {
    try {
      const response = await axios({
        method: 'get',
        url:
          baseUrl +
          '/newapi/FieldingRequest/GetUserAssignedActiveFieldingRequestList',
        params: {
          token: userInfo().token,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingComplete = createAsyncThunk(
  'fieldingComplete',
  async (targetFieldingRequestId: string) => {
    try {
      const response = await axios({
        method: 'put',
        url: baseUrl + '/newapi/FieldingRequest/FPCompleteFieldingRequest',
        params: {
          token: userInfo().token,
          targetFieldingRequestId: targetFieldingRequestId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingReactive = createAsyncThunk(
  'fieldingReactive',
  async (targetFieldingRequestId: string) => {
    try {
      const response = await axios({
        method: 'put',
        url: baseUrl + '/newapi/FieldingRequest/ReactiveFielding',
        params: {
          token: userInfo().token,
          targetFieldingRequestId: targetFieldingRequestId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingMarkerStart = createAsyncThunk(
  'fieldingMarkerStart',
  async (fieldingRequestMarkerId: string) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/FieldingRequestMarker/StartFielding',
        data: {
          token: userInfo().token,
          fieldingRequestMarkerId: fieldingRequestMarkerId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingMarkerComplete = createAsyncThunk(
  'fieldingMarkerComplete',
  async (fieldingRequestMarkerId: string) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/FieldingRequestMarker/CompleteFielding',
        data: {
          token: userInfo().token,
          fieldingRequestMarkerId: fieldingRequestMarkerId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingAttachmentDelete = createAsyncThunk(
  'fieldingAttachmentDelete',
  async (fieldingRequestAttachmentId: string) => {
    try {
      const response = await axios({
        method: 'delete',
        url:
          baseUrl + '/newapi/FieldingRequest/DeleteFieldingRequestAttachment',
        params: {
          token: userInfo().token,
          fieldingRequestAttachmentId: fieldingRequestAttachmentId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingMarkerAttachmentDelete = createAsyncThunk(
  'fieldingMarkerAttachmentDelete',
  async (fieldingRequestMarkerAttachmentId: string) => {
    try {
      const response = await axios({
        method: 'delete',
        url:
          baseUrl +
          '/newapi/FieldingRequestMarker/DeleteFieldingRequestMarkerAttachment',
        params: {
          token: userInfo().token,
          fieldingRequestMarkerAttachmentId: fieldingRequestMarkerAttachmentId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const deleteAttachment = createAsyncThunk(
  'deleteAttachment',
  async (attachmentId: string) => {
    try {
      const response = await axios({
        method: 'delete',
        url:
          baseUrl +
          '/newapi/Project/DeleteAttachment',
        params: {
          token: userInfo().token,
          attachmentId: attachmentId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const requestDeleteAttachment = createAsyncThunk(
  'requestDeleteAttachment',
  async (attachmentId: string) => {
    try {
      const response = await axios({
        method: 'put',
        url:
          baseUrl +
          '/newapi/Project/RequestDeleteAttachment',
        params: {
          token: userInfo().token,
          attachmentId: attachmentId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const FieldingMarkerDefaultTimeOffsetGetFieldingId = createAsyncThunk(
  'FieldingMarkerDefaultTimeOffsetGetFieldingId',
  async (data: { token: string; fieldingRequestId: string }) => {
    try {
      const response = await axios({
        method: 'get',
        url:
          baseUrl +
          '/newapi/FieldingRequestMarker/GetDefaultTimeoffsetByFieldingRequestId',
        params: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const FieldingMarkerDefaultTimeOffsetGetProjectId = createAsyncThunk(
  'FieldingMarkerDefaultTimeOffsetGetProjectId',
  async (data: { token: string; projectId: string }) => {
    try {
      const response = await axios({
        method: 'get',
        url:
          baseUrl +
          '/newapi/FieldingRequestMarker/GetDefaultTimeoffsetByProjectId',
        params: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const FieldingNameGetByProjectId = createAsyncThunk(
  'FieldingNameGetByProjectId',
  async (projectId: string) => {
    try {
      const response = await axios({
        method: 'get',
        url: baseUrl + '/newapi/FieldingRequest/FPGetListByProjectId',
        params: {
          token: userInfo().token,
          projectId: projectId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const FieldingNameGetByFieldingId = createAsyncThunk(
  'FieldingNameGetByFieldingId',
  async (fieldingRequestId: string) => {
    try {
      const response = await axios({
        method: 'get',
        url: baseUrl + '/newapi/fieldingrequestmarker/GetListByFieldingRequestId',
        params: {
          token: userInfo().token,
          fieldingRequestId: fieldingRequestId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingMarkerAdd = createAsyncThunk(
  'fieldingMarkerAdd',
  async (data: InterfaceFieldingMarkerAdd) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/api/FieldingRequestMarker/Create',
        data: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingNoteCreate = createAsyncThunk(
  'fieldingNoteCreate',
  async (data: InterfaceFieldingNoteCreate) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/FieldingRequest/FPCreateFieldingRequestNote',
        data: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingSubmitProgress = createAsyncThunk(
  'fieldingSubmitProgress',
  async (data: Array<InterfaceUpdateProgress>) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/FieldingRequest/FPUpdateFieldingRequestMarkerProgress',
        data: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingNoteCommentCreate = createAsyncThunk(
  'fieldingNoteCommentCreate',
  async (data: InterfaceFieldingNoteCommentCreate) => {
    try {
      const response = await axios({
        method: 'post',
        url:
          baseUrl +
          '/newapi/FieldingRequest/FPCreateFieldingRequestNoteComment',
        data: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingAssignFtr = createAsyncThunk(
  'fieldingAssignFtr',
  async (data: InterfaceFieldingAssignFtr) => {
    try {
      const response = await axios({
        method: 'post',
        url: baseUrl + '/newapi/FieldingRequest/FPAssignFTRFielder',
        data: data,
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingAssignFtrAccept = createAsyncThunk(
  'fieldingAssignFtrAccept',
  async (targetFieldingRequestId: string) => {
    try {
      const response = await axios({
        method: 'put',
        url: baseUrl + '/newapi/FieldingRequest/FPAcceptFTRFielderNotification',
        params: {
          token: userInfo().token,
          targetFieldingRequestId: targetFieldingRequestId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingAssignFtrDecline = createAsyncThunk(
  'fieldingAssignFtrDecline',
  async (targetFieldingRequestId: string) => {
    try {
      const response = await axios({
        method: 'put',
        url:
          baseUrl + '/newapi/FieldingRequest/FPDeclineFTRFielderNotification',
        params: {
          token: userInfo().token,
          targetFieldingRequestId: targetFieldingRequestId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingGetNotification = createAsyncThunk(
  'fieldingGetNotification',
  async () => {
    try {
      const response = await axios({
        method: 'get',
        url:
          baseUrl +
          '/newapi/FieldingRequest/GetUserAssignedFieldingRequestNotifications',
        params: {
          token: userInfo().token,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingReadNotification = createAsyncThunk(
  'fieldingReadNotification',
  async () => {
    try {
      const response = await axios({
        method: 'put',
        url:
          baseUrl +
          '/newapi/FieldingRequest/ReadUserAssignedFieldingRequestNotifications',
        params: {
          token: userInfo().token,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingClearNotification = createAsyncThunk(
  'fieldingClearNotification',
  async () => {
    try {
      const response = await axios({
        method: 'put',
        url:
          baseUrl +
          '/newapi/FieldingRequest/ClearUserAssignedFieldingRequestNotifications',
        params: {
          token: userInfo().token,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const fieldingDelete = createAsyncThunk(
  'fieldingDelete',
  async (id: string) => {
    try {
      const response = await axios({
        method: 'delete',
        url:
          baseUrl + '/api/FieldingRequest/Delete',
        params: {
          token: userInfo().token,
          targetFieldingRequestId: id,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const createDownloadLog = createAsyncThunk(
  'createDownloadLog',
  async (fieldingRequestId: string) => {
    try {
      const response = await axios({
        method: 'put',
        url:
          baseUrl + '/newapi/FieldingRequest/CreateDownloadLog',
        params: {
          token: userInfo().token,
          targetFieldingRequestId: fieldingRequestId,
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const createTravelLog = createAsyncThunk(
  'createTravelLog',
  async (data: {
    downloadLogId: string,
    miles: number,
    claimerId: string,
    initialLocation: string,
    nextLocation: string,
    isContinueLater: boolean
  }) => {
    try {
      const response = await axios({
        method: 'post',
        url:
          baseUrl + '/newapi/FieldingRequest/CreateTravelLog',
        data: {
          token: userInfo().token,
          downloadLogId: data.downloadLogId,
          travelLogs: [{
            miles: data.miles,
            dateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
            timeOffset: new Date().getTimezoneOffset(),
            teamIdList: localStorage.getItem("startMateIdList") ? JSON.parse(localStorage.getItem("startMateIdList")!) : [],
            claimerId: data.claimerId,
            initialLocation: data.initialLocation,
            nextLocation: data.nextLocation
          }],
          isContinueLater: data.isContinueLater
        },
      });
      return response;
    } catch (error: any) {
      handleError(error);
      return error.response;
    }
  }
);

export const startFielding = createAsyncThunk('startFielding', async (fieldingRequestId: string) => {
  try {
    const response = await axios({
      method: 'post',
      url: baseUrl + '/newapi/FieldingRequest/StartFielding',
      params: {
        token: userInfo().token,
        fieldingRequestId: fieldingRequestId,
      }
    })
    return response;
  }
  catch (error: any) {
    handleError(error);
    return error.response;
  }
})