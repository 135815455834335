import { Box, Card, CardContent, Typography } from "@mui/material";

export default function DashboardHeader() {
    return (
        <Card variant="outlined">
            <CardContent>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant="h5" fontWeight={"bold"}>Dashboard</Typography>
                </Box>
            </CardContent>
        </Card>
    )
}