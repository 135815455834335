import { Box, Button, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { InterfaceFieldingMarker } from "../../../fielding/fielding.interface";
import FieldingTypeForm from "../shared/FieldingTypeForm";
import ButtonOutlined from "../../../../shared/ButtonOutlined";

export default function ModalAddMarker(props: {
    value: InterfaceFieldingMarker,
    setValue: (params: InterfaceFieldingMarker | null) => void,
    onSubmit: () => void
}) {

    return (
        <Box minWidth={"25vw"}>
            <FieldingTypeForm
                readOnly={false}
                value={props.value.customTypeId || ""}
                onChange={(value) => props.setValue({ ...props.value, customTypeId: value })}
            />
            <Grid container spacing={2} padding={1}>
                <Grid item xs={6} md={3}>
                    <FormControlLabel control={<Checkbox checked={props.value?.requestedAmountType === 0} onChange={(e) => props.setValue({ ...props.value, requestedAmountType: 0 })} />} label="Footage" />
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormControlLabel control={<Checkbox checked={props.value?.requestedAmountType === 1} onChange={(e) => props.setValue({ ...props.value, requestedAmountType: 1 })} />} label="Quantity" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField margin="normal" label="amount" value={parseInt(props.value?.requestedFielding) || ""} onChange={(e) => props.setValue({ ...props.value, requestedFielding: String(parseInt(e.target.value)) || "0" })} />
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <ButtonOutlined fullWidth onClick={() => props.setValue(null)}>
                        Cancel
                    </ButtonOutlined>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={props.onSubmit}
                        disabled={
                            props.value?.requestedAmountType === null ||
                            props.value.customTypeId === null ||
                            props.value.customTypeId === "" ||
                            props.value.requestedFielding === "" ||
                            props.value.requestedFielding === "NaN"
                        }>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}