const baseUrl = "https://api.fielderpro.com"

const esriToken = "AAPK6ef8d0e1ff504df79f8fde10a4d699d5fOaHAJn5bzcrz5qxhdIIb1hYDau6fgROg9czK4UCGmavgv6yzjXEnCStbAHmTNCl"
const googleToken = "AIzaSyDQ-aesOz_ut1yBv_d6FG-0N60uqDwgLZs"
const colors = [
    "#138265",
    "#DC2336",
    "#EC3D84",
    "#4D3919",
    "#676858",
    "#4A4280",
    "#A31BFE",
    "#808751",
    "#9F1883",
    "#3F8926",
    "#1D3679",
    "#592DF6",
    "#76A730",
    "#6F0460"
]

export { baseUrl, esriToken, googleToken, colors }

// export NODE_OPTIONS=--max_old_space_size=4096
