import { Autocomplete, Box, Button, Container, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import TableHeader from "../../../shared/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setDataExpandedAddressList, setDataExpandedList, setParameterGetData } from "../project.reducer";
import roles from "../../../shared/roles";
import { InitialProjectDetail, InterfaceProjectDetail } from "../project.interface";
import { Fragment } from "react/jsx-runtime";
import { projectComplete, projectDelete, projectGet, projectGetDetail, projectMoveToActive, projectMoveToArchive } from "../project.api";
import useAddress from "../../../shared/useAddress";
import { Archive, Check, Circle, Delete, Edit, KeyboardArrowDown, KeyboardArrowUp, Map, Unarchive } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import notificationDelete from "../../../shared/notificationDelete";
import hot from "../../../assets/images/hot.png"
import ButtonOutlined from "../../../shared/ButtonOutlined";
import { fieldingComplete, fieldingReactive } from "../../fielding/fielding.api";
import Pagination from "../../../shared/Pagination"
import { useEffect } from "react";
import moment from "moment";

export default function ProjectListCompact() {

    const dispatch = useDispatch<any>()
    const address = useAddress()
    const navigate = useNavigate()

    const { parameterGetData, dataExpandedList, dataExpandedAddressList, dataList, dataTotal } = useSelector((state: RootState) => state.project)

    useEffect(() => {
        dispatch(projectGet(parameterGetData))
    }, [dispatch, parameterGetData])

    const fillAddress = async (data: InterfaceProjectDetail) => {
        const addressToAdd = await address.fillAddressMultiple(
            data.projectFieldingRequests
                .filter(
                    (fielding) =>
                        !dataExpandedAddressList.map((address) => address.id).includes(fielding.fieldingRequestId)
                )
                .map((fieldingRequest) => {
                    return {
                        id: fieldingRequest.fieldingRequestId,
                        markers: fieldingRequest.fieldingRequestMarkers.map((marker) => {
                            return {
                                ...marker,
                                lineData: JSON.parse(marker.lineData),
                                shapeData: JSON.parse(marker.shapeData)
                            }
                        })
                    }
                }),
            true
        )
        dispatch(setDataExpandedAddressList([...dataExpandedAddressList, ...addressToAdd]))
    }

    const handleExpand = async (id: string | undefined, reload: boolean = false) => {
        if (!reload && dataExpandedList.filter((data) => data.id === id).length > 0) {
            let filtered = dataExpandedList.filter((data) => data.id !== id)
            await dispatch(setDataExpandedList(filtered))
        } else {
            if (id)
                await dispatch(projectGetDetail(id))
                    .unwrap()
                    .then(async (response: any) => {
                        let data: InterfaceProjectDetail = response.data.data
                        dispatch(
                            setDataExpandedList([
                                ...dataExpandedList.filter((data) => data.id !== id),
                                response.data.data
                            ])
                        )
                        fillAddress(data)
                    })
        }
    }

    const handleDelete = (id: string) => {
        notificationDelete().then((isConfirmed) => {
            if (isConfirmed) {
                dispatch(projectDelete(id))
                    .unwrap()
                    .then(() => {
                        dispatch(projectGet(parameterGetData))
                    })
            }
        })
    }

    const handleComplete = (projectId: string) => {
        dispatch(projectComplete(projectId))
            .unwrap()
            .then(() => {
                dispatch(projectGet(parameterGetData))
            })
    }

    const handleToActive = (projectId: string) => {
        dispatch(projectMoveToActive(projectId))
            .unwrap()
            .then(() => {
                dispatch(projectGet(parameterGetData))
            })
    }

    const handleToArchive = (projectId: string) => {
        dispatch(projectMoveToArchive(projectId))
            .unwrap()
            .then(() => {
                dispatch(projectGet(parameterGetData))
            })
    }

    const handleCompleteFielding = (projectId: string, id: string) => {
        dispatch(fieldingComplete(id))
            .unwrap()
            .then(() => {
                dispatch(projectGetDetail(projectId))
                    .unwrap()
                    .then((response: any) => {
                        dispatch(
                            setDataExpandedList([
                                ...dataExpandedList.filter((data) => data.id !== projectId),
                                response.data.data
                            ])
                        )
                    })
            })
    }

    const handleUncompleteFielding = (projectId: string, id: string) => {
        dispatch(fieldingReactive(id))
            .unwrap()
            .then(() => {
                dispatch(projectGetDetail(projectId))
                    .unwrap()
                    .then((response: any) => {
                        dispatch(
                            setDataExpandedList([
                                ...dataExpandedList.filter((data) => data.id !== projectId),
                                response.data.data
                            ])
                        )
                    })
            })
    }

    const fieldingColor = ["red", "blue", "green"]

    return (
        <Container maxWidth={"xl"} sx={{ overflow: "auto" }}>
            <table
                className="project custom-table"
                style={{
                    // tableLayout: "fixed",
                    borderCollapse: "collapse"
                }}
            >
                <thead>
                    <tr>
                        <th style={{ textAlign: "center" }}>
                            <TableHeader
                                name="Project"
                                field="projectNumber"
                                fieldSelected={parameterGetData.sortField}
                                sortTypeSelected={parameterGetData.sortType}
                                onAscending={() =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            sortField: "projectNumber",
                                            sortType: 0
                                        })
                                    )
                                }
                                onDescending={() =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            sortField: "projectNumber",
                                            sortType: 1
                                        })
                                    )
                                }
                            />
                        </th>
                        <th
                            className="text-nowrap"
                            style={{
                                textAlign: "center",
                                // display: "flex",
                                // flexWrap: "nowrap",
                                padding: "10px 0"
                            }}
                        >
                            <TableHeader
                                name="Project Customer Code"
                                field="customerCodeString"
                                fieldSelected={parameterGetData.sortField}
                                sortTypeSelected={parameterGetData.sortType}
                                onAscending={() =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            sortField: "customerCodeString",
                                            sortType: 0
                                        })
                                    )
                                }
                                onDescending={() =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            sortField: "customerCodeString",
                                            sortType: 1
                                        })
                                    )
                                }
                            />
                        </th>
                        <th style={{ textAlign: "center" }} colSpan={dataExpandedList.length > 0 ? 1 : 1}>
                            Location
                        </th>
                        <th style={{ textAlign: "center", paddingLeft: "24px", paddingRight: 0 }}>
                            <TableHeader
                                name="Due Date"
                                field="dueDateString"
                                fieldSelected={parameterGetData.sortField}
                                sortTypeSelected={parameterGetData.sortType}
                                onAscending={() =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            sortField: "dueDateString",
                                            sortType: 0
                                        })
                                    )
                                }
                                onDescending={() =>
                                    dispatch(
                                        setParameterGetData({
                                            ...parameterGetData,
                                            sortField: "dueDateString",
                                            sortType: 1
                                        })
                                    )
                                }
                            />
                        </th>
                        {dataExpandedList.length > 0 && <th></th>}
                        {roles("PROJECT_EDIT") && (
                            <th style={{ textAlign: "center" }} colSpan={dataExpandedList.length > 0 ? 2 : 1}>
                                Action
                            </th>
                        )}
                    </tr>
                    {/* <tr>
                    <th className="space" colSpan={8}></th>
                </tr> */}
                </thead>
                <tbody>
                    {dataList.map((data) => {
                        let detail: InterfaceProjectDetail = InitialProjectDetail
                        if (dataExpandedList.filter((dataExpanded) => dataExpanded.id === data.id).length > 0) {
                            detail = dataExpandedList.filter((dataExpanded) => dataExpanded.id === data.id)[0]
                        }
                        const fieldingRequestList =
                            parameterGetData.fielderIds.length > 0
                                ? detail.projectFieldingRequests.filter((fieldingRequest) =>
                                    fieldingRequest.fielderIds.some((fielderId) =>
                                        parameterGetData.fielderIds.includes(fielderId)
                                    )
                                )
                                : detail.projectFieldingRequests

                        return (
                            <Fragment key={data.id}>
                                <tr>
                                    <td
                                    // colSpan={dataExpandedList.length > 0 ? 2 : 1}
                                    >
                                        <Box display={"flex"}>
                                            <Tooltip title="List Field Request">
                                                <IconButton onClick={() => handleExpand(data.id)}>
                                                    {dataExpandedList.filter(
                                                        (dataExpanded) => dataExpanded.id === data.id
                                                    ).length > 0 ? (
                                                        <KeyboardArrowUp />
                                                    ) : (
                                                        <KeyboardArrowDown />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="New Field Request">
                                                <Button
                                                    onClick={() => navigate(`/project/detail?id=${data.id}`)}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        width: "-webkit-fill-available"
                                                    }}
                                                    color={data.isHotJob ? "error" : "primary"}
                                                >
                                                    <Box display={"flex"} alignItems={"center"} gap={1}>
                                                        <span style={{ textAlign: "left" }}>
                                                            {data.projectNumber}
                                                        </span>
                                                        {data.isHotJob && (
                                                            <img src={hot} alt="hot" style={{ width: "20px" }} />
                                                        )}
                                                    </Box>
                                                </Button>
                                            </Tooltip>
                                            <Typography
                                                variant="caption"
                                                fontStyle={"italic"}
                                                display={"flex"}
                                                alignItems={"center"}
                                                color={"grey"}
                                                sx={{
                                                    width: "-webkit-fill-available",
                                                    paddingLeft: dataExpandedList.length > 0 ? 2 : 1
                                                }}
                                            >
                                                Created by {data.createdByName} at {data.createdDateString}
                                            </Typography>
                                        </Box>
                                    </td>
                                    <td style={{ textAlign: "center", position: "relative", left: "-14px" }}>
                                        {data.customerCodeString}
                                    </td>
                                    <td
                                        colSpan={dataExpandedList.length > 0 ? 1 : 1}
                                        style={{ padding: "10px 0" }}
                                    >
                                        {dataExpandedList.filter(expanded => expanded.id === data.id).length === 0 && (
                                            <Box>
                                                <Tooltip title="Project view">
                                                    <IconButton
                                                        onClick={() => navigate(`/project/detailmap?id=${data.id}`)}
                                                    >
                                                        <Map />
                                                    </IconButton>
                                                </Tooltip>
                                                {data.fpAddress}
                                            </Box>
                                        )}
                                    </td>
                                    <td
                                        colSpan={
                                            1
                                            // dataExpandedList.length > 0 ? (roles("FIELDING_ASSIGN") ? 3 : 2) : 1
                                        }
                                        style={{ textAlign: "center" }}
                                    >
                                        {dataExpandedList.filter(expanded => expanded.id === data.id).length === 0 && (
                                            parameterGetData.projectStatus === 1 ? (
                                                <p>Project Completed : {data.completeDateString}</p>
                                            ) : parameterGetData.projectStatus === 2 ? (
                                                <p>Archived Date : {data.archiveDateString}</p>
                                            ) : (
                                                data.dueDateString
                                            )
                                        )}
                                    </td>
                                    {dataExpandedList.length > 0 ? <td></td> : ""}
                                    {roles("PROJECT_EDIT") && (
                                        <td style={parameterGetData.projectStatus === 1 ? { padding: 0 } : {}}>
                                            {parameterGetData.projectStatus === 0 ? (
                                                <Fragment>
                                                    {data.fpProjectStatus === 0 && (
                                                        <Button
                                                            startIcon={<Check />}
                                                            color="warning"
                                                            onClick={() => handleComplete(data.id)}
                                                        >
                                                            Complete Project
                                                        </Button>
                                                    )}
                                                    <Button
                                                        startIcon={<Edit />}
                                                        color="success"
                                                        onClick={() =>
                                                            navigate(`/project/detail?id=${data.id}`)
                                                        }
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        startIcon={<Delete />}
                                                        color="error"
                                                        onClick={() => handleDelete(data.id)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </Fragment>
                                            ) : parameterGetData.projectStatus === 1 ? (
                                                <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                                                    <Button
                                                        startIcon={<Unarchive />}
                                                        color={"success"}
                                                        onClick={() => handleToActive(data.id)}
                                                        style={{
                                                            textAlign: "left",
                                                            padding: 0,
                                                            marginRight: "24px"
                                                        }}
                                                        className="text-nowrap"
                                                    >
                                                        Move to active
                                                    </Button>
                                                    <Button
                                                        startIcon={<Archive />}
                                                        color="primary"
                                                        onClick={() => handleToArchive(data.id)}
                                                        style={{
                                                            textAlign: "left",
                                                            padding: 0,
                                                            marginRight: "24px"
                                                        }}
                                                        className="text-nowrap"
                                                    >
                                                        Move to archive
                                                    </Button>
                                                </Box>
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                    )}
                                </tr>
                                {fieldingRequestList.length > 0 && (
                                    <tr key={`${data.id}-child`} className="child">
                                        <th
                                            style={{
                                                textAlign: "center",
                                                padding: "10px 0",
                                                width: "100%",
                                                display: "flex",
                                                flexWrap: "nowrap"
                                            }}
                                        >
                                            <div style={{ width: "50%" }}>Request Number</div>
                                            <div style={{ width: "50%" }}>Created By</div>
                                        </th>
                                        {/* <th style={{ textAlign: "center" }}>Created By</th> */}
                                        <th style={{ textAlign: "center" }}>Fielding Type</th>
                                        <th style={{ textAlign: "center" }}>Location</th>
                                        <th style={{ textAlign: "center" }}>Due Date</th>
                                        <th style={{ textAlign: "center" }}>Fielding Status</th>
                                        <th style={{ textAlign: "center" }} colSpan={2}>
                                            Fielder
                                        </th>
                                    </tr>
                                )}

                                {fieldingRequestList.length > 0 &&
                                    fieldingRequestList.map((fieldingRequest, i) => {
                                        return (
                                            <tr
                                                key={`${data.id}-child-${fieldingRequest.fieldingRequestId}`}
                                                className={`child ${fieldingRequest.status === "Active" &&
                                                    parameterGetData.projectStatus === 0
                                                    ? fieldingColor[i % 3]
                                                    : "done"
                                                    }`}
                                                style={{ textAlign: "center" }}
                                            >
                                                <td>
                                                    <div
                                                        style={{
                                                            padding: "10px 0",
                                                            width: "100%",
                                                            display: "flex",
                                                            flexWrap: "nowrap"
                                                        }}
                                                    >
                                                        <div style={{ width: "50%" }}>
                                                            {fieldingRequest.fieldingRequest}
                                                        </div>
                                                        <div style={{ width: "50%" }}>
                                                            {fieldingRequest.createdByName}
                                                        </div>
                                                    </div>
                                                </td>
                                                {/* <td>{fieldingRequest.createdByName}</td> */}
                                                <td>
                                                    {fieldingRequest.fieldingRequestMarkers[0].customTypeString}
                                                </td>
                                                <td>
                                                    <Box maxWidth={"300px"} margin={"auto"}>
                                                        {dataExpandedAddressList.filter(
                                                            (address) =>
                                                                address.id === fieldingRequest.fieldingRequestId
                                                        ).length > 0
                                                            ? dataExpandedAddressList.filter(
                                                                (address) =>
                                                                    address.id ===
                                                                    fieldingRequest.fieldingRequestId
                                                            )[0].value
                                                            : "Loading address"}
                                                    </Box>
                                                </td>
                                                <td>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            minWidth: "230px",
                                                            justifyContent: "space-between"
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                display: "flex",
                                                                width: "120px",
                                                                justifyContent: "space-between"
                                                            }}
                                                        >
                                                            <span>Due date</span> <span>:</span>
                                                        </p>
                                                        <p>{fieldingRequest.dueDateString}</p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            minWidth: "230px",
                                                            justifyContent: "space-between"
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                display: "flex",
                                                                minWidth: "120px",
                                                                width: "49%",
                                                                justifyContent: "space-between"
                                                            }}
                                                        >
                                                            <span>Fielding date range</span> <span>:</span>
                                                        </p>
                                                        <p
                                                            style={{
                                                                width: "49%",
                                                                display: "flex",
                                                                justifyContent: "space-around"
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                    width: "30%",
                                                                    paddingLeft: "2px",
                                                                    textAlign: "center"
                                                                }}
                                                            >
                                                                {fieldingRequest.requestedStartDateString !==
                                                                    "-" &&
                                                                    fieldingRequest.requestedStartDateString !==
                                                                    ""
                                                                    ? moment(
                                                                        fieldingRequest.requestedStartDateString
                                                                    ).format("MM/DD")
                                                                    : ""}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                    paddingLeft: "4px",
                                                                    paddingRight: "4px",
                                                                    width: "10%",
                                                                    textAlign: "center"
                                                                }}
                                                            >
                                                                -
                                                            </span>
                                                            <span
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                    width: "550%",
                                                                    textAlign: "center"
                                                                }}
                                                            >
                                                                {fieldingRequest.requestedDueDateString !==
                                                                    "-" &&
                                                                    fieldingRequest.requestedDueDateString !==
                                                                    ""
                                                                    ? moment(
                                                                        fieldingRequest.requestedDueDateString
                                                                    ).format("MM/DD YYYY")
                                                                    : ""}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            minWidth: "230px",
                                                            justifyContent: "space-between",
                                                            fontStyle: "italic",
                                                            color: "darkgreen"
                                                        }}
                                                    >
                                                        <p
                                                            style={{
                                                                display: "flex",
                                                                width: "120px",
                                                                justifyContent: "space-between"
                                                            }}
                                                        >
                                                            <span>Last update</span> <span>:</span>
                                                        </p>
                                                        <p>{fieldingRequest.modifiedDateString}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={"column"}
                                                        gap={1}
                                                        alignItems={"center"}
                                                        sx={{
                                                            paddingTop: "2px"
                                                        }}
                                                    >
                                                        <Typography>
                                                            {parameterGetData.projectStatus !== 0 ||
                                                                fieldingRequest.status === "Completed"
                                                                ? "100 %"
                                                                : `${(
                                                                    fieldingRequest.fieldingRequestMarkers.reduce(
                                                                        (total: number, data: any) =>
                                                                            total + data.progressPercentage,
                                                                        0
                                                                    ) /
                                                                    fieldingRequest.fieldingRequestMarkers
                                                                        .length
                                                                ).toFixed(2)} %`}
                                                        </Typography>
                                                        {parameterGetData.projectStatus === 0 ? (
                                                            <ButtonOutlined
                                                                fullWidth
                                                                startIcon={
                                                                    fieldingRequest.status === "Active" ? (
                                                                        <Circle />
                                                                    ) : undefined
                                                                }
                                                                endIcon={
                                                                    fieldingRequest.status !== "Active" ? (
                                                                        <Circle />
                                                                    ) : undefined
                                                                }
                                                                color={
                                                                    fieldingRequest.status === "Active"
                                                                        ? "success"
                                                                        : "primary"
                                                                }
                                                                onClick={() =>
                                                                    fieldingRequest.status === "Active"
                                                                        ? handleCompleteFielding(
                                                                            data.id,
                                                                            fieldingRequest.fieldingRequestId
                                                                        )
                                                                        : handleUncompleteFielding(
                                                                            data.id,
                                                                            fieldingRequest.fieldingRequestId
                                                                        )
                                                                }
                                                            >
                                                                {fieldingRequest.status}
                                                            </ButtonOutlined>
                                                        ) : (
                                                            <ButtonOutlined
                                                                fullWidth
                                                                color={"warning"}
                                                                endIcon={<Circle />}
                                                            >
                                                                Completed
                                                            </ButtonOutlined>
                                                        )}
                                                    </Box>
                                                </td>
                                                <td colSpan={2}>
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={"row"}
                                                        width={"300px"}
                                                        alignItems={"center"}
                                                    >
                                                        {roles("FIELDING_ASSIGN") && (
                                                            <div
                                                                className="fielding-type"
                                                                style={{ flexGrow: 1 }}
                                                            >
                                                                <>
                                                                    <Autocomplete
                                                                        fullWidth
                                                                        multiple
                                                                        readOnly
                                                                        popupIcon={<></>}
                                                                        options={fieldingRequest.fielderNames}
                                                                        getOptionLabel={(value) => value}
                                                                        renderTags={(values, getTagProps) => (
                                                                            <div
                                                                                style={{ position: "relative" }}
                                                                            >
                                                                                {values.map((value, i) => (
                                                                                    <Box
                                                                                        key={i}
                                                                                        sx={{
                                                                                            bgcolor:
                                                                                                "lightgray",
                                                                                            margin: 0.5,
                                                                                            padding: 1,
                                                                                            borderRadius: 1,
                                                                                            textAlign: "left"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    "0.8125rem"
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                value.split(
                                                                                                    "Assigned by",
                                                                                                    1
                                                                                                )[0]
                                                                                            }
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    "0.7rem",
                                                                                                fontStyle:
                                                                                                    "italic"
                                                                                            }}
                                                                                        >
                                                                                            Assigned by{" "}
                                                                                            {
                                                                                                value.split(
                                                                                                    "Assigned by",
                                                                                                    2
                                                                                                )[1]
                                                                                            }
                                                                                        </span>
                                                                                    </Box>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                        value={fieldingRequest.fielderNames}
                                                                        renderInput={(params) => (
                                                                            <div
                                                                                style={{
                                                                                    position: "relative",
                                                                                    height: "100%"
                                                                                }}
                                                                            >
                                                                                <TextField
                                                                                    {...params}
                                                                                    sx={{
                                                                                        borderTopRightRadius:
                                                                                            "0px !important"
                                                                                    }}
                                                                                    label={
                                                                                        fieldingRequest
                                                                                            .fielderIds.length >
                                                                                            0
                                                                                            ? "Assigned Fielder"
                                                                                            : "No fielder"
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    />
                                                                    <Button
                                                                        variant="contained"
                                                                        sx={{
                                                                            whiteSpace: "nowrap",
                                                                            paddingLeft: 3,
                                                                            paddingRight: 3
                                                                        }}
                                                                        disabled={
                                                                            parameterGetData.projectStatus !== 0
                                                                        }
                                                                        onClick={() => {
                                                                            navigate(
                                                                                `/project/assign?id=${fieldingRequest.fieldingRequestId}`
                                                                            )
                                                                        }}
                                                                    >
                                                                        Assignment
                                                                    </Button>
                                                                </>
                                                            </div>
                                                        )}
                                                    </Box>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </Fragment>
                        )
                    })}
                    <tr>
                        <td colSpan={7}>
                            <div className="foot">
                                <Pagination
                                    skip={parameterGetData.skip}
                                    take={parameterGetData.take}
                                    dataTotal={dataTotal}
                                    onChangePerPage={(value) =>
                                        dispatch(
                                            setParameterGetData({
                                                ...parameterGetData,
                                                skip: 0,
                                                take: value,
                                            })
                                        )
                                    }
                                    onBack={() =>
                                        dispatch(
                                            setParameterGetData({
                                                ...parameterGetData,
                                                skip: parameterGetData.skip - parameterGetData.take,
                                            })
                                        )
                                    }
                                    onNext={() =>
                                        dispatch(
                                            setParameterGetData({
                                                ...parameterGetData,
                                                skip: parameterGetData.skip + parameterGetData.take,
                                            })
                                        )
                                    }
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Container>
    )
}