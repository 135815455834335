import { Box, Button, Card, CardActions, CardContent, CardHeader, IconButton, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { Fragment, useEffect, useState } from "react"
import {
    fieldingAssignFtrAccept,
    fieldingAssignFtrDecline,
    fieldingClearNotification,
    fieldingGetNotification,
    fieldingReadNotification
} from "../../fielding/fielding.api"
import { InterfaceFieldingNotification } from "../../fielding/fielding.interface"
import { ArrowBack, PersonOffOutlined } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import CustomModal from "../../../shared/customModal/CustomModal"
import NotificationNoteReply from "./NotificationNoteReply"
import notificationSuccess from "../../../shared/notificationSuccess"
import CustomLoading from "../../../shared/CustomLoading"
import { projectClearNotification, projectGetNotification, projectReadNotification } from "../../account/account.api"
import ButtonOutlined from "../../../shared/ButtonOutlined"

export default function NotificationProject() {
    const dispatch = useDispatch<any>()
    const navigate = useNavigate()

    const { dataNotificationProjectList } = useSelector((state: RootState) => state.account)

    const [noteIdSelected, setNoteidSelected] = useState("")

    useEffect(() => {
        dispatch(projectGetNotification())
        dispatch(projectReadNotification(""))
    }, [])

    const isShowActions = (data: InterfaceFieldingNotification) => {
        return Boolean(data.isFirstToReceive)
    }

    const handleClear = () => {
        dispatch(projectClearNotification())
            .unwrap()
            .then((response: any) => {
                if (response.status === 200) {
                    dispatch(projectGetNotification())
                }
            })
    }

    var lastDate = ""

    return (
        <Box height={"100vh"} overflow={"auto"} bgcolor={"#e5e5e5"}>
            <CustomLoading />
            <CustomModal
                open={Boolean(noteIdSelected)}
                onClose={() => setNoteidSelected("")}
                title="Reply Note"
                size="sm"
                component={
                    <NotificationNoteReply
                        noteId={noteIdSelected}
                        onSubmit={() => dispatch(fieldingGetNotification())}
                    />
                }
            />
            <Box bgcolor={"#063058"} color={"white"} display={"flex"} alignItems={"center"} padding={2}>
                <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack sx={{ color: "white" }} />
                </IconButton>
                <Typography>Notification</Typography>
            </Box>
            <Box padding={3}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <Button variant="contained" onClick={handleClear}>
                        Clear All Notification
                    </Button>
                </Box>

                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        // width: "100%",
                        minHeight: "10px",
                        overflowY: "auto",
                        alignItems: "start",
                        justifyContent: "start",
                        marginTop: 16
                    }}
                >
                    {dataNotificationProjectList.map((notif: any, i: number) => {
                        lastDate = notif.assignedDate
                        return (
                            <Card sx={{ marginBottom: 1, width: "100%", margin: "8px 0" }}>
                                <div
                                    key={notif.fieldingRequestId + i}
                                    style={{
                                        margin: "16px",
                                        padding: 6,
                                        display: "flex",
                                        justifyContent: "space-evenly"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            borderRadius: "50%",
                                            background: "lightgray",
                                            height: 60,
                                            width: 60,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                        padding={1}
                                    >
                                        <PersonOffOutlined sx={{ fontSize: "30px" }} />
                                    </Box>
                                    <Box
                                        flexDirection={"column"}
                                        sx={{
                                            alignItems: "start !important",
                                            paddingLeft: 1,
                                            width: "90%",
                                            // margin:"0px 4%",
                                            position: "relative"
                                        }}
                                    >
                                        <Box
                                            display={"flex"}
                                            style={{ justifyContent: "space-between", width: "100%", margin: 0 }}
                                        >
                                            <Typography sx={{ fontWeight: "bold" }}>{notif.title}</Typography>
                                            <Typography>{notif.dateTime}</Typography>
                                        </Box>
                                        <Typography>{notif.content}</Typography>

                                        <Box
                                            display={"flex"}
                                            style={{
                                                justifyContent: "end",
                                                alignItems: "center",
                                                width: "100%",
                                                margin: 0
                                            }}
                                        >
                                            <ButtonOutlined
                                                sx={{
                                                    position: "absolute",
                                                    right: 0,
                                                    bottom: "-12px"
                                                    // color: "#80B8DD"
                                                }}
                                                onClick={() =>
                                                    navigate("/project/assign?id=" + notif.fieldingRequestId)
                                                }
                                            >
                                                Assignment
                                            </ButtonOutlined>
                                        </Box>
                                    </Box>
                                </div>
                            </Card>
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
}
