export interface InterfaceDashboardFieldingStatusData {
    total: number
    active: number
    completed: number
    archived: number
    activePercentage: number
    completedPercentage: number
    archivedPercentage: number
}
export const InitialDashboardFieldingStatusData: InterfaceDashboardFieldingStatusData = {
    total: 0,
    active: 0,
    completed: 0,
    archived: 0,
    activePercentage: 0,
    completedPercentage: 0,
    archivedPercentage: 0,
};

export interface InterfaceDashboardFieldingDateData {
    total: number
    new: number
    onGoing: number
    overdue: number
    newPercentage: number
    onGoingPercentage: number
    overduePercentage: number
}
export const InitialDashboardFieldingStatusDateData: InterfaceDashboardFieldingDateData = {
    total: 0,
    new: 0,
    onGoing: 0,
    overdue: 0,
    newPercentage: 0,
    onGoingPercentage: 0,
    overduePercentage: 0,
};

export interface InterfaceDashboardFieldingNotesData {
    notes: string,
    to: string,
    date: string,
    from: string
}

export interface InterfaceDashboardFieldingType {
    typeName: string
    totalRequest: number
}

export interface InterfaceDashboardFielderActiveData {
    name: string
    totalAssignedJobs: string
    lastActivityDateString: string
}

export interface InterfaceDashboardActivityLog {
    fieldingRequest: string
    name: string
    activityDateString: string
    activityTypeString: string
    miles: string
    durationString: string
    sortDate: string
}

export interface InterfaceActivityType {
    display: string,
    value: number,
    order: number
}