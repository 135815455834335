import { Check, Delete, East, Edit, PersonRemove } from "@mui/icons-material";
import { Box, Button, Container, Divider, Grid, Paper, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import notificationDelete from "../../../shared/notificationDelete";
import { projectComplete, projectDelete, projectGet } from "../project.api";
import { useEffect } from "react";
import hot from "../../../assets/images/hot.png"
import Pagination from "../../../shared/Pagination";
import { setParameterGetData } from "../project.reducer";

export default function ProjectList(props: { onSelect: (projectId: string) => void }) {

    const navigate = useNavigate()
    const theme = useTheme()
    const dispatch = useDispatch<any>()

    const { dataList, dataTotal, parameterGetData } = useSelector((state: RootState) => state.project)

    useEffect(() => {
        dispatch(setParameterGetData({
            ...parameterGetData,
            sortField: "",
            sortType: 0
        }))
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        dispatch(projectGet(parameterGetData))
    }, [dispatch, parameterGetData])

    const handleDelete = (id: string) => {
        notificationDelete().then((isConfirmed) => {
            if (isConfirmed) {
                dispatch(projectDelete(id))
                    .unwrap()
                    .then(() => {
                        dispatch(projectGet(parameterGetData))
                    })
            }
        })
    }

    const handleComplete = (projectId: string) => {
        dispatch(projectComplete(projectId))
            .unwrap()
            .then(() => {
                dispatch(projectGet(parameterGetData))
            })
    }

    return (
        <Container
            maxWidth={"xl"}
            sx={{
                marginY: 2,
                display: "flex",
                flexDirection: "column",
                gap: 1
            }}
        >
            {dataList.map((project, projectIndex) => {
                return (
                    <Box
                        borderRadius={1}
                        bgcolor={theme.palette.background.paper}
                        key={project.id}
                    >
                        <Box
                            marginLeft={3}
                            padding={1}
                            width={50}
                            bgcolor={theme.palette.primary.main}
                            color={theme.palette.primary.contrastText}
                            sx={{
                                borderBottomLeftRadius: 8,
                                borderBottomRightRadius: 8
                            }}
                        >
                            <Typography variant="body1" align="center">{(parameterGetData.skip + projectIndex + 1).toString().padStart(2, "0")}</Typography>
                        </Box>
                        <Box
                            padding={2}
                        >
                            <Grid container spacing={2} alignItems={"flex-start"}>
                                <Grid item md={2}>
                                    <Typography variant="body1" fontWeight={"bold"} marginLeft={1}>Project</Typography>
                                    <Button
                                        onClick={() => navigate(`/project/detail?id=${project.id}`)}
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            width: "-webkit-fill-available"
                                        }}
                                        color={project.isHotJob ? "error" : "primary"}
                                    >
                                        <Box display={"flex"} alignItems={"center"} gap={1}>
                                            <span style={{ textAlign: "left" }}>
                                                {project.projectNumber}
                                            </span>
                                            {project.isHotJob && (
                                                <img src={hot} alt="hot" style={{ width: "20px" }} />
                                            )}
                                        </Box>
                                    </Button>
                                    <Typography variant="caption" fontStyle={"italic"} color={"grey"} marginLeft={1}>Created by {project.createdByName} at {project.createdDateString}</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography variant="body1" fontWeight={"bold"}>Project Customer Code</Typography>
                                    <Typography variant="body1">{project.customerCodeString}</Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography variant="body1" fontWeight={"bold"}>Location</Typography>
                                    <Typography variant="body1">{project.fpAddress}</Typography>
                                </Grid>
                                <Grid item md={4} lg={1}>
                                    <Typography variant="body1" fontWeight={"bold"}>Due Date</Typography>
                                    <Typography variant="body1">{project.dueDateString}</Typography>
                                </Grid>
                                <Grid item md={12} lg={3} display={"flex"} flexGrow={1} alignItems={"flex-end"} justifyContent={"flex-end"}>
                                    <Button
                                        color="error"
                                        startIcon={<Delete />}
                                        onClick={() => handleDelete(project.id)}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        color="success"
                                        startIcon={<Edit />}
                                        onClick={() => navigate(`/project/detail?id=${project.id}`)}
                                    >
                                        Edit
                                    </Button>
                                    {
                                        project.fpProjectStatus === 0 && (
                                            <Button
                                                color="warning"
                                                startIcon={<Check />}
                                                onClick={() => handleComplete(project.id)}
                                            >
                                                Complete Project
                                            </Button>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider />
                        <Box
                            padding={2}
                            display={"flex"}
                            justifyContent={"space-between"}
                        >
                            <Box>
                                <Typography variant="body1" fontWeight={"light"} display={"inline"}>qty </Typography>
                                <Typography variant="body1" fontWeight={"bold"} display={"inline"}>{project.fieldingRequestCount} Request</Typography>
                            </Box>
                            <Box>
                                <Button
                                    startIcon={project.projectFielderIds.length === 0 ? <PersonRemove color="error" /> : undefined}
                                    endIcon={<East />}
                                    onClick={() => props.onSelect(project.id)}
                                >
                                    View Request Detail
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                )
            })}
            <Paper variant="outlined">
                <Box margin={2}>
                    <Pagination
                        skip={parameterGetData.skip}
                        take={parameterGetData.take}
                        dataTotal={dataTotal}
                        onChangePerPage={(value) =>
                            dispatch(
                                setParameterGetData({
                                    ...parameterGetData,
                                    skip: 0,
                                    take: value,
                                })
                            )
                        }
                        onBack={() =>
                            dispatch(
                                setParameterGetData({
                                    ...parameterGetData,
                                    skip: parameterGetData.skip - parameterGetData.take,
                                })
                            )
                        }
                        onNext={() =>
                            dispatch(
                                setParameterGetData({
                                    ...parameterGetData,
                                    skip: parameterGetData.skip + parameterGetData.take,
                                })
                            )
                        }
                    />
                </Box>
            </Paper>
        </Container >
    )
}